import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <div className="not-found">
      <h1 className="text-4xl uppercase font-bold tracking-tighter">404 PAGE NOT FOUND</h1>
      <button onClick={() => navigate('/')} className="font-semibold scale-125">
        Go to home 🏘️
      </button>
    </div>
  )
}

export default NotFound
