import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  attachedDocuments: [],
}

const stampSlice = createSlice({
  name: 'attachedDocuments',
  initialState,
  reducers: {
    setAttachedDocuments: (state, action) => {
      state.attachedDocuments = action.payload
    },
  },
})

export const { setAttachedDocuments } = stampSlice.actions
export default stampSlice.reducer
