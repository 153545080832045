import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { setFile, setFileURL } from '../../api/features/fileSlice'
import { PDFDocumentIcon } from '../../assets/icons'

export const DropdownFileInput = ({ handleChange }) => {
  const { fileURL } = useSelector((state) => state.file)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const fileType = ['application/pdf']

  //Drag and drop
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    onDrop: (e) => {
      let selectFile = e[0]

      dispatch(setFile(selectFile))

      if (selectFile) {
        // setName(selectFile.name)

        if (selectFile && fileType.includes(selectFile.type)) {
          let reader = new FileReader()
          reader.readAsDataURL(selectFile)
          reader.onloadend = (e) => {
            const blobURL = URL.createObjectURL(selectFile)
            dispatch(setFileURL(blobURL))
            toast.success(t('uploaded'))
          }
        } else {
          setFile(null)
          toast.error('Dokument mora biti .PDF')
        }
      } else {
        toast.error('Izaberite dokument')
      }
    },
  })

  return (
    <div className="bg-neutral-100 py-6 px-9 rounded-lg border-[1px] border-neutral w-full h-full">
      <div
        {...getRootProps()}
        className="w-full cursor-pointer z-20 h-[85%] relative flex flex-col border bg-neutral rounded-xl p-1"
      >
        <input {...getInputProps()} className="hidden absolute" id="file-input" required accept="application/pdf" />
        {fileURL ? (
          <div className="h-full z-10">
            <iframe
              src={fileURL}
              frameBorder="0"
              width="100%"
              height="100%"
              title="test"
              type="application/pdf"
            ></iframe>
          </div>
        ) : null}
        <div className="absolute inset-0 flex justify-center items-center">
          <PDFDocumentIcon />
        </div>
      </div>

      <div className="h-[2px] w-full bg-primary my-4 rounded-sm"></div>

      <div className="flex w-full justify-center items-center">
        {/* <label {...getRootProps()} className="text-secondary font-semibold">
          {t('import_pdf')}
        </label> */}
        <label
          {...getRootProps()}
          className="bg-primary border py-2 font-semibold text-white text-sm rounded-md w-2/3 flex justify-center cursor-pointer p-2"
        >
          {t('import_pdf')}
        </label>
      </div>
    </div>
  )
}
