import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'

import { useGetAllFunctionsQuery } from '../../../api/services/functionsApi'
import { useGetCompanyGroupsQuery } from '../../../api/services/groupsApi'
import { CreateCompanyGroupsModal, DeleteGroupModal, UpdateGroup } from '../../modals/modals'
import { Button } from '../../root/Button'
import ColumnFilter from '../ColumnFilter'
import Table from '../Table'

const TableGroups = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Filter: ColumnFilter,
      },
      {
        Header: t('name'),
        accessor: 'name',
        Filter: ColumnFilter,
      },
    ],
    [],
  )

  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [group, setGroup] = useState()
  const [id, setId] = useState()
  const [newGroupName, setNewGroupName] = useState()
  const [newGroupFunctions, setNewGroupFunctions] = useState([])

  const [open3, setOpen3] = useState(false)

  const company = useSelector((state) => state.company.company)

  const { data: companyGroups } = useGetCompanyGroupsQuery(company?.id, {
    skip: !company?.id,
  })

  const { data: allFunctions } = useGetAllFunctionsQuery()

  const tableHooksArray = [
    {
      label: t('edit'),
      Icon: FaEdit,
      onClick: (row) => {
        setOpen(true)
        setId(row.original.id)
        setGroup(row.original)
        setNewGroupName(row.original.name)
        setNewGroupFunctions(row.original.functions.map((fun) => (fun.name, fun)))
      },
      disabled: false,
    },
    {
      label: t('delete'),
      Icon: FaTrashAlt,
      onClick: (row) => {
        setOpen2(true)
        setId(row.original.id)
      },
      disabled: false,
    },
  ]

  // const arr =
  //   Object.keys(companyGroups).length !== 0
  //     ? companyGroups[company?.value]
  //     : [];

  // const filteredArr = arr?.reduce((acc, current) => {
  //   let includes = acc.find((item) => item.id === current.id);

  //   if (!includes) {
  //     return acc.concat([current]);
  //   } else {
  //     return acc;
  //   }
  // }, []);

  const data = useMemo(() => (Array.isArray(companyGroups) ? companyGroups : []), [companyGroups])

  return (
    <div className="wrapper phone:hidden mt-2 flex flex-col gap-4">
      <div className="w-full flex justify-start">
        <div className="w-full max-w-xs">
          <Button onClick={() => setOpen3(true)}>{t('create_group')}</Button>
        </div>
      </div>

      <Table data={data} columns={columns} actions filtering hooksArray={tableHooksArray} hiddenColumns={['address']} />

      <UpdateGroup
        setNewGroupFunctions={setNewGroupFunctions}
        newGroupFunctions={newGroupFunctions}
        setNewGroupName={setNewGroupName}
        groupFunctions={allFunctions}
        onClose={() => setOpen(false)}
        newGroupName={newGroupName}
        group={group}
        open={open}
        id={id}
      />
      <DeleteGroupModal onClose={() => setOpen2(false)} open={open2} id={id} />
      <CreateCompanyGroupsModal
        onClose={() => {
          setOpen3(false)
        }}
        open={open3}
      />
    </div>
  )
}
export default TableGroups
