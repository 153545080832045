import React from 'react'
import { IoReorderThreeOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { setOpenSidebar } from '../api/features/sidebarSlice'
import Logo from '../assets/images/protocol.ico'
import MobileSidebar from './MobileSidebar'
import Navbar from './Navbar'
import PageWrapper from './PageWrapper'
import Sidebar from './Sidebar'

export default function Layout() {
  const dispatch = useDispatch()
  const { openSidebar } = useSelector((state) => state.sidebar)

  const handleSetOpen = () => {
    dispatch(setOpenSidebar(!openSidebar))
  }

  return (
    <>
      <div className="lg:hidden relative">
        <div className="bg-[#006EAB] h-[86px] flex items-center p-6 justify-between">
          <img src={Logo} className="duration-500 h-8 w-8" alt="Logo" />
          <span onClick={handleSetOpen}>
            <IoReorderThreeOutline className="text-white" size={35} />
          </span>
        </div>
        {openSidebar && <div className="fixed inset-0 bg-black opacity-75 z-10" onClick={handleSetOpen}></div>}
        <MobileSidebar openSidebar={openSidebar} />
        <div className={`transition-transform duration-300 ${openSidebar ? 'overflow-hidden' : ''}`}>
          <PageWrapper>
            <Outlet />
          </PageWrapper>
        </div>
      </div>
      <div className="hidden lg:flex items-start justify-start max-h-screen overflow-hidden">
        <Sidebar />

        <div className="w-full flex flex-col justify-center items-start">
          <div className="w-full flex justify-center items-center">
            <div className="w-[90%]">
              <Navbar />
            </div>
          </div>
          <PageWrapper>
            <Outlet />
          </PageWrapper>
        </div>
      </div>
    </>
  )
}
