import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useGetCategoryQuery } from '../../../../api/services/categoriesApi'
import { useGetRelatedDocumentsQuery, useRelateDocumentsMutation } from '../../../../api/services/documentRelations'
import { usePostPaginatedProtocolMutation } from '../../../../api/services/protocolApi'
import { useGetDatabaseStampsQuery } from '../../../../api/services/stampsApi'
import { FileRelationCheckbox } from '../../../../components/other/FileRelationCheckbox'
import { Button } from '../../../../components/root/Button'
import CheckboxPaginatedTable from '../../../../components/table/CheckboxPaginatedTable'

const NewRelationsPage = () => {
  const { t } = useTranslation()
  const company = useSelector((state) => state.company.company)

  // page & pageSize for pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const { id } = useParams()
  const navigate = useNavigate()

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    return queryString
  }, [filterInputs])

  const { data: category } = useGetCategoryQuery(company?.id)
  const { data: stampFields } = useGetDatabaseStampsQuery({
    companyId: company?.id,
  })
  const { data: relatedDocuments } = useGetRelatedDocumentsQuery(
    {
      fileId: +id,
      page,
      pageSize,
    },
    {
      skip: !+id,
    },
  )

  let subjectHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Predmet' || el.attributeKey === 'Broj fakture') {
      return el
    }
  })

  let clientHeader = stampFields?.find((el) => {
    if (
      el.attributeKey === 'Klijent' ||
      el.attributeKey === 'Partner' ||
      el.attributeKey === 'Dobavljač' ||
      el.attributeKey === 'Kupac'
    ) {
      return el
    }
  })
  let locationHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Mjesto' || el.attributeKey === 'Sjedište') {
      return el
    }
  })
  let dateHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Datum dokumenta' || el.attributeKey === 'Datum predmeta') {
      return el
    }
  })

  const attachColumns = [
    {
      Header: t('stamp_key'),
      accessor: 'Sifra',
    },
    {
      Header: subjectHeader?.attributeKey || t('subject'),
      accessor: 'Predmet',
    },
    {
      Header: clientHeader?.attributeKey || t('client'),
      accessor: 'Klijent',
    },
    {
      Header: locationHeader?.attributeKey || t('location'),
      accessor: 'Lokacija',
    },
    {
      Header: dateHeader?.attributeKey || t('date_of_prijem'),
      accessor: 'Datum prijema',
    },
    {
      Header: 'Status',
      accessor: 'Status',

      Cell: ({ row }) => {
        let status = row.original.Status

        return status === 'Approved' ? t('approved') : status === 'Rejected' ? t('rejected') : t('awaiting')
      },
    },
    {
      Header: t('type'),
      accessor: 'Tip',
    },
    {
      Header: t('address'),
      accessor: 'address',
    },
    {
      Header: t('category'),
      accessor: 'Kategorije',

      Cell: ({ row }) => {
        let category = row.original.Kategorije
        if (!category) return 'Nema kategorije'
        return category
      },
    },
    {
      Header: t('kind'),
      accessor: 'advance',

      Cell: ({ row }) => {
        let advance = row.original.advance === 1 ? 'advance' : ''
        let importExport = row.original.importExport === 1 ? 'import' : 'export'
        let clasified = row.original.clasified === 1 ? 'clasified' : ''
        let reExport = row.original.reExport === 1 ? 're-export' : ''
        let KO = row.original.KO === 1 ? 'KO' : ''
        return (
          <div className="flex flex-col w-full justify-center items-center">
            <p className="mb-0">{advance}</p>
            <p className="mb-0">{importExport}</p>
            <p className="mb-0">{clasified}</p>
            <p className="mb-0">{KO}</p>
            <p className="mb-0">{reExport}</p>
          </div>
        )
      },
    },
  ]

  const attachedColumns = useMemo(() => attachColumns, [category])

  // Exclude the file it self
  // And all his already related documents
  const excludes = useMemo(
    () =>
      +id && relatedDocuments?.rows?.length
        ? [...relatedDocuments?.rows?.map((doc) => doc.relatedFileId), +id]
        : +id && !relatedDocuments?.rows?.length
          ? [+id]
          : null,
    [id, relatedDocuments?.rows],
  )

  const [selectedFlatRows, setSelectedFlatRows] = useState()

  const [relateDocument, { isLoading: loadingRelate }] = useRelateDocumentsMutation()
  const [protocolFiles, { data: ProtocolFiles }] = usePostPaginatedProtocolMutation()

  useEffect(() => {
    protocolFiles({
      page,
      pageSize,
      companyId: company?.id,
      query,
      exclude: excludes,
    })
  }, [id, page, pageSize, query, excludes, company.id, protocolFiles])

  //checkbox selection
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'select',
        Header: ({ getToggleAllRowsSelectedProps }) => <FileRelationCheckbox {...getToggleAllRowsSelectedProps()} />,
        Cell: ({ row }) => {
          return (
            <div className="flex justify-center items-center cursor-pointer w-full h-full">
              <FileRelationCheckbox {...row.getToggleRowSelectedProps()} stampKey={row.original.Sifra} />
            </div>
          )
        },
      },
      ...columns,
    ])
  }

  const stampKeyArray = selectedFlatRows?.map((el) => {
    return { id: el.original.id }
  })

  const createRelation = async (companyId, relatedDocuments) => {
    try {
      const body = { arr: relatedDocuments, companyId: companyId, primaryFileId: +id }

      await relateDocument(body).unwrap()
      toast.success(t('success'))

      navigate(-1)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  const data = useMemo(() => (Array.isArray(ProtocolFiles?.rows) ? ProtocolFiles?.rows : []), [ProtocolFiles])

  return (
    <>
      <div className="w-full flex justify-evenly">
        <div className="w-full">
          <div className="flex gap-4 pb-4 max-w-sm">
            <Button
              isLoading={loadingRelate}
              disabled={loadingRelate}
              onClick={() => {
                createRelation(company?.id, stampKeyArray)
              }}
            >
              {t('relate')}
            </Button>
          </div>
        </div>
      </div>
      <CheckboxPaginatedTable
        columns={attachedColumns}
        data={data}
        filtering
        hiddenColumns={['address', 'id']}
        tableHooks={tableHooks}
        setSelectedRows={setSelectedFlatRows}
        setPage={setPage}
        pageManual={page}
        setPageAmount={setPageSize}
        controlledPageCount={ProtocolFiles?.totalPages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={ProtocolFiles?.count}
        actions
      />
    </>
  )
}

export default NewRelationsPage
