import { Form, Formik } from 'formik'
import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../../api/services/configuratorApi'

function CommentBeforeApprove() {
  const company = useSelector((state) => state.company.company)
  const { data: config } = useGetConfiguratorQuery(company?.id)

  const initialValues = {
    ...config,
  }

  const [updateConfig] = useEditConfiguratorMutation()

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={(values, { setSubmitting }) => {}}>
      {({ values, handleChange }) => (
        <Form className="flex justify-between w-full">
          <p className=" h-[20px] font-popins font-medium leading-[20px] text-center text-lg text-[#073C59]">
            {t('com_be_app')}
          </p>
          <input
            type="checkbox"
            className={`toggle border-[#A6A9AD] ${config?.commentBeforeApprove === true ? 'bg-white [--tglbg:#006EAB] hover:bg-white' : 'bg-[#006EAB] [--tglbg:white] hover:bg-[#006EAB]'} `}
            name="comm_before_approve"
            checked={config?.commentBeforeApprove}
            onChange={async () => {
              try {
                delete values?.num_key
                const response = await updateConfig({
                  companyId: company?.id,
                  updatedConfig: { ...values, commentBeforeApprove: !config.commentBeforeApprove },
                }).unwrap()
                toast.success(response.message)
              } catch (error) {
                toast.error(error.data.message)
              }
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default CommentBeforeApprove
