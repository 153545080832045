import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const useGETAPI = (api) => {
  const [response, setResponse] = useState([])

  const company = useSelector((state) => state.company.company)

  // taxId is ID of company in ERP applicaton
  const comany_tax_id = useMemo(() => company?.taxId, [company])
  const pin = useMemo(() => company?.pin, [company])
  const EPR_API = useMemo(() => company?.ip, [company])

  useEffect(() => {
    const getData = async (taxId, pin, ip) => {
      try {
        const data = await axios.post(`${process.env.REACT_APP_API_IP}${api}`, {
          taxId: +taxId,
          pin,
          ip,
        })

        const responseArray = Array.isArray(data.data) ? data.data : []

        setResponse(responseArray)
      } catch (error) {
        toast.error(error)
      }
    }
    if (comany_tax_id && pin && EPR_API && api) getData(comany_tax_id, pin, EPR_API)
  }, [comany_tax_id, EPR_API, pin, api])

  return response
}

export default useGETAPI
