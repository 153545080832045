import { t } from 'i18next'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button } from '../../../../root/Button'

const AddSelectField = ({ onClick, spredValues, setInputFields, filteredOptions, index }) => {
  const addSelectField = () => {
    if (filteredOptions.lenght !== 0) {
      let newFields = [...spredValues]
      newFields.splice(index + 1, 0, {
        uId: uuidv4(),
        attributeKey: '',
        attributeValue: '',
        select: true,
      })

      setInputFields(newFields)
    } else {
      return
    }

    onClick()
  }

  return (
    <Button onClick={addSelectField} className="text-xl font-semibold" disabled={!filteredOptions.length}>
      {t('add_select')}
    </Button>
  )
}

export default AddSelectField
