import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useDeleteUserMutation } from '../../../api/services/usersApi'
import NewModal from '../../root/modals/NewModal'

const DeleteUserModal = ({ open, onClose, user, companyId }) => {
  const { t } = useTranslation()

  const [deleteUser] = useDeleteUserMutation()

  const handleDelete = async () => {
    try {
      if (companyId) {
        const response = await deleteUser(user?.id, companyId).unwrap()
        toast.success(response.message)
      } else {
        const responsee = await deleteUser(user?.id).unwrap()
        toast.success(responsee.message)
      }
      onClose()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        title={t('you_sure_user')}
        hideClose
        btns
        noChildren
        titleClose={t('close')}
        titleSubmit={t('delete')}
        onClickClose={onClose}
        onClickSubmit={handleDelete}
      />
    </>
  )
}

export default DeleteUserModal
