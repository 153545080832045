import { format } from 'date-fns'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { FcViewDetails } from 'react-icons/fc'
import { useSelector } from 'react-redux'
import { useAsyncDebounce } from 'react-table'

import { useArchiveQuery } from '../../../api/services/filesApi'
import { ViewModal } from '../../modals/modals'
import ModalDataMobile from '../../root/modals/ModalDataMobile'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import NewTable from '../NewTable'

const TableArchive = () => {
  const user = useSelector((state) => state.user.user)
  const company = useSelector((state) => state.company.company)

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})
  const [fileAddress, setFileAddress] = useState('')
  const [comment, setComment] = useState('')
  const [open, setIsOpen] = useState(false)
  const [openDataTableModal, setOpenDataTableModal] = useState(false)
  const [fileId, setFileId] = useState('')

  const [row, setRow] = useState()

  const [viewArray, setViewArray] = useState()

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (key === 'Date' || key === 'Date of document') {
        if (value.trim() !== '') {
          const formattedDate = format(new Date(value), 'dd-MM-yyyy')
          queryString += `${key}=${encodeURIComponent(formattedDate)}&`
        }
      } else {
        if (value.trim() !== '') {
          queryString += `${key}=${encodeURIComponent(value)}&`
        }
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const handleClick = (row) => {
    const url = row.original.address || row.original.url
    setFileId(row?.original?.id)
    setComment(row?.original?.comment)
    setFileAddress(url)
    setIsOpen(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('stamp_key'),
        accessor: 'stampKey' || 'name',
        Cell: ({ row }) => {
          return (
            <button
              className="cursor-pointer text-sky-700 hover:underline hover:text-sky-700/70"
              onClick={() => handleClick(row)}
            >
              {row.original.stampKey}
            </button>
          )
        },
      },
      {
        Header: t('client'),
        accessor: 'Client',
      },
      {
        Header: t('subject'),
        accessor: 'Subject',
      },
      {
        Header: t('location'),
        accessor: 'Location',
      },
      {
        Header: t('protocol_date'),
        accessor: 'Date',
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
      {
        Header: t('date_of_document'),
        accessor: 'Date of document',
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
    ],
    [],
  )

  const {
    data: allArchives,
    isFetching,
    isLoading,
  } = useArchiveQuery({
    page,
    pageSize,
    companyId: company?.id,
    userId: user?.id,
    query: query ? query : '',
  })

  const data = useMemo(() => (Array.isArray(allArchives?.files) ? allArchives?.files : []), [allArchives])

  useEffect(() => {
    // This will only run when `data` changes
    if (data) {
      const addressData = data?.map((item) => ({ address: item?.address, id: item?.id }))
      setViewArray(addressData)
    }
  }, [data])

  const tableHooksArray = [
    {
      label: t('view'),
      Icon: AiFillEye,
      onClick: (row) => {
        const url = row.original.address || row.original.url
        // if (url) {
        //   window.open(url, '_blank')
        // }
        setFileAddress(url)
        setComment(row?.original?.comment)
        setIsOpen(true)
      },
      disabled: false,
    },
  ]
  const tableHooksArrayMobile = [
    {
      label: t('view'),
      Icon: AiFillEye,
      onClick: (row) => {
        const url = row.original.address || row.original.url
        // if (url) {
        //   window.open(url, '_blank')
        // }
        setFileAddress(url)
        setComment(row?.original?.comment)
        setIsOpen(true)
      },
      disabled: false,
    },
    {
      label: 'Details',
      Icon: FcViewDetails,
      onClick: (row) => {
        setOpenDataTableModal(true)
        setRow(row)
      },
      disabled: false,
    },
  ]

  const columnsMobile = useMemo(
    () => columns?.filter((item) => item?.accessor === 'Client' || item?.accessor === 'stampKey'),
    [columns],
  )

  return (
    <>
      <div className="hidden lg:block">
        <NewTable
          data={data}
          columns={columns}
          filtering
          actions
          hooksArray={tableHooksArray}
          hiddenColumns={['address', 'id']}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          setPage={setPage}
          pageManual={page}
          setPageAmount={setPageSize}
          controlledPageCount={allArchives?.totalPages || 0}
          count={allArchives?.count}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      </div>
      <div className="lg:hidden">
        <NewTable
          data={data}
          columns={columnsMobile}
          filtering
          actions
          hooksArray={tableHooksArrayMobile}
          hiddenColumns={['address', 'id']}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          setPage={setPage}
          pageManual={page}
          setPageAmount={setPageSize}
          controlledPageCount={allArchives?.totalPages || 0}
          count={allArchives?.count}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      </div>
      <ViewModal
        address={fileAddress}
        open={open}
        onClose={() => setIsOpen(false)}
        comment={comment}
        displayComment={true}
        page={'protocol'}
        id={fileId}
        viewArray={viewArray}
        displayBtns={true}
      />
      <ModalDataMobile
        openDataTableModal={openDataTableModal}
        row={row}
        setOpenDataTableModal={setOpenDataTableModal}
        columns={columns}
      />
    </>
  )
}
export default TableArchive
