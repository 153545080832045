import { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import * as AiIcons from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'

import { setAttachedDocuments } from '../../api/features/attachedDocuments'
import AttachedDocument from '../../components/other/AttachedDocument'

const AttachedDocuments = () => {
  const dispatch = useDispatch()

  const company = useSelector((state) => state.company.company)
  const attachedDocuments = useSelector((state) => state.attachedDocuments.attachedDocuments)

  const componentRef = useRef()

  const { stampTemplate } = useSelector((state) => state.stamp)

  const [attachedDocs, setAttachedDocs] = useState([])
  const [checkReturn, setCheckReturn] = useState(false)
  const [checkSent, setCheckSent] = useState(false)
  const [partner, setPartner] = useState('')
  const [label, setLabel] = useState('')
  const [text, setText] = useState('')
  const [num, setNum] = useState('')
  const [date, setDate] = useState('')
  const [date2, setDate2] = useState('')

  var date3 = new Date(date2)
  const parsedDate = date && date.toLocaleDateString('en-UK').replace(/\//g, '-')

  const parsedSndDate = `${date3.getDate()}.${date3.getMonth() + 1}.${date3.getFullYear()}.`

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(setAttachedDocuments(attachedDocs))
  }, [attachedDocs])

  const subbmit = () => {
    if (!date || !text || !label) {
      return
    }
    setAttachedDocs((prevDocs) => {
      return [...prevDocs, { label, num, text, parsedDate }]
    })
    setText('')
    setDate('')
    setLabel('')
  }

  const handleCheckBox = (e) => {
    if (e.target.id === 'sent') {
      setCheckSent(!checkSent)
      setCheckReturn(false)
    }
    if (e.target.id === 'return') {
      setCheckReturn(!checkReturn)
      setCheckSent(false)
    }
  }

  console.log('checkReturn || checkSent || attachedDocs.length', checkReturn, checkSent, attachedDocs.length)

  const maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() + 100)
  return (
    <>
      {/* <div className="flex flex-col gap-6"> */}
      <div className="grid grid-cols-1 gap-6">
        <div className="grid grid-cols-2 gap-[20px]">
          <input
            className="w-full h-[48px] rounded-lg border-[1px] border-[#E6E6E5] py-[9px] px-[8px] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder={t('document')}
          />

          <div className="flex items-center w-full h-[48px] rounded-lg border-[1px] border-[#E6E6E5] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5">
            <DatePicker
              className="w-full py-[9px] px-[8px] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5 focus:outline-none"
              dateFormat="dd/MM/yyyy"
              selected={date2}
              showYearDropdown
              scrollableYearDropdown
              onChangeRaw={(event) => {
                const inputValue = event.target.value
                let formattedValue = inputValue ? inputValue.replace(/[^\d]/g, '') : ''

                if (formattedValue.length > 2) {
                  formattedValue = `${formattedValue.slice(0, 2)}.${formattedValue.slice(2)}` // Insert dot after two digits
                }

                if (formattedValue.length > 5) {
                  formattedValue = `${formattedValue.slice(0, 5)}.${formattedValue.slice(5)}` // Insert dot after five digits
                }
                if (formattedValue.length > 10) {
                  formattedValue = formattedValue.slice(0, 10)
                }

                if (formattedValue !== inputValue || (inputValue && !isNaN(Number(inputValue)))) {
                  event.target.value = formattedValue
                  const dateParts = formattedValue.split('.').filter(Boolean)
                  const day = parseInt(dateParts[0], 10)
                  const month = parseInt(dateParts[1], 10) - 1
                  const year = parseInt(dateParts[2], 10) || 0
                  const date = new Date(year, month, day)

                  if (!isNaN(date.getTime())) {
                    setDate2(date)
                  } else {
                    setDate2(null)
                  }
                }
              }}
              onChange={(date) => setDate2(date)}
              maxDate={maxDate}
              preventOpenOnFocus
              placeholderText={'Datum dokumenta'}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-[20px]">
          <input
            className="w-full h-[48px] rounded-lg border-[1px] border-[#E6E6E5] py-[9px] px-[8px] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            placeholder={'Oznaka'}
          />
          <input
            className="w-full h-[48px] rounded-lg border-[1px] border-[#E6E6E5] py-[9px] px-[8px] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5"
            value={partner}
            onChange={(e) => setPartner(e.target.value)}
            placeholder={'Partner'}
          />
        </div>
        <div className="grid grid-cols-2 gap-[20px]">
          <div className="flex items-center w-full h-[48px] rounded-lg border-[1px] border-[#E6E6E5] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5">
            <DatePicker
              className="w-full py-[9px] px-[8px] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5 focus:outline-none"
              dateFormat="dd/MM/yyyy"
              selected={date}
              showYearDropdown
              scrollableYearDropdown
              onChangeRaw={(event) => {
                const inputValue = event.target.value
                let formattedValue = inputValue ? inputValue.replace(/[^\d]/g, '') : ''

                if (formattedValue.length > 2) {
                  formattedValue = `${formattedValue.slice(0, 2)}.${formattedValue.slice(2)}` // Insert dot after two digits
                }

                if (formattedValue.length > 5) {
                  formattedValue = `${formattedValue.slice(0, 5)}.${formattedValue.slice(5)}` // Insert dot after five digits
                }
                if (formattedValue.length > 10) {
                  formattedValue = formattedValue.slice(0, 10)
                }

                if (formattedValue !== inputValue || (inputValue && !isNaN(Number(inputValue)))) {
                  event.target.value = formattedValue
                  const dateParts = formattedValue.split('.').filter(Boolean)
                  const day = parseInt(dateParts[0], 10)
                  const month = parseInt(dateParts[1], 10) - 1
                  const year = parseInt(dateParts[2], 10) || 0
                  const date = new Date(year, month, day)

                  if (!isNaN(date.getTime())) {
                    setDate(date)
                  } else {
                    setDate(null)
                  }
                }
              }}
              maxDate={maxDate}
              preventOpenOnFocus
              onChange={(date) => setDate(date)}
              placeholderText={t('date_of_submission')}
            />
          </div>
          <input
            className="w-full h-[48px] rounded-lg border-[1px] border-[#E6E6E5] py-[9px] px-[8px] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5"
            value={num}
            onChange={(e) => setNum(e.target.value)}
            placeholder={'Broj protokola'}
          />
        </div>
      </div>
      <div className="flex gap-2 mt-4 items-center justify-between">
        <div className="flex gap-[20px]">
          <div className="text-[#0D5C8D] flex gap-2">
            <input
              type="checkbox"
              className="checkbox checkbox-md border-[#A6A9AD] [--chkbg:theme(colors.white)] [--chkfg:black]"
              onChange={handleCheckBox}
              id="sent"
              checked={checkSent}
            />
            <label>{t('sent')}</label>
          </div>
          <div className="text-[#0D5C8D] flex gap-2">
            <input
              type="checkbox"
              className="checkbox checkbox-md border-[#A6A9AD] [--chkbg:theme(colors.white)] [--chkfg:black]"
              onChange={handleCheckBox}
              checked={checkReturn}
              id="return"
            />
            <label>{t('returned')}</label>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <button
            className="w-[200px] h-[48px] rounded py-3 px-5 bg-[#006EAB] text-white font-popins font-semibold leading-6 text-base"
            onClick={subbmit}
          >
            {t('add_doc')}
          </button>

          <div className="">
            {checkReturn || checkSent || attachedDocs.length !== 0 ? (
              <ReactToPrint
                trigger={() => {
                  return (
                    <div className="bg-[#006EAB] flex cursor-pointer justify-center gap-3 w-[200px] h-[48px]  rounded items-center text-white font-popins font-semibold leading-6 text-base">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5 0C3.62109 0 2.5 1.12109 2.5 2.5V6.25H5V2.5H13.8555L15 3.64453V6.25H17.5V3.64453C17.5 2.98047 17.2383 2.34375 16.7695 1.875L15.625 0.730469C15.1562 0.261719 14.5195 0 13.8555 0H5ZM15 13.75V15V17.5H5V15V14.375V13.75H15ZM17.5 15H18.75C19.4414 15 20 14.4414 20 13.75V10C20 8.62109 18.8789 7.5 17.5 7.5H2.5C1.12109 7.5 0 8.62109 0 10V13.75C0 14.4414 0.558594 15 1.25 15H2.5V17.5C2.5 18.8789 3.62109 20 5 20H15C16.3789 20 17.5 18.8789 17.5 17.5V15ZM16.875 9.6875C17.1236 9.6875 17.3621 9.78627 17.5379 9.96209C17.7137 10.1379 17.8125 10.3764 17.8125 10.625C17.8125 10.8736 17.7137 11.1121 17.5379 11.2879C17.3621 11.4637 17.1236 11.5625 16.875 11.5625C16.6264 11.5625 16.3879 11.4637 16.2121 11.2879C16.0363 11.1121 15.9375 10.8736 15.9375 10.625C15.9375 10.3764 16.0363 10.1379 16.2121 9.96209C16.3879 9.78627 16.6264 9.6875 16.875 9.6875Z"
                          fill="white"
                        />
                      </svg>

                      <div>{t('print')}</div>
                    </div>
                  )
                }}
                content={() => componentRef.current}
                pageStyle={' @page { margin: 10px 0px 0 0px !important}'}
                // ' @page { size: auto 62mm; margin: 24px !important }'
              />
            ) : (
              <div className="bg-[#006EAB] flex cursor-pointer justify-center gap-3 w-[200px] h-[48px]  rounded items-center text-white font-popins font-semibold leading-6 text-base">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5 0C3.62109 0 2.5 1.12109 2.5 2.5V6.25H5V2.5H13.8555L15 3.64453V6.25H17.5V3.64453C17.5 2.98047 17.2383 2.34375 16.7695 1.875L15.625 0.730469C15.1562 0.261719 14.5195 0 13.8555 0H5ZM15 13.75V15V17.5H5V15V14.375V13.75H15ZM17.5 15H18.75C19.4414 15 20 14.4414 20 13.75V10C20 8.62109 18.8789 7.5 17.5 7.5H2.5C1.12109 7.5 0 8.62109 0 10V13.75C0 14.4414 0.558594 15 1.25 15H2.5V17.5C2.5 18.8789 3.62109 20 5 20H15C16.3789 20 17.5 18.8789 17.5 17.5V15ZM16.875 9.6875C17.1236 9.6875 17.3621 9.78627 17.5379 9.96209C17.7137 10.1379 17.8125 10.3764 17.8125 10.625C17.8125 10.8736 17.7137 11.1121 17.5379 11.2879C17.3621 11.4637 17.1236 11.5625 16.875 11.5625C16.6264 11.5625 16.3879 11.4637 16.2121 11.2879C16.0363 11.1121 15.9375 10.8736 15.9375 10.625C15.9375 10.3764 16.0363 10.1379 16.2121 9.96209C16.3879 9.78627 16.6264 9.6875 16.875 9.6875Z"
                    fill="white"
                  />
                </svg>

                <div>{t('print')}</div>
              </div>
            )}
            {/* Div that's being printed */}
            <div className="attached-doc-print-source scale-75 absolute top-[0px]" ref={componentRef}>
              <div>
                <p>{stampTemplate ? stampTemplate[0]?.attributeValue : ''}</p>
                <p>{stampTemplate ? stampTemplate[4]?.attributeValue : ''}</p>
              </div>
              <div className="text-sm">
                <p className="text-sm">
                  Na osnovu člana 65. stav 2 Zakona o upravnom postupku ("Službene novine Federacije Bosne i
                  Hercegovine", broj 2/98 i 48/99), izdaje se
                </p>
              </div>
              <div className="w-full text-center mb-14 mt-0">POTVRDA</div>
              <div className="w-full flex justify-between">
                <p className="text-sm">
                  {checkSent
                    ? `Potvrđuje se da je ${parsedSndDate} ${partner} podnio`
                    : `Potvrđuje se da je ${parsedSndDate} ${company?.name} vratio sljedeće dokumente :`}
                </p>
              </div>

              <table className="w-full">
                <thead>
                  <tr className="SA-th_th">
                    <th></th>
                    <th className="SA-th_th">Dokument</th>
                    <th className="SA-th_th">Datum</th>
                    <th className="SA-th_th">Oznaka</th>
                  </tr>
                </thead>
                {attachedDocuments?.map((doc, index) => {
                  return (
                    <tbody key={index}>
                      <tr className="SA-th_th">
                        <th>{index + 1}</th>
                        <th className="SA-th_th">{doc.text}</th>
                        <td className="CS-td">{doc.parsedDate}</td>
                        <td className="SA-th_th">{doc.label}</td>
                      </tr>
                    </tbody>
                  )
                })}
              </table>
              {/* Ovlašteno lice */}
              {/* <div className='ml-28'>
             <p>{stampData[4]?.attributeValue}</p>
           </div> */}
              <div className="flex items-end flex-col top-[550px] mb-[50px] relative">
                <h4 className="my-4 underline underline-offset-[50px]">OVLAŠTENO LICE</h4>
                <h4 className="mt-0 border-t-2 border-black  w-full text-end ">
                  ŠIFRA DOKUMENTA : {stampTemplate ? stampTemplate[0]?.attributeValue : ''}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 w-full flex justify-center h-full">
        <AttachedDocument attachedDocs={attachedDocs} setAttachedDocs={setAttachedDocs} />
      </div>
    </>
  )
}

export default AttachedDocuments
