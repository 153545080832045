const ColumnFilterDatePagination = ({ column, handleFilterChange }) => {
  const { filterValue, setFilter } = column

  return (
    <div className="p-1 w-full">
      <input
        type="date"
        className="bg-white border-neutral text-secondary rounded-lg p-2 w-24"
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          handleFilterChange(e.target.value, column.id)
        }}
        placeholder={`Pretraži zapise...`}
      />
    </div>
  )
}

export default ColumnFilterDatePagination
