import React from 'react'

const Card = ({ onClick, label, svg, index, length }) => {
  return (
    <div
      className={`w-full  ${index !== length - 1 ? 'border-b-[1px]' : ''} cursor-pointer hover:bg-[#e9e7e7] hover:rounded-[8px] items-center h-[79px] py-[20px] px-[40px] flex gap-[20px] bg-[#F7F7F7] border-b-[#D5D9EC]`}
      onClick={onClick}
    >
      <span>{svg}</span>

      <p>{label}</p>
    </div>
  )
}

export default Card
