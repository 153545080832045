import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useDeleteCompanyMutation } from '../../../../api/services/companiesApi'
import NewModal from '../../../root/modals/NewModal'

const DeleteCompanyModal = ({ open, onClose, company }) => {
  const { t } = useTranslation()

  const [delCompany] = useDeleteCompanyMutation()

  const deleteCompany = async (companyId) => {
    try {
      const response = await delCompany({ companyId }).unwrap()

      toast.success(response.message)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  const handleDelete = () => {
    deleteCompany(company.id)
    onClose()
  }

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        title={t('you_sure_company')}
        hideClose
        btns
        noChildren
        titleClose={t('close')}
        titleSubmit={t('delete')}
        onClickClose={onClose}
        onClickSubmit={handleDelete}
      />
    </>
  )
}

export default DeleteCompanyModal
