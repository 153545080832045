import { api } from '../api'

export const outboundDocumentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createOutboundDocument: builder.mutation({
      query: ({ companyId, uploaderId, body }) => ({
        url: `/outbound-documents/create-outbound-document/${companyId}/${uploaderId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    uploadOutboundDocument: builder.mutation({
      query: (body) => ({
        url: '/outbound-documents/upload-outbound-document-S3',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    generateOutboundDocumentUrl: builder.mutation({
      query: ({ fileName }) => ({
        url: `/outbound-documents/generate-outbound-document-url/?fileName=${fileName}&contentType=application/pdf&kind=protocolstorage`,
        method: 'PUT',
        body: {
          fileName,
          contentType: 'application/pdf',
          kind: 'protocolstorage',
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    updateOutboundDocumentUrl: builder.mutation({
      query: ({ docId, body }) => ({
        url: `/outbound-documents/update-outbound-document-url/${docId} `,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Documents'],
    }),
    getcompanyOutboundDocuments: builder.query({
      query: (companyId) => ({
        url: `/outbound-documents/company-outbound-documents/${companyId}`,
      }),
      providesTags: ['Documents'],
    }),
  }),
})

export const {
  useCreateOutboundDocumentMutation,
  useUploadOutboundDocumentMutation,
  useGenerateOutboundDocumentUrlMutation,
  useUpdateOutboundDocumentUrlMutation,
  useGetcompanyOutboundDocumentsQuery,
} = outboundDocumentsApi
