import { format } from 'date-fns'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useAsyncDebounce } from 'react-table'
import 'react-toastify/dist/ReactToastify.css'

import { useGetCategoryQuery } from '../../../api/services/categoriesApi'
import { usePaginatedProtocolQuery } from '../../../api/services/protocolApi'
import { ConfirmDeleteModal } from '../../modals/modals'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import SelectColumnFilterPaginated from '../components/SelectColumnFilterPaginated'
import NewTable from '../NewTable'
import TableWrapper from '../TableWrapper'

const TableDeleteFiles = () => {
  const [open, setOpen] = useState(false)
  const [fileId, setFileId] = useState()
  const [isBooked, setIsBooked] = useState(false)
  const company = useSelector((state) => state.company.company)
  const [filterInputs, setFilterInputs] = useState({})
  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const { data: category } = useGetCategoryQuery(company?.id)

  const columns = useMemo(
    () => [
      {
        Header: t('stamp_key'),
        accessor: 'Sifra',
      },
      {
        Header: t('subject'),
        accessor: 'Predmet',
      },
      {
        Header: t('client'),
        accessor: 'Klijent',
      },
      {
        Header: t('location'),
        accessor: 'Lokacija',
      },
      {
        Header: t('date_of_prijem'),
        accessor: 'Datum prijema',
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
      {
        Header: 'Status',
        accessor: 'Status',
        Cell: ({ row }) => {
          let status = row.original.Status

          return status === 'Approved' ? t('approved') : status === 'Rejected' ? t('rejected') : t('awaiting')
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={[
                { label: t('approved'), value: 1 },
                { label: t('rejected'), value: 2 },
                { label: t('awaiting'), value: 3 },
              ]}
            />
          )
        },
      },
      {
        Header: t('type'),
        accessor: 'Tip',
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={[
                { label: 'Računovodstveni', value: 1 },
                { label: 'Pravni', value: 2 },
              ]}
            />
          )
        },
      },
      {
        Header: t('category'),
        accessor: 'Kategorije',
        Cell: ({ row }) => {
          let category = row.original.Kategorije
          if (!category) return 'Nema kategorije'
          return category
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={category?.map((item) => {
                return {
                  label: item?.categoryName,
                  value: item?.id,
                }
              })}
            />
          )
        },
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
    ],
    [],
  )

  const closeModal = () => {
    setOpen(false)
  }

  const tableHooksArray = [
    {
      label: t('delete'),
      Icon: FaTrashAlt,
      onClick: (row) => {
        setIsBooked(row.original.booked === 1)
        setFileId(row?.original?.id)
        setOpen(true)
        // setAddress(row.original.address)
      },
      disabled: false,
    },
  ]

  // PAGNATION

  // page & pageSize for pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  // Fetch patinated files
  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (key === 'Datum prijema') {
        if (value.trim() !== '') {
          const formattedDate = format(new Date(value), 'dd-MM-yyyy')
          queryString += `Datum prijema=${encodeURIComponent(formattedDate)}&`
        }
      } else {
        if (value.trim() !== '') {
          queryString += `${key}=${encodeURIComponent(value)}&`
        }
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const { data: paginatedProtocolFiles } = usePaginatedProtocolQuery({
    page,
    pageSize,
    companyId: company?.id,
    query,
  })

  // paginated data
  const data = useMemo(
    () => (Array.isArray(paginatedProtocolFiles?.rows) ? paginatedProtocolFiles?.rows : []),
    [paginatedProtocolFiles],
  )

  return (
    <>
      <TableWrapper>
        <NewTable
          columns={columns}
          setPage={setPage}
          pageManual={page}
          data={data}
          setPageAmount={setPageSize}
          controlledPageCount={paginatedProtocolFiles?.totalPages || 0}
          hooksArray={tableHooksArray}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          count={paginatedProtocolFiles?.count}
          actions
          filtering
        />
      </TableWrapper>

      <ConfirmDeleteModal open={open} onClose={closeModal} fileId={fileId} isBooked={isBooked} />
    </>
  )
}
export default TableDeleteFiles
