import React from 'react'

const EngIcon = ({ active }) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_241_7711)">
        <g clip-path="url(#clip0_241_7711)">
          <path d="M6 6H26V26H6V6Z" fill="#012169" />
          <path
            d="M26 6V8.5L18.5781 16L26 23.3047V26H23.3828L15.9219 18.6562L8.65625 26H6V23.3438L13.2656 16.0391L6 8.89062V6H8.42188L15.9219 13.3438L23.1875 6H26Z"
            fill="#EFFBE4"
          />
          <path
            d="M13.1875 18.6562L13.6172 19.9844L7.64062 26H6V25.8828L13.1875 18.6562ZM18.0312 18.1875L20.1406 18.5L26 24.2422V26L18.0312 18.1875ZM26 6L18.5 13.6562L18.3438 11.9375L24.2031 6H26ZM6 6.03906L13.5391 13.4219L11.2344 13.1094L6 7.91406V6.03906Z"
            fill="#C8102E"
          />
          <path d="M12.875 6V26H19.125V6H12.875ZM6 12.875V19.125H26V12.875H6Z" fill="#EFFBE4" />
          <path d="M6 14.125V17.875H26V14.125H6ZM14.125 6V26H17.875V6H14.125Z" fill="#C8102E" />
        </g>
        <rect
          x="5"
          y="5"
          width="22"
          height="22"
          rx="11"
          stroke={`${active ? 'white' : '#006EAB'}`}
          stroke-width="2"
          shape-rendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_241_7711"
          x="0"
          y="0"
          width="36"
          height="36"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_241_7711" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_241_7711" result="shape" />
        </filter>
        <clipPath id="clip0_241_7711">
          <rect x="6" y="6" width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EngIcon
