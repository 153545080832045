import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const useGetOrgUnits = () => {
  const [organisationalUnits, setOrganisationalUnits] = useState([])

  const company = useSelector((state) => state.company.company)

  const comany_tax_id = useMemo(() => company?.taxId, [company])
  const pin = useMemo(() => company?.pin, [company])
  const EPR_API = useMemo(() => company?.ip, [company])

  useEffect(() => {
    const getOrgUnits = async (taxId, pin, ip) => {
      try {
        const data = await axios.post(`${process.env.REACT_APP_API_IP}/protocol/erp-organisational-units`, {
          taxId: +taxId,
          pin,
          ip,
        })

        setOrganisationalUnits(data.data)
      } catch (error) {
        toast.error(error)
      }
    }

    if (comany_tax_id && pin && EPR_API) getOrgUnits(comany_tax_id, pin, EPR_API)
  }, [comany_tax_id, EPR_API, pin])

  return organisationalUnits
}

export default useGetOrgUnits
