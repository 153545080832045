import React from 'react'

const ArchiveIcon = () => {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.75 2.89282H27.25C28.218 2.89282 29 3.67485 29 4.64282V6.39282C29 7.36079 28.218 8.14282 27.25 8.14282H2.75C1.78203 8.14282 1 7.36079 1 6.39282V4.64282C1 3.67485 1.78203 2.89282 2.75 2.89282ZM2.75 9.89282H27.25V23.8928C27.25 25.8233 25.6805 27.3928 23.75 27.3928H6.25C4.31953 27.3928 2.75 25.8233 2.75 23.8928V9.89282ZM9.75 14.2678C9.75 14.7491 10.1438 15.1428 10.625 15.1428H19.375C19.8563 15.1428 20.25 14.7491 20.25 14.2678C20.25 13.7866 19.8563 13.3928 19.375 13.3928H10.625C10.1438 13.3928 9.75 13.7866 9.75 14.2678Z"
        fill="white"
      />
    </svg>
  )
}

export default ArchiveIcon
