import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useUpdateCategoryMutation } from '../../../../api/services/categoriesApi'
import NewModal from '../../../root/modals/NewModal'

const UpdateCategoryModal = ({ open, onClose, approval, category }) => {
  const { t } = useTranslation()

  const [updateCategory] = useUpdateCategoryMutation()

  const [newCategory, setNewCategory] = useState()

  const handleChange = (e) => {
    const { name, value } = e.target
    setNewCategory((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  useEffect(() => {
    setNewCategory({
      id: category?.id,
      categoryName: category?.categoryName,
      categoryDescription: category?.categoryDescription,
      requiredApproval: approval,
    })
  }, [category])

  const handleUpdate = async (e) => {
    e.preventDefault()

    if (!newCategory.categoryName || !newCategory.categoryDescription) {
      return toast.error(t('input_all_fields'))
    }

    try {
      const response = await updateCategory({
        categoryId: category?.id,
        newCategory,
      }).unwrap()
      toast.success(response.message)
      onClose()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <>
      <NewModal
        closeModal={onClose}
        showModal={open}
        hideClose
        title={t('edit_category')}
        btns
        page={'middle'}
        titleClose={t('close')}
        titleSubmit={t('save')}
        onClickClose={onClose}
        onClickSubmit={handleUpdate}
      >
        <form className="flex flex-col justify-center items-center gap-2" onSubmit={handleUpdate}>
          <input
            className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
            placeholder={t('add_cat')}
            defaultValue={newCategory?.categoryName}
            onChange={handleChange}
            name="categoryName"
          />
          <textarea
            className="w-full h-[100px] rounded-lg py-[9px] px-[8px] bg-white border-[1px] border-[#E6E6E5] placeholder:text-[#073C59] text-sm font-normal font-popins leading-5"
            rows={3}
            placeholder={t('desc')}
            defaultValue={newCategory?.categoryDescription}
            onChange={handleChange}
            name="categoryDescription"
          />
          <div className="flex mt-4">
            <input
              type="checkbox"
              id="invoice"
              checked={newCategory?.requiredApproval}
              onChange={() =>
                setNewCategory((prev) => ({
                  ...prev,
                  requiredApproval: prev.requiredApproval ? !prev.requiredApproval : true,
                }))
              }
              className="checkbox checkbox-md border-[#A6A9AD] [--chkbg:theme(colors.white)] [--chkfg:black]"
            />
            <p className="font-popins font-semibold text-base leading-6 text-center ml-2 text-[#073C59]">
              {t('req_approval')}
            </p>
          </div>
        </form>
      </NewModal>
    </>
  )
}

export default UpdateCategoryModal
