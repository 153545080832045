import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { AiOutlineLeftCircle, AiOutlineRightCircle } from 'react-icons/ai'
import { IoMdClose } from 'react-icons/io'

export default function Modal({
  title,
  children,
  isOpen,
  closeModal,
  hideClose,
  page,
  displayBtns = false,
  onRightClick,
  onLeftClick,
  disabledLeft,
  disabledRight,
}) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative" onClose={() => ''}>
          <Transition.Child as={Fragment} s>
            <div className="fixed inset-0 bg-black/25 z-[80]" />
          </Transition.Child>

          <div className="fixed inset-0 z-[90]">
            <div className="flex min-h-full items-center justify-center p-4 text-center z-[90]">
              {displayBtns && (
                <button
                  className="z-[90]  inset-0 bg-white mr-4 rounded-full disabled:cursor-not-allowed"
                  disabled={disabledLeft}
                  onClick={onLeftClick}
                >
                  <AiOutlineLeftCircle size={40} />
                </button>
              )}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`border ${page === 'PDF' ? 'max-w-7xl h-[700px]' : page === 'POE' ? 'w-[500px] h-full' : page === 'Table' ? 'max-h-[92vh] max-w-[90vw] overflow-y-hidden' : page === 'XL' ? 'max-w-[90vw] max-h-[90vh]' : page === 'EditDoc' ? 'w-[68vw] max-h-[90vh]' : 'max-w-md'} w-full p-6 ${
                    page === 'full' ? 'my-0' : 'my-8'
                  }  w-fit max-w-7xl  rounded-2xl bg-white text-left align-middle shadow-xl transition-all max-h-[90vh] overflow-y-auto`}
                >
                  {/* className={`${page === "confirm"}w-fit max-w-7xl transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all max-h-[90vh] overflow-y-auto`} */}
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-semibold leading-6 bg-red-300 text-[#073C59] flex justify-between items-center"
                  >
                    <div>{title}</div>
                    <div>
                      {!hideClose ? (
                        <button onClick={closeModal} className="">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.46875 5.46875C5.7625 5.175 6.2375 5.175 6.52812 5.46875L7.99687 6.9375L9.46562 5.46875C9.75937 5.175 10.2344 5.175 10.525 5.46875C10.8156 5.7625 10.8187 6.2375 10.525 6.52812L9.05625 7.99687L10.525 9.46562C10.8187 9.75937 10.8187 10.2344 10.525 10.525C10.2312 10.8156 9.75625 10.8187 9.46562 10.525L7.99687 9.05625L6.52812 10.525C6.23438 10.8187 5.75938 10.8187 5.46875 10.525C5.17812 10.2312 5.175 9.75625 5.46875 9.46562L6.9375 7.99687L5.46875 6.52812C5.175 6.23438 5.175 5.75938 5.46875 5.46875Z"
                              fill="#073C59"
                            />
                          </svg>
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </Dialog.Title>
                  <div className="">{children}</div>
                </Dialog.Panel>
              </Transition.Child>

              {displayBtns && (
                <button
                  className="z-[90] inset-0 bg-white ml-4 rounded-full cursor-pointer disabled:cursor-not-allowed"
                  disabled={disabledRight}
                  onClick={onRightClick}
                >
                  <AiOutlineRightCircle size={40} />
                </button>
              )}
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
