import React from 'react'
import Select from 'react-select'

import { clientStyles } from '../../data/createStampSelectStyles'

const SelectInputRegular = ({ noLabel, label, ...props }) => {
  return (
    <div className="flex flex-col justify-start">
      {!noLabel && (
        <div className="w-full flex justify-start text-secondary">
          <label>{label}</label>
        </div>
      )}
      <Select {...props} styles={clientStyles} menuPlacement="auto" />
    </div>
  )
}

export default SelectInputRegular
