import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useCreateCategoryMutation } from '../../../../api/services/categoriesApi'
import NewModal from '../../../root/modals/NewModal'

const CreateCategoryModal = ({ close, open }) => {
  const company = useSelector((state) => state.company.company)
  const [createCategory] = useCreateCategoryMutation({
    companyId: company?.id,
  })

  const [category, setCategory] = useState({
    categoryName: '',
    categoryDescription: '',
    requiredApproval: false,
  })

  const { t } = useTranslation()

  // const fileTypes = JSON.parse(localStorage.getItem("fileTypes"))

  const subbmit = async (e) => {
    e.preventDefault()

    if (!category.categoryName || !category.categoryDescription) {
      return toast.error(t('input_all_fields'))
    }

    try {
      const response = await createCategory({
        categoryName: category?.categoryName,
        categoryDescription: category?.categoryDescription,
        requiredApproval: category?.requiredApproval || false,
        companyId: company?.id,
      }).unwrap()
      setCategory({})
      toast.success(response.message)
      close()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setCategory((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={close}
        hideClose
        title={t('add_cat')}
        page={'middle'}
        btns
        titleClose={t('close')}
        titleSubmit={t('save')}
        onClickClose={close}
        onClickSubmit={subbmit}
      >
        <div>
          <form className="flex flex-col justify-center items-center gap-2">
            <input
              name="categoryName"
              className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
              value={category.categoryName}
              placeholder={t('add_cat')}
              onChange={handleChange}
            />
            <textarea
              className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
              value={category.categoryDescription}
              name="categoryDescription"
              rows={3}
              placeholder={t('desc')}
              onChange={handleChange}
            />
            <div className="flex mt-4">
              <input
                type="checkbox"
                id="invoice"
                name="requiredApproval"
                checked={category.requiredApproval}
                onChange={() =>
                  setCategory((prev) => ({
                    ...prev,
                    requiredApproval: prev.requiredApproval ? !prev.requiredApproval : true,
                  }))
                }
                className="checkbox checkbox-md border-[#A6A9AD] [--chkbg:theme(colors.white)] [--chkfg:black]"
              />
              <p className="font-popins font-semibold text-base leading-6 text-center ml-2 text-[#073C59]">
                {t('req_approval')}
              </p>
            </div>
          </form>
        </div>
      </NewModal>
    </>
  )
}

export default CreateCategoryModal
