import React, { useMemo } from 'react'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

import useTableHooks from '../../hooks/useTableHooks'
import ColumnFilter from './ColumnFilter'
import Pagination from './Pagination'

const Table = ({ data, columns, hiddenColumns = [], actions, hooksArray, filtering }) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    [],
  )

  const tableHooks = useTableHooks(hooksArray)

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { hiddenColumns },
      filterable: true,
    },
    useFilters,
    useGlobalFilter,
    // @ts-ignore
    actions && tableHooks,
    useSortBy,
    usePagination,
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    pageCount,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = tableInstance

  return (
    <div className="drop-shadow-[0px_0px_3px_rgba(0,0,0,0.25)] pt-1">
      <div className="flex w-full justify-center items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </div>

      <div className={`shadow-lg shadow-slate-400 w-full max-h-[80vh] overflow-auto max-w-full`}>
        <table {...getTableProps()} className={`text-xs border border-main-400 w-full z-0`}>
          <thead className="sticky top-0 bg-main-regular">
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()} className="sticky z-0">
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                        textAlign: 'center',
                        borderBottom: column.borderBottom,
                        fontSize: '13px',
                      },
                    })}
                    className="p-2 border border-t-main-400  border-b-main-400  border-l-main-300  text-sm font-semibold border-r-main-300 first-of-type:border-l-main-400 last-of-type:border-r-main-400 text-secondary tracking-wide"
                  >
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                      </span>
                    </div>

                    {filtering && (
                      <div className="text-sky-900">{column.canFilter ? column.render('Filter') : null}</div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                // eslint-disable-next-line react/jsx-key
                <tr
                  {...row.getRowProps()}
                  className={`bg-main-gray-ligth text-secondary tracking-wide text-center border-t-[2px] border-t-white`}
                >
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                            maxWidth: 100,
                          },
                        })}
                        className="p-1"
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
