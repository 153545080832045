import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const ProtectAuth = () => {
  const user = useSelector((state) => state.user.user)

  const location = useLocation()

  return user?.functions && user?.functions?.length ? (
    <Navigate to={user.functions[0].name} state={{ from: location }} replace />
  ) : (
    <Outlet />
  )
}

export default ProtectAuth
