import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useGetAllCompaniesNoPaginationQuery } from '../../../api/services/companiesApi'
import { useEditUserMutation } from '../../../api/services/usersApi'
import { clientStyles } from '../../../data/createStampSelectStyles'
import NewModal from '../../root/modals/NewModal'
import CustomSelectCompanyGroup from './components/CustomSelectCompanyGroup'

const EditUsersCompanies = ({
  open, //
  user, //
  onClose, //
  newGroup, //
  setGroup, //
  newUser,
  newCompanies,
  setNewCompanies,
}) => {
  const { t } = useTranslation()
  const [userGroups, setUserGropus] = useState([])
  const [deleteCompanyGroup, setDeleteCompanyGroup] = useState([])
  const { data: allCompanies } = useGetAllCompaniesNoPaginationQuery()
  const [updateUser] = useEditUserMutation()

  useEffect(() => {
    const groups = user?.groups?.map((group) => ({
      name: group?.name,
      id: group?.id,
      value: group?.id,
      label: group?.name,
      companyId: group.usergroups.companyId,
    }))
    setUserGropus(groups)
  }, [user])

  //Return group only if edited
  const subbimtEddit = async () => {
    try {
      const companiesCompleted = newCompanies.every((key) => key.id && key.name.length)

      const filteredGroups = userGroups?.filter((group) => {
        const found = newCompanies?.find((company) => company?.id === group?.companyId)

        if (found) {
          return group
        }
      })

      if (
        newCompanies?.length === 0 ||
        newGroup?.length === 0 ||
        !companiesCompleted ||
        filteredGroups.length !== newCompanies.length
      )
        return toast.error(t('input_all_fields'))

      const groupsIdForDelete = [...new Set(deleteCompanyGroup)]

      let finalUser
      finalUser = {
        ...user,
        ...newUser,
        companies: newCompanies,
        groups: filteredGroups,
        type: 'SUPERADMIN',
        password: '',
        companiesForGroupDelete: groupsIdForDelete,
      }

      //Delete to prevent backend error
      delete finalUser.createdAt
      delete finalUser.updatedAt
      const response = await updateUser({ userId: user?.id, editedUsers: finalUser }).unwrap()
      setUserGropus([])
      setDeleteCompanyGroup([])
      toast.success(response.message)
      onClose()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  //Add a select for company
  const addCompanySelect = (e, index) => {
    e.preventDefault()
    let newFields = [...newCompanies]
    let newGroups = [...userGroups]
    newFields.splice(index + 1, 0, { id: '', name: '' })
    newGroups.splice(index + 1, 0, { id: '', name: '' })

    setNewCompanies(newFields)
    setUserGropus(newGroups)
  }

  //Remove company select and group for that company
  const removeCompanySelect = (e, index) => {
    e.preventDefault()

    let newFields = [...newCompanies]

    let companyId = newFields[index].id
    setDeleteCompanyGroup((prevState) => [...prevState, companyId])

    newFields.splice(index, 1)
    //Filter removed company's group
    let newGroups = newGroup.filter((group) => {
      if (group?.usergroups?.companyId) {
        if (group.usergroups.companyId !== companyId) return group
      }
      if (group.companyId) {
        if (parseInt(group.companyId) !== companyId) return group
      }
    })

    setGroup(newGroups)
    if (newFields.length === 0) setNewCompanies([{ id: '', name: '' }])
    if (newFields.length !== 0) setNewCompanies(newFields)
  }

  //Select company handler
  const selectCompany = (e, index) => {
    let companies = [...newCompanies]
    companies[index] = { id: e.value, name: e.label }
    setNewCompanies(companies)
  }
  const handleUserGroups = (e) => {
    const existingGroup = userGroups.find((el) => el.companyId === e.companyId)
    if (!existingGroup) {
      setUserGropus((prev) => [...prev, e])
    } else {
      // If a group with the same companyId exists, update its state
      setUserGropus((prev) => prev.map((group) => (group.companyId === e.companyId ? e : group)))
    }
  }

  // const companiesId = newCompanies ? newCompanies?.companies.map((company) => company.id) : []

  const companyOptions = allCompanies?.companies?.map((company) => ({
    value: company?.id,
    label: company?.name,
  }))

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        title={'Edit user companies'}
        hideClose
        page={'configStamp'}
        btns
        titleClose={t('close')}
        titleSubmit={t('save')}
        onClickSubmit={subbimtEddit}
        onClickClose={() => {
          onClose()
          setGroup([])
          setDeleteCompanyGroup([])
          if (setNewCompanies) setNewCompanies([])
        }}
      >
        <div className="h-[40vh] overflow-y-auto">
          <form className="flex flex-col justify-center w-full gap-3">
            {/* Group select for each company */}
            {/* Edit user's companies */}

            {newCompanies?.map((company, index) => (
              <div className="flex gap-4">
                <div className="w-full">
                  <CustomSelectCompanyGroup
                    companiesId={company?.id}
                    user={user}
                    handleChange={handleUserGroups}
                    userGroups={userGroups}
                  />
                </div>

                <div className="w-full">
                  <Select
                    key={index}
                    styles={clientStyles}
                    value={{
                      label: newCompanies[index].name,
                      value: newCompanies[index].name,
                    }}
                    options={companyOptions}
                    placeholder={t('company')}
                    onChange={(e) => {
                      setDeleteCompanyGroup((prev) => [...prev, newCompanies[index]?.id])
                      selectCompany(e, index)
                    }}
                  />
                </div>

                {newCompanies?.length > 1 && (
                  <div className="flex justify-center items-center">
                    <button
                      className="add-remove-stamp-field z-0"
                      onClick={(e) => {
                        removeCompanySelect(e, index, 'nonSelect')
                      }}
                    >
                      -
                    </button>
                  </div>
                )}
                <div className="flex justify-center items-center">
                  <button
                    className="add-remove-stamp-field z-0"
                    onClick={(e) => addCompanySelect(e, index, 'nonSelect')}
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
          </form>
        </div>
      </NewModal>
    </>
  )
}

export default EditUsersCompanies
