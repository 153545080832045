import ActionList from '../components/table/components/ActionList'
import TableActions from '../components/table/components/TableActions'

const useTableHooks = (actions) => {
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: 'action',
        Header: 'Akcije',
        Cell: ({ row }) => {
          return (
            <TableActions>
              <ActionList actions={actions} row={row} />
            </TableActions>
          )
        },
      },
    ])
  }

  return tableHooks
}

export default useTableHooks
