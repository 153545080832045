export const clientStyles = {
  control: (base, state) => ({
    ...base,
    height: '43.2px',
    background: '#ffffff',
    color: '#000000',
    borderColor: state.isDisabled ? 'red' : '#DFDFDF',
    borderWidth: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderRadius: 10,
    boxShadow: '#DFDFDF',
    '&:hover': {
      borderColor: '#DFDFDF',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'default', // Promijenjeni kursor kada je onemogućeno
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: '#D1D5E2',
    fontWeight: '400',
    textAlign: 'left',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
  }),
  option: (base) => ({
    ...base,
    background: '#fff',
    color: '#000000',
    fontWeight: '400',
    textAlign: 'left',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#000000',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    maxWidth: 'calc(100% - 8px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  indicatorSeparator: () => null,
  downChevron: (base) => ({
    ...base,
    backgroundColor: '#073C59',
    color: '#073C59',
  }),
}
