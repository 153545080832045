import React from 'react'

const DocIcon = () => {
  return (
    <svg width="29" height="39" viewBox="0 0 29 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 5.14282C5.56953 5.14282 4 6.71235 4 8.64282V29.6428C4 31.5733 5.56953 33.1428 7.5 33.1428H21.5C23.4305 33.1428 25 31.5733 25 29.6428V13.8928H18C17.032 13.8928 16.25 13.1108 16.25 12.1428V5.14282H7.5ZM18 5.14282V12.1428H25L18 5.14282ZM10.125 19.1428H18.875C19.3562 19.1428 19.75 19.5366 19.75 20.0178C19.75 20.4991 19.3562 20.8928 18.875 20.8928H10.125C9.64375 20.8928 9.25 20.4991 9.25 20.0178C9.25 19.5366 9.64375 19.1428 10.125 19.1428ZM10.125 22.6428H18.875C19.3562 22.6428 19.75 23.0366 19.75 23.5178C19.75 23.9991 19.3562 24.3928 18.875 24.3928H10.125C9.64375 24.3928 9.25 23.9991 9.25 23.5178C9.25 23.0366 9.64375 22.6428 10.125 22.6428ZM10.125 26.1428H18.875C19.3562 26.1428 19.75 26.5366 19.75 27.0178C19.75 27.4991 19.3562 27.8928 18.875 27.8928H10.125C9.64375 27.8928 9.25 27.4991 9.25 27.0178C9.25 26.5366 9.64375 26.1428 10.125 26.1428Z"
        fill="white"
      />
    </svg>
  )
}

export default DocIcon
