import { createSlice } from '@reduxjs/toolkit'

const company = JSON.parse(localStorage.getItem('company'))

const initialState = { company: company ? company : {} }

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    resetCompany: () => initialState,
    setCompany: (state, action) => {
      state.company = action.payload
    },
  },
})

export const { setCompany, resetCompany } = companySlice.actions
export default companySlice.reducer
