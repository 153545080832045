import { format } from 'date-fns'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { BsFileCheckFill } from 'react-icons/bs'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useAsyncDebounce } from 'react-table'

import { useAssociatedFilesByCompanyAndUserQuery } from '../../../api/services/filesApi'
import FarwardFileModal from '../../modals/files/FarwardFileModal'
import ProtocolModal from '../../modals/files/ProtocolModal'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import NewTable from '../NewTable'

const TableAwaiting = () => {
  const user = useSelector((state) => state.user.user)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const [fileAddress, setFileAddress] = useState('')

  const [openProtocol, setOpenProtocol] = useState(false)
  const [openFarward, setOpenFarward] = useState(false)

  const [fileId, setFileId] = useState('')

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (key === 'Date') {
        if (value.trim() !== '') {
          const formattedDate = format(new Date(value), 'dd-MM-yyyy')
          queryString += `Date=${encodeURIComponent(formattedDate)}&`
        }
      } else {
        if (value.trim() !== '') {
          queryString += `${key}=${encodeURIComponent(value)}&`
        }
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('stamp_key'),
        accessor: 'stampKey' || 'name',
        Cell: ({ row }) => {
          return (
            <button
              className="cursor-pointer text-sky-700 hover:underline hover:text-sky-700/70"
              onClick={() => {
                setFileId(row?.values?.id)
                setFileAddress(row?.original?.address)
                setOpenProtocol(true)
              }}
            >
              {row.original.stampKey}
            </button>
          )
        },
      },
      {
        Header: t('client'),
        accessor: 'Client',
      },
      {
        Header: t('subject'),
        accessor: 'Subject',
      },
      {
        Header: t('location'),
        accessor: 'Location',
      },
      {
        Header: t('date'),
        accessor: 'Date',
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
    ],
    [],
  )

  const company = useSelector((state) => state.company.company)
  const [searchParams] = useSearchParams()

  const { data: allAwaiting } = useAssociatedFilesByCompanyAndUserQuery(
    {
      companyId: searchParams.get('id') ? searchParams.get('id') : company.id,
      userId: user?.id,
      page,
      pageSize,
      query: query ? query : '',
    },
    {
      skip: !searchParams.get('id') && !company.id,
    },
  )

  //Checking for completed comment
  const [isDone, setIsDone] = useState('')

  const closeModal = () => {
    setOpenProtocol(false)
  }

  const data = useMemo(() => (Array.isArray(allAwaiting?.files) ? allAwaiting?.files : []), [allAwaiting])

  const tableHooksArray = [
    {
      label: t('protocol'),
      Icon: BsFileCheckFill,
      onClick: (row) => {
        setFileId(row?.values?.id)
        setFileAddress(row?.original?.address)
        setOpenProtocol(true)
      },
      disabled: false,
    },
    {
      label: t('forward_doc_action'),
      Icon: FaArrowAltCircleRight,
      onClick: (row) => {
        setFileId(row?.values?.id)
        setOpenFarward(true)
      },
      disabled: false,
    },
  ]

  return (
    <>
      <div className="w-full">
        <NewTable
          columns={columns}
          data={data}
          hooksArray={tableHooksArray}
          hiddenColumns={['address', 'id']}
          actions
          filtering
          setPage={setPage}
          pageManual={page}
          setPageAmount={setPageSize}
          controlledPageCount={allAwaiting?.totalPages || 0}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          count={allAwaiting?.totalCount}
        />
      </div>
      <ProtocolModal
        id={fileId}
        address={fileAddress}
        isOpen={openProtocol}
        onClose={closeModal}
        setIsDone={setIsDone}
        isDone={isDone}
      />
      <FarwardFileModal id={fileId} isOpen={openFarward} onClose={setOpenFarward} />
    </>
  )
}
export default TableAwaiting
