import { useGetCompanyGroupsQuery } from '../../../../api/services/groupsApi'
import SelectInputRegular from '../../../inputs/SelectInputRegular'

function CustomSelectCompanyGroup({ companiesId, handleChange, userGroups }) {
  // Fetching company groups
  const { data: CompanyGroupById } = useGetCompanyGroupsQuery(companiesId, { skip: !companiesId })
  const groupsOptions = CompanyGroupById?.map((groups) => ({
    name: groups?.name,
    id: groups?.id,
    value: groups?.id,
    label: groups?.name,
    companyId: companiesId,
  }))

  return (
    <SelectInputRegular
      options={groupsOptions}
      placeholder={'Company group'}
      onChange={handleChange}
      value={userGroups && userGroups.length !== 0 ? userGroups.find((group) => group.companyId === companiesId) : null}
    />
  )
}

export default CustomSelectCompanyGroup
