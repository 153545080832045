import React from 'react'

const TableWrapper = ({ children }) => {
  return (
    <div className="max-w-[80vw]">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col w-full mb-2 ">{children}</div>
      </div>
    </div>
  )
}

export default TableWrapper
