import { api } from '../api'

export const stampsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllStamps: builder.query({
      query: () => ({
        url: '/stamps/get-all-stamps',
      }),
      providesTags: ['Stamps'],
    }),
    getStampOptions: builder.query({
      query: () => ({
        url: '/stamps/get-stamp-options',
      }),
      providesTags: ['Stamps'],
    }),
    getCompanyStamps: builder.query({
      query: (companyId) => ({
        url: `/stamps/get-stamps-for-one-company/${companyId}`,
      }),
      providesTags: ['Stamps'],
    }),
    getCompanyStampsPaginated: builder.query({
      query: ({ companyId, page, pageSize, query }) => ({
        url: `/stamps/get-stamps-for-one-company/${companyId}/${page}/${pageSize}${query ? `/${query}` : ''}`,
      }),
      providesTags: ['Stamps'],
    }),
    getDatabaseStamps: builder.query({
      query: ({ companyId }) => ({
        url: `/stamps/get-stamp-fields/${companyId}`,
      }),
      providesTags: ['Stamps'],
    }),
    getStampFieldsForEditFile: builder.query({
      query: ({ companyId, fileId }) => ({
        url: `/stamps/get-stamp-for-edit/${companyId}/${fileId}`,
      }),
      providesTags: ['Stamps'],
    }),
    getCustomStamps: builder.query({
      query: ({ companyId }) => ({
        url: `/stamps/get-stamp-fields/${companyId}`,
      }),
      providesTags: ['Stamps'],
    }),
    createCustomStamps: builder.mutation({
      query: ({ companyId, fields }) => ({
        url: `/stamps/create-stamp-fields/${companyId}`,
        method: 'POST',
        body: { fields },
      }),
      invalidatesTags: ['Stamps'],
    }),
    updateCustomStamp: builder.mutation({
      query: ({ companyId, fields }) => ({
        url: `/stamps/edit-stamp-fields/${companyId}`,
        method: 'PUT',
        body: { fields },
      }),
      invalidatesTags: ['Stamps'],
    }),
    stamp: builder.mutation({
      query: ({ id, companyId, stamp }) => ({
        url: `/stamps/${id}/stamp/${companyId}`,
        method: 'POST',
        body: { data: stamp },
      }),
      invalidatesTags: ['Stamps'],
    }),
    getNewCompanyStampsPaginated: builder.query({
      query: ({ companyId, page, pageSize, query }) => ({
        url: `/stamps/get-stamps/for-one-company/${companyId}/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Stamps'],
    }),
  }),
})

export const {
  useGetAllStampsQuery,
  useGetStampOptionsQuery,
  useGetCompanyStampsQuery,
  useGetDatabaseStampsQuery,
  useLazyGetDatabaseStampsQuery,
  useGetCustomStampsQuery,
  useGetStampFieldsForEditFileQuery,
  useCreateCustomStampsMutation,
  useUpdateCustomStampMutation,
  useStampMutation,
  useGetCompanyStampsPaginatedQuery,
  useGetNewCompanyStampsPaginatedQuery,
} = stampsApi
