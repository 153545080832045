import { Link } from 'react-router-dom'

const SidebarItemWrapper = ({ link, children }) => {
  return (
    <Link
      to={link.link}
      className="w-full flex justify-center tooltip tooltip-right hover:bg-white/10"
      data-tip={link.title}
    >
      {children}
    </Link>
  )
}

export default SidebarItemWrapper
