import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { logout } from '../../../api/features/userSlice'
import NewModal from '../../root/modals/NewModal'

const LogoutModal = ({ open, onClose }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logOut = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <NewModal
      showModal={open}
      closeModal={onClose}
      hideClose
      title={t('you_sure_logout')}
      btns
      titleClose={t('close')}
      titleSubmit={t('logout')}
      onClickClose={onClose}
      onClickSubmit={logOut}
      noChildren
    />
  )
}

export default LogoutModal
