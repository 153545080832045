import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { BsThreeDotsVertical } from 'react-icons/bs'

function Modal({ children, buttonRef, onClose }) {
  const [element, setElement] = useState(null)
  const modalRef = useRef(null)
  // console.log(element)

  useLayoutEffect(() => {
    setElement(modalRef.current)
  }, [])

  //Hide actions menu when clicked
  useEffect(() => {
    const handleClick = () => {
      setTimeout(() => {
        onClose()
      }, 1)
    }

    if (element) {
      element.addEventListener('click', handleClick)
    }

    return () => {
      if (element) {
        element.removeEventListener('click', handleClick)
      }
    }
  }, [element, onClose])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        onClose()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [onClose])

  const buttonRect = buttonRef.current.getBoundingClientRect()
  const modalRect = modalRef.current?.getBoundingClientRect()
  // console.log('window.innerHeight', window.innerHeight)
  // console.log('window.scrollY ', window.scrollY)
  // console.log('buttonRect', buttonRect)
  // console.log('modalRect', modalRect)

  const modalStyle = {
    position: 'absolute',
    top: buttonRect.top + window.scrollY,
    left: buttonRect.left - 220,
    // right: 50,
    background: '#ffffff',
    zIndex: 9999,
  }

  // if (buttonRect.bottom + 90 > window.innerHeight) {
  //   modalStyle.top = buttonRect.bottom - 200;
  // }
  if (buttonRect.bottom + window.scrollY + modalRect?.height > window.innerHeight + window.scrollY) {
    // modalStyle.top = buttonRect.bottom - 230
    modalStyle.top = buttonRect.bottom + window.scrollY - modalRect?.height
  }

  return createPortal(
    <ul
      ref={modalRef}
      tabIndex={0}
      className="dropdown-content z-[9999] shadow bg-base-100 rounded-box max-h-[400px] w-52 overflow-auto bg-white"
      style={modalStyle}
    >
      {children}
    </ul>,
    document.querySelector('#root-modal'),
  )
}

const TableActions = ({ children }) => {
  const [showModal, setShowModal] = useState(false)
  const buttonRef = useRef(null)

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      <button
        className="hover:cursor-pointer hover:bg-neutral hover:rounded p-2 active:opacity-30 transition-all duration-300 border-2 border-neutral rounded-lg"
        onClick={() => setShowModal(true)}
        type="button"
        ref={buttonRef}
      >
        <BsThreeDotsVertical />
      </button>
      {showModal && (
        <Modal buttonRef={buttonRef} onClose={closeModal}>
          {children}
        </Modal>
      )}
    </div>
  )
}

export default TableActions
