import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useGetAllFunctionsQuery } from '../../../../api/services/functionsApi'
import { useCreateGroupsMutation } from '../../../../api/services/groupsApi'
import FunctionCheckbox from '../../../other/FunctionCheckbox'
import NewModal from '../../../root/modals/NewModal'

const CreateCompanyGroupsModal = ({ open, onClose }) => {
  const [grupName, setGrupName] = useState('')
  const [functions, setFunctions] = useState([])
  const { data: allFunctions } = useGetAllFunctionsQuery()
  const { t } = useTranslation()
  const [createCompanyGroup] = useCreateGroupsMutation()

  const company = useSelector((state) => state.company.company)

  //Select optinos
  let functionOptions = []

  allFunctions?.forEach((fun, index) => {
    if (fun.name !== 'admin-dashboard' && fun.name !== 'selectcompany')
      functionOptions.push({ value: index, label: fun.name })
  })

  //Subbmit group
  const handeGroupSubbmit = async (e) => {
    if (!functions.length || !grupName.length) {
      return toast.error(t('input_all_fields'))
    }

    const group = { functions, name: grupName }
    try {
      const response = await createCompanyGroup({ group, companyId: company?.id }).unwrap()
      setFunctions([])
      setGrupName('')
      toast.success(response.message)
      onClose()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  const handleChange = (label) => {
    let includes = functions.includes(label)
    if (includes) {
      let newFunctions = [...functions]
      let index = newFunctions.indexOf(label)
      newFunctions.splice(index, 1)
      setFunctions(newFunctions)
    } else {
      setFunctions((prev) => [...prev, label])
    }
  }

  return (
    <NewModal
      showModal={open}
      closeModal={onClose}
      setShowModal={onClose}
      btns
      titleClose={t('close')}
      titleSubmit={t('submit')}
      title={t('create_group')}
      onClickClose={onClose}
      onClickSubmit={handeGroupSubbmit}
    >
      <>
        <form className="justify-center">
          <div className="w-full">
            <div className="flex flex-col w-full">
              <input
                type="text"
                name="name"
                value={grupName}
                placeholder={t('name')}
                className="w-full h-[48px] rounded-lg border-[1px] border-[#E6E6E5] py-[9px] px-[8px] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5"
                onChange={(e) => setGrupName(e.target.value)}
              />
            </div>

            {/* Funcions select */}
            <label className="font-popins font-semibold w-full flex my-4 text-base leading-6 text-[#073C59]">
              {t('choose_functions')}
            </label>
            <div className="w-full grid grid-cols-2 grid-rows-4">
              {functionOptions?.map((fun, index) => (
                <button
                  className="text-left"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault()
                    handleChange(fun.label)
                  }}
                >
                  <FunctionCheckbox label={fun.label} checked={functions.includes(fun.label)} />
                </button>
              ))}
            </div>
          </div>
        </form>
      </>
    </NewModal>
  )
}

export default CreateCompanyGroupsModal
