import React from 'react'
import { useLocation } from 'react-router-dom'

const PageWrapper = ({ children }) => {
  const location = useLocation()

  const isCreateStamp = location.pathname === '/scandocument'
  const isConfig = location.pathname === '/config'

  return (
    <div
      className={`${isCreateStamp ? 'h-screen' : 'max-h-[94.5vh]'} overflow-y-auto p-2 w-full flex justify-center items-start ${isConfig || isCreateStamp ? 'pb-20' : 'pb-15'} ${!isCreateStamp ? 'pt-5' : 'pt-0'}`}
    >
      <div className="w-[90%] h-full">{children}</div>
    </div>
  )
}

export default PageWrapper
