import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { RiArrowGoBackFill } from 'react-icons/ri'
import { useAsyncDebounce } from 'react-table'
import 'react-toastify/dist/ReactToastify.css'

import { useGetAllDeleteCompaniesQuery } from '../../../api/services/companiesApi'
import useDarkMode from '../../../hooks/useDarkMode'
import { RestoreDeletedCompany } from '../../modals/modals'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import NewTable from '../NewTable'

const TableDeletedCompanies = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('address'),
        accessor: 'address',
      },
      {
        Header: t('phone'),
        accessor: 'phone',
      },
      {
        Header: 'Tax',
        accessor: 'tax',
        Cell: ({ row }) => {
          const tax = row.original.tax === true
          return <td>{tax ? 'True' : 'False'}</td>
        },
      },
      {
        Header: t('created'),
        accessor: 'createdAt',
        Cell: ({ row }) => {
          const createdAt = row.original.createdAt
          if (!createdAt) return ''
          return createdAt.substr(0, 10)
        },
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
    ],
    [],
  )

  const [company, setCompany] = useState()
  const [open, setOpen] = useState(false)

  useDarkMode()

  const { data: deletedCompanies } = useGetAllDeleteCompaniesQuery({ page, pageSize, query: query ? query : '' })

  const data = useMemo(
    () => (Array.isArray(deletedCompanies?.companies) ? deletedCompanies?.companies : []),
    [deletedCompanies],
  )
  // // Actions buttons
  const tableHooksArray = [
    {
      label: t('restore'),
      Icon: RiArrowGoBackFill,
      onClick: (row) => {
        setCompany(row.original)
        setOpen(true)
      },
      disabled: false,
    },
  ]

  return (
    <>
      <NewTable
        columns={columns}
        data={data}
        actions
        filtering
        hooksArray={tableHooksArray}
        setPage={setPage}
        pageManual={page}
        setPageAmount={setPageSize}
        controlledPageCount={deletedCompanies?.total_pages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={deletedCompanies?.count}
        hiddenColumns={['id']}
      />

      <RestoreDeletedCompany open={open} onClose={() => setOpen(false)} companyId={company?.id} />
    </>
  )
}
export default TableDeletedCompanies
