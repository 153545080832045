import React from 'react'

const PDFDocumentIcon = () => {
  return (
    <svg width="63" height="64" viewBox="0 0 63 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.391785 8.72632C0.391785 4.31382 3.97928 0.726318 8.39178 0.726318H28.3918V16.7263C28.3918 18.9388 30.1793 20.7263 32.3918 20.7263H48.3918V38.7263H22.3918C17.9793 38.7263 14.3918 42.3138 14.3918 46.7263V64.7263H8.39178C3.97928 64.7263 0.391785 61.1388 0.391785 56.7263V8.72632ZM48.3918 16.7263H32.3918V0.726318L48.3918 16.7263ZM22.3918 44.7263H26.3918C30.2543 44.7263 33.3918 47.8638 33.3918 51.7263C33.3918 55.5888 30.2543 58.7263 26.3918 58.7263H24.3918V62.7263C24.3918 63.8263 23.4918 64.7263 22.3918 64.7263C21.2918 64.7263 20.3918 63.8263 20.3918 62.7263V56.7263V46.7263C20.3918 45.6263 21.2918 44.7263 22.3918 44.7263ZM26.3918 54.7263C28.0543 54.7263 29.3918 53.3888 29.3918 51.7263C29.3918 50.0638 28.0543 48.7263 26.3918 48.7263H24.3918V54.7263H26.3918ZM38.3918 44.7263H42.3918C45.7043 44.7263 48.3918 47.4138 48.3918 50.7263V58.7263C48.3918 62.0388 45.7043 64.7263 42.3918 64.7263H38.3918C37.2918 64.7263 36.3918 63.8263 36.3918 62.7263V46.7263C36.3918 45.6263 37.2918 44.7263 38.3918 44.7263ZM42.3918 60.7263C43.4918 60.7263 44.3918 59.8263 44.3918 58.7263V50.7263C44.3918 49.6263 43.4918 48.7263 42.3918 48.7263H40.3918V60.7263H42.3918ZM52.3918 46.7263C52.3918 45.6263 53.2918 44.7263 54.3918 44.7263H60.3918C61.4918 44.7263 62.3918 45.6263 62.3918 46.7263C62.3918 47.8263 61.4918 48.7263 60.3918 48.7263H56.3918V52.7263H60.3918C61.4918 52.7263 62.3918 53.6263 62.3918 54.7263C62.3918 55.8263 61.4918 56.7263 60.3918 56.7263H56.3918V62.7263C56.3918 63.8263 55.4918 64.7263 54.3918 64.7263C53.2918 64.7263 52.3918 63.8263 52.3918 62.7263V54.7263V46.7263Z"
        fill="#006EAB"
      />
    </svg>
  )
}

export default PDFDocumentIcon
