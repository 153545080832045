import ReactDOM from "react-dom";
import { t } from "i18next";
import Modal from "../../root/modals/Modal";

const FreezeAppModal = ({ open }) => {
  return (
    <Modal isOpen={open} hideClose>
      <div className="freeze-app-modal">
        <h1 className="text-white">{t("wait_send")}</h1>
      </div>
    </Modal>
  );
};

export default FreezeAppModal;
