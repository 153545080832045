import React from 'react'

const ProtocolBookIcon = () => {
  return (
    <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25 3C5.35156 3 3 5.35156 3 8.25V25.75C3 28.6484 5.35156 31 8.25 31H24H25.75C26.718 31 27.5 30.218 27.5 29.25C27.5 28.282 26.718 27.5 25.75 27.5V24C26.718 24 27.5 23.218 27.5 22.25V4.75C27.5 3.78203 26.718 3 25.75 3H24H8.25ZM8.25 24H22.25V27.5H8.25C7.28203 27.5 6.5 26.718 6.5 25.75C6.5 24.782 7.28203 24 8.25 24ZM10 10.875C10 10.3938 10.3938 10 10.875 10H21.375C21.8563 10 22.25 10.3938 22.25 10.875C22.25 11.3562 21.8563 11.75 21.375 11.75H10.875C10.3938 11.75 10 11.3562 10 10.875ZM10.875 13.5H21.375C21.8563 13.5 22.25 13.8938 22.25 14.375C22.25 14.8562 21.8563 15.25 21.375 15.25H10.875C10.3938 15.25 10 14.8562 10 14.375C10 13.8938 10.3938 13.5 10.875 13.5Z"
        fill="white"
      />
    </svg>
  )
}

export default ProtocolBookIcon
