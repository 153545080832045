import React from 'react'

const PrintIcon = () => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_182_7414)">
        <path
          d="M7 0.142822C5.06953 0.142822 3.5 1.71235 3.5 3.64282V8.89282H7V3.64282H19.3977L21 5.24517V8.89282H24.5V5.24517C24.5 4.31548 24.1336 3.42407 23.4773 2.76782L21.875 1.16548C21.2188 0.509229 20.3273 0.142822 19.3977 0.142822H7ZM21 19.3928V21.1428V24.6428H7V21.1428V20.2678V19.3928H21ZM24.5 21.1428H26.25C27.218 21.1428 28 20.3608 28 19.3928V14.1428C28 12.2124 26.4305 10.6428 24.5 10.6428H3.5C1.56953 10.6428 0 12.2124 0 14.1428V19.3928C0 20.3608 0.782031 21.1428 1.75 21.1428H3.5V24.6428C3.5 26.5733 5.06953 28.1428 7 28.1428H21C22.9305 28.1428 24.5 26.5733 24.5 24.6428V21.1428ZM23.625 13.7053C23.9731 13.7053 24.3069 13.8436 24.5531 14.0897C24.7992 14.3359 24.9375 14.6697 24.9375 15.0178C24.9375 15.3659 24.7992 15.6998 24.5531 15.9459C24.3069 16.192 23.9731 16.3303 23.625 16.3303C23.2769 16.3303 22.9431 16.192 22.6969 15.9459C22.4508 15.6998 22.3125 15.3659 22.3125 15.0178C22.3125 14.6697 22.4508 14.3359 22.6969 14.0897C22.9431 13.8436 23.2769 13.7053 23.625 13.7053Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_7414">
          <rect width="28" height="28" fill="white" transform="translate(0 0.142822)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PrintIcon
