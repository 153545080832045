import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { useGetCompanyGroupsQuery } from '../../../../api/services/groupsApi'
import { useRegisterUserForCompanyMutation } from '../../../../api/services/usersApi'
import { clientStyles } from '../../../../data/createStampSelectStyles'
import NewModal from '../../../root/modals/NewModal'

const CreateUserModal = ({
  open,
  company,
  setUser,
  user,
  permissions,
  archive,
  recipient,
  setPermissions,
  setArchive,
  setRecipient,
  onClose,
  warning,
  setWarning,
  emailNotification,
  setEmailNotification,
}) => {
  const { t } = useTranslation()
  const { data: companyGroups } = useGetCompanyGroupsQuery(company?.id)

  const permissionOptions = companyGroups?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }))
  const [registerUserForCompany] = useRegisterUserForCompanyMutation()

  const archiveOptions = [
    { value: 'All', label: 'All' },
    { value: 'Mine', label: 'Mine' },
  ]

  const recipientOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]
  const warningOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]
  const emailNotificationOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]

  //Creating a user
  const handleOnChange = (e) => {
    if (!e.target) {
      const value = e.value

      //If from archive select
      if (value === 'All' || value === 'Mine') {
        setUser({
          ...user,
          archive: e.value,
        })
      }
      //If from permission select
      else {
        setUser({
          ...user,
          groups: [e.label],
        })
      }
    }
    //If not from react-select
    else {
      const value = e.target.value
      setUser({
        ...user,
        [e.target.name]: value,
      })
    }
  }

  const handleSubbmit = async (e) => {
    e.preventDefault()
    const sendUser = {
      ...user,
      groups: [permissions?.label],
      archive: archive?.value,
      recipient: recipient?.value,
      warning: warning?.value,
      emailNotification: emailNotification?.value,
    }
    try {
      const res = await registerUserForCompany({ user: sendUser, company: company?.id }).unwrap()
      setUser({})
      setPermissions()
      setArchive()
      setRecipient()
      onClose()
      toast.success(res.message)
    } catch (error) {
      toast.error(error.data?.message)
    }
  }

  return (
    <NewModal
      closeModal={onClose}
      showModal={open}
      hideClose
      btns
      page={'middle'}
      title={t('reg_user')}
      titleClose={t('close')}
      onClickClose={onClose}
      titleSubmit={t('reg_user')}
      onClickSubmit={handleSubbmit}
    >
      <form className="flex flex-col gap-4">
        <div className="flex gap-2">
          <input
            type="email"
            value={user.email || ''}
            placeholder={t('email')}
            onChange={handleOnChange}
            name="email"
            className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
          />
          <input
            type="text"
            value={user.firstName || ''}
            placeholder={t('first_name')}
            styles={clientStyles}
            onChange={handleOnChange}
            name="firstName"
            className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
          />
        </div>
        <div className="flex gap-2">
          <input
            type="text"
            value={user.lastName || ''}
            styles={clientStyles}
            placeholder={t('last_name')}
            onChange={handleOnChange}
            name="lastName"
            className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
          />

          <input
            type="text"
            value={user.username || ''}
            placeholder={t('username')}
            styles={clientStyles}
            onChange={handleOnChange}
            name="username"
            className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
          />
        </div>
        <div className="flex gap-2">
          <input
            type="text"
            value={user.address || ''}
            placeholder={t('adress')}
            styles={clientStyles}
            onChange={handleOnChange}
            name="address"
            className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
          />

          <input
            type="password"
            value={user.password || ''}
            styles={clientStyles}
            placeholder={t('password')}
            onChange={handleOnChange}
            name="password"
            className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
          />
        </div>
        <div className="grid grid-cols-2 gap-4 pb-4">
          {/* Permission select */}
          <div className="w-full">
            <Select
              options={permissionOptions}
              styles={clientStyles}
              value={permissions || null}
              placeholder={t('per_groups')}
              name="permissions"
              onChange={(e) => setPermissions(e)}
            />
          </div>
          {/* Archive select */}
          <div className="w-full">
            <Select
              options={archiveOptions}
              styles={clientStyles}
              value={archive || null}
              placeholder={t('arc')}
              name="archive"
              onChange={(e) => setArchive(e)}
            />
          </div>

          {/* Recipient select */}
          <div className="w-full">
            <Select
              options={recipientOptions}
              styles={clientStyles}
              value={recipient || null}
              placeholder={t('reciever')}
              name="archive"
              onChange={(e) => setRecipient(e)}
            />
          </div>
          {/* Warning select */}
          <div className="w-full">
            <Select
              options={warningOptions}
              styles={clientStyles}
              value={warning}
              placeholder={'Warning'}
              name="warning"
              onChange={(e) => setWarning(e)}
            />
          </div>

          {/* Email Notification */}
          <div className="w-full">
            <Select
              options={emailNotificationOptions}
              styles={clientStyles}
              value={emailNotification}
              placeholder={'Email notification'}
              name="emailNotification"
              onChange={(e) => setEmailNotification(e)}
            />
          </div>
        </div>
      </form>
    </NewModal>
  )
}

export default CreateUserModal
