export const values = [
  { name: 'Bosnian Convertible Mark', label: 'BAM', id: 1 },
  { name: 'Euro', label: 'EUR', id: 2 },
  { name: 'Serbian Dinar', label: 'RSD', id: 3 },
  { name: 'United States Dollar', label: 'USD', id: 4 },
  { name: 'Japanese Yen', label: 'JEN', id: 5 },
  { name: 'British Pound Sterling', label: 'GBP', id: 6 },
  { name: 'Swiss Franc', label: 'CHF', id: 7 },
  { name: 'Canadian Dollar', label: 'CAD', id: 8 },
  { name: 'Australian Dollar', label: 'AUD', id: 9 },
  { name: 'Chinese Yuan', label: 'ÂĄ', id: 10 },
  { name: 'Indian Rupee', label: 'â‚ą', id: 11 },
  { name: 'Brazilian Real', label: 'R$', id: 12 },
]
