import { api } from '../api'

export const categoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCategory: builder.mutation({
      query: ({ categoryName, categoryDescription, requiredApproval, companyId }) => ({
        url: `/categories/create-category/${companyId}`,
        method: 'POST',
        body: { categoryName, categoryDescription, requiredApproval },
      }),
      invalidatesTags: ['Categories'],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/categories/delete-category/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Categories'],
    }),
    updateCategory: builder.mutation({
      query: ({ categoryId, newCategory }) => ({
        url: `/categories/update-category/${categoryId}`,
        body: newCategory,
        method: 'PUT',
      }),
      invalidatesTags: ['Categories'],
    }),
    getCategory: builder.query({
      query: (companyId) => ({
        url: `/categories/get-categories/${companyId}`,
      }),
      providesTags: ['Categories'],
    }),
    getAllCategories: builder.query({
      query: (companyId) => ({
        url: `/categories/get-categories/${companyId}`,
      }),
      providesTags: ['Categories'],
    }),
  }),
})

export const {
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoryQuery,
  useGetAllCategoriesQuery,
} = categoriesApi
