import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

import { useGetAttachmentsPaginatedQuery } from '../../../api/services/attachments'
import { AttachFileToFileModal, DeleteAttachmentModal, ViewModal } from '../../modals/modals'
import NewTable from '../NewTable'

const TableAttachments = () => {
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const param = useParams()

  const { data: attachments } = useGetAttachmentsPaginatedQuery({ fileId: param?.id, page, pageSize })

  const [openView, setOpenView] = useState(false)
  const [fileAddress, setFileAddress] = useState('')

  const [openDelete, setopenDelete] = useState(false)
  const [attachmentId, setAttachmentId] = useState('')

  const [openEditAttachment, setOpenEditAttachment] = useState(false)

  const [primaryDoc, setPrimaryDoc] = useState({})

  const dynamicColumns = [
    {
      Header: t('attachment_name'),
      accessor: 'attachmentName',
    },
  ]

  const columns = useMemo(() => (Array.isArray(dynamicColumns) ? dynamicColumns : []), [])

  const tableHooksArray = [
    {
      label: t('view'),
      Icon: AiFillEye,
      onClick: (row) => {
        setFileAddress(row.original.attachmentUrl)
        setOpenView(true)
      },
      disabled: false,
    },
    {
      label: t('edit'),
      Icon: FaEdit,
      onClick: (row) => {
        setPrimaryDoc(row.original)
        setOpenEditAttachment(true)
      },
      disabled: false,
    },
    {
      label: t('delete'),
      Icon: FaTrashAlt,
      onClick: (row) => {
        setAttachmentId(row.original.id)
        setopenDelete(true)
      },
      disabled: false,
    },
  ]

  const data = useMemo(() => (Array.isArray(attachments?.attachments) ? attachments?.attachments : []), [attachments])

  return (
    <div className="phone:hidden">
      <NewTable
        columns={columns}
        data={data}
        setPage={setPage}
        pageManual={page}
        setPageAmount={setPageSize}
        controlledPageCount={attachments?.totalPages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={attachments?.totalCount}
        hooksArray={tableHooksArray}
        actions
        filtering
        hiddenColumns={[
          'address',
          'id',
          //  isOnProtocolBook && 'delete',
          //   isOnProtocolBook && 'edit'
        ]}
      />

      <ViewModal open={openView} onClose={() => setOpenView(false)} address={fileAddress} page="report" />
      <DeleteAttachmentModal
        onClose={() => {
          setopenDelete(false)
          setAttachmentId('')
        }}
        attachmentId={attachmentId}
        open={openDelete}
      />
      <AttachFileToFileModal
        isEditAttachment={true}
        open={openEditAttachment}
        primaryDocument={primaryDoc}
        onClose={() => setOpenEditAttachment(false)}
      />
    </div>
  )
}
export default TableAttachments
