import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { setCompany } from '../../../../api/features/companySlice'
import { useEditCompanyMutation } from '../../../../api/services/companiesApi'
import SelectInputRegular from '../../../../components/inputs/SelectInputRegular'
import TextInputRegular from '../../../../components/inputs/TextInputRegular'
import NewModal from '../../../root/modals/NewModal'

const EditCompanyModal = ({ company, onClose, open }) => {
  const user = useSelector((state) => state.user.user)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [editCompany] = useEditCompanyMutation()
  const userFunctions = user?.functions

  const [newCompany, setNewCompany] = useState({})
  const [tax, setTax] = useState(false)
  // Company cuser is currently logged in to
  const LScompany = useSelector((state) => state.company.company)

  useEffect(() => {
    setTax(company?.tax)
    setNewCompany({ ...company, pin: '' })
  }, [company])

  const taxOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]

  const handleChange = (e) => {
    const company = { ...newCompany }

    company[e.target.name] = e.target.value
    setNewCompany(company)
  }
  const updateCompany = async (companyId, companyData) => {
    try {
      const response = await editCompany({ companyId, companyData }).unwrap()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  const handleSubmit = () => {
    let rememberme = localStorage.getItem('remember_me')

    if (!company.id || !newCompany.name || !newCompany.address || !newCompany.phone)
      return toast.error(t('input_all_fields'))

    const sendCompany = {
      ...company,
      ...newCompany,
      id: company.id || company.id, //In case localStorage company is beeing edited
      name: newCompany.name ? newCompany.name : company.name || company.label, //In case localStorage company is beeing edited
      tax: tax,
      pin: newCompany.pin ? newCompany.pin : '',
    }

    //In case of localStorage company editing
    let companyId = company.id || company.id
    delete sendCompany['createdAt']
    delete sendCompany['updatedAt']

    if (sendCompany.id === LScompany?.id) {
      const newLSCompany = {
        ...sendCompany,
        label: sendCompany.name,
        value: sendCompany.id,
      }

      if (rememberme === true) dispatch(setCompany(newLSCompany))
      else sessionStorage.setItem('Company', JSON.stringify(newLSCompany))
    }

    //If editing logged-in company
    //Update in localStorage
    if (sendCompany.value) {
      let rememberMe = localStorage.getItem('remember_me')
      if (rememberMe === 'true') {
        dispatch(setCompany(sendCompany))
      } else {
        dispatch(setCompany(sendCompany))
      }
    }
    if (sendCompany.value) delete sendCompany['value']
    if (sendCompany.label) delete sendCompany['label']
    updateCompany(companyId, sendCompany)
    setNewCompany({})
    onClose()
  }

  // Check if user is superadmin
  const adminUser = userFunctions
    ? userFunctions?.find((user_function) => user_function?.name === 'admin-dashboard')
    : false

  return (
    <>
      <NewModal
        closeModal={onClose}
        showModal={open}
        hideClose
        title={t('edit_company')}
        page={'middle'}
        btns
        titleClose={t('close')}
        titleSubmit={t('save')}
        onClickClose={onClose}
        onClickSubmit={handleSubmit}
      >
        <form className="flex flex-col gap-4">
          <TextInputRegular
            type="text"
            name="name"
            defaultValue={company.name || company.label}
            placeholder={t('company_name')}
            onChange={handleChange}
          />
          <TextInputRegular
            type="text"
            name="address"
            defaultValue={company.address}
            placeholder={t('address')}
            onChange={handleChange}
          />
          <TextInputRegular
            type="text"
            name="phone"
            defaultValue={company.phone}
            placeholder={t('phone')}
            onChange={handleChange}
          />
          {/* Only Admin user sets taxId's for companies */}
          {adminUser && (
            <>
              <SelectInputRegular
                options={taxOptions}
                placeholder="Tax..."
                defaultValue={{
                  value: company.tax,
                  label: company.tax === true ? 'True' : 'False',
                }}
                onChange={(e) => setTax(e.value)}
              />
              {tax && (
                <>
                  <TextInputRegular
                    type="text"
                    name="taxId"
                    onChange={handleChange}
                    value={newCompany['taxId'] !== undefined ? newCompany.taxId : company.taxId || ''}
                    placeholder="Tax ID"
                  />
                  <TextInputRegular type="password" name="pin" onChange={handleChange} placeholder="ERP PIN" />
                  <TextInputRegular
                    type="text"
                    name="ip"
                    onChange={handleChange}
                    value={newCompany['ip'] !== undefined ? newCompany.ip : company.ip || ''}
                    placeholder="ERP ip"
                  />
                </>
              )}
            </>
          )}
        </form>
      </NewModal>
    </>
  )
}

export default EditCompanyModal
