import { api } from '../api'

export const placeOfExpanseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPlaceOfExpanse: builder.mutation({
      query: ({ arr, type, id, companyId }) => ({
        url: `/place-of-expanse/create-place-of-expanse`,
        method: 'POST',
        body: { arr, type, id, companyId },
      }),
      invalidatesTags: ['PlaceOfExpanse'],
    }),
  }),
})

export const { useCreatePlaceOfExpanseMutation } = placeOfExpanseApi
