import React from 'react'

const RemoveField = ({
  index,
  nonSelect,
  inputField,
  spredValues,
  setInputFields,
  nonSelectStampFields,
  setNonSelectStampFields,
}) => {
  //Remove input field
  const removeInputField = (e, index, nonSelect) => {
    e.preventDefault()
    let values = []
    if (!nonSelect) {
      values = [...spredValues]
      values.splice(index, 1)
      setInputFields(values)
    } else {
      values = [...nonSelectStampFields]
      const indexInArr = values.findIndex((el) => el.uId === inputField.uId)
      values.splice(indexInArr, 1)
      setNonSelectStampFields(values)
    }
  }
  return (
    <button
      className='add-remove-stamp-field'
      onClick={(e) => removeInputField(e, index, nonSelect)}
    >
      -
    </button>
  )
}

export default RemoveField
