import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import Select from 'react-select'

import { useUserCompaniesQuery } from '../../api/services/companiesApi'
import { clientStyles } from '../../data/createStampSelectStyles'

const CompaniesTabs = () => {
  const user = useSelector((state) => state.user.user)
  const { data: userCompanies } = useUserCompaniesQuery(user?.id)

  const data = userCompanies?.map((item) => {
    return { label: item?.name, value: item.id, item }
  })

  const [selectedCompany, setSelectedCompany] = useState(data ? data[0] : null)

  const initialSearchParams = { name: data[0]?.label, id: data[0]?.value }

  const [searchParams, setSearchParams] = useSearchParams(initialSearchParams)

  const handleChange = (selectedOption) => {
    setSelectedCompany(selectedOption)
    setSearchParams({
      ...searchParams,
      name: selectedOption?.label || '',
      id: selectedOption?.value || '',
    })
  }

  useEffect(() => {
    if (data && data.length > 0) {
      setSelectedCompany(data[0])
    }
    setSearchParams(initialSearchParams)
  }, [userCompanies])

  return (
    <>
      <div>
        <Select
          options={data}
          styles={clientStyles}
          placeholder={'Kompanija'}
          value={selectedCompany}
          onChange={handleChange}
        />
      </div>
    </>
  )
}

export default CompaniesTabs
