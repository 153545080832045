import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { useDeleteRelationMutation, useGetRelatedDocumentsQuery } from '../../../api/services/documentRelations'
import NewTable from '../NewTable'

const TableRelations = ({ isOnProtocolBook }) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const sessionCompany = useSelector((state) => state.company.company)
  const localCompany = useSelector((state) => state.company.company)

  const company = Object.keys(localCompany)?.lenght ? localCompany : sessionCompany

  const fileId = useParams()
  const navigate = useNavigate()

  const { data: relatedDocuments } = useGetRelatedDocumentsQuery(
    {
      fileId: fileId?.id,
      page,
      pageSize,
    },
    { skip: !fileId?.id },
  )

  const [deleteRelation] = useDeleteRelationMutation(relatedDocuments?.id)

  const relateColumns = [
    {
      Header: t('primary_document'),
      accessor: 'primaryFileStampKey',
    },
    {
      Header: t('related_document'),
      accessor: 'relatedFileStampKey',
    },
  ]

  const columns = useMemo(() => relateColumns, [])

  const data = useMemo(() => (Array.isArray(relatedDocuments?.rows) ? relatedDocuments?.rows : []), [relatedDocuments])

  const newArray = data?.map((item) => {
    return item?.relatedFileId
  })

  const primaryId = [...new Set(data?.map((item) => item?.primaryFileId))]
  const primaryKey = primaryId[0]
  newArray.push(primaryKey)

  const tableHooksArray = [
    {
      label: t('edit'),
      Icon: FaEdit,
      onClick: (row) => {
        navigate(`/config/associate-files/edit-relations/${row.original.id}`)
      },
      disabled: false,
    },
    {
      label: t('delete'),
      Icon: FaTrashAlt,
      onClick: (row) => {
        deleteRelation(row?.original?.id, company?.value)
      },
      disabled: false,
    },
  ]

  return (
    <NewTable
      columns={columns}
      data={data}
      setPage={setPage}
      pageManual={page}
      setPageAmount={setPageSize}
      controlledPageCount={relatedDocuments?.totalPages || 0}
      filterInputs={filterInputs}
      setFilterInputs={setFilterInputs}
      count={relatedDocuments?.totalCount}
      actions={true}
      filtering
      hooksArray={tableHooksArray}
      hiddenColumns={['address', 'id', isOnProtocolBook && 'edit', isOnProtocolBook && 'delete']}
    />
  )
}
export default TableRelations
