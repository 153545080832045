import { t } from 'i18next'

export const breadcrumbsConstants = [
  {
    label: t('scan_doc'),
    value: 'scandocument',
  },
  {
    label: t('attached_docs'),
    value: 'attached-documents',
  },
  {
    label: t('protocol_book'),
    value: 'protocolbook',
  },
  {
    label: t('arch'),
    value: 'archive',
  },
  {
    label: t('awaiting_docs'),
    value: 'awaitingDocs',
  },
  {
    label: t('issued_docs'),
    value: 'izdatnice',
  },
  {
    label: t('stamp_collection'),
    value: 'stampCollection',
  },
  {
    label: t('config_menu'),
    value: 'config',
  },
  {
    label: 'Admin Dashboard',
    value: 'admin-dashboard',
  },
  {
    label: 'Admin Panel',
    value: 'admin-panel',
  },
  {
    label: t('logout'),
    value: '',
  },
]
