import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import img from '../../assets/images/protocol-logo.png'

export default function Loader({ load, percentLoaded }) {
  return (
    <>
      <Transition appear show={load} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => percentLoaded === 100}>
          <Transition.Child as={Fragment}>
            <div className="fixed inset-0 bg-black/70 z-40" />
          </Transition.Child>

          <div className="fixed inset-0 z-50">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="bg-transparent">
                  <div className="relative w-40 h-40">
                    <svg width="100%" height="100%" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        {/* Gradijent za napredak */}
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" style={{ stopColor: '#006EAB', stopOpacity: 1 }} />
                          <stop offset="50%" style={{ stopColor: '#3d88b3', stopOpacity: 1 }} />
                          <stop offset="100%" style={{ stopColor: '#3d88b3', stopOpacity: 1 }} />
                        </linearGradient>

                        {/* Gradijent za pozadinu */}
                        <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" style={{ stopColor: '#E5E7EB', stopOpacity: 1 }} />
                          <stop offset="100%" style={{ stopColor: '#3d88b3', stopOpacity: 1 }} />
                        </linearGradient>

                        <clipPath id="circleClip">
                          <circle cx="18" cy="18" r="15.5" />
                        </clipPath>
                      </defs>

                      {/* Pozadina kruga */}
                      <circle
                        cx="18"
                        cy="18"
                        r="16"
                        fill="none"
                        className="stroke-current text-gray-200"
                        strokeWidth="4"
                        stroke="url(#grad2)"
                      />

                      {/* Napredak kruga */}
                      <circle
                        cx="18"
                        cy="18"
                        r="16"
                        fill="none"
                        stroke="url(#grad1)"
                        strokeWidth="4"
                        strokeDasharray="100"
                        strokeDashoffset={100 - percentLoaded}
                        transform="rotate(-90 18 18)"
                      />

                      {/* Slika */}
                      <image x="2.5" y="2.5" width="31" height="31" href={img} clipPath="url(#circleClip)" />
                    </svg>
                    <p className="text-white mt-2">{percentLoaded}%</p>
                  </div>
                  <div className="z-[9999] text-white">
                    <p className="font-popins font-bold text-[24px] leading-6 text-center mt-8">In progress</p>
                    <p className="font-popins font-normal text-[18px] mt-3">Please wait.</p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
