import { useParams } from 'react-router-dom'

import TableRelations from '../../../../components/table/files/TableRelations'

const RelationsPage = () => {
  const { id } = useParams()

  return <TableRelations primaryDocumentId={id} />
}

export default RelationsPage
