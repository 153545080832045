import React from 'react'

const ApproveCheckboxIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 1C0.896875 1 0 1.89688 0 3V13C0 14.1031 0.896875 15 2 15H12C13.1031 15 14 14.1031 14 13V3C14 1.89688 13.1031 1 12 1H2ZM10.5312 6.53125L6.53125 10.5312C6.2375 10.825 5.7625 10.825 5.47188 10.5312L3.47187 8.53125C3.17812 8.2375 3.17812 7.7625 3.47187 7.47188C3.76562 7.18125 4.24062 7.17813 4.53125 7.47188L6 8.94063L9.46875 5.46875C9.7625 5.175 10.2375 5.175 10.5281 5.46875C10.8187 5.7625 10.8219 6.2375 10.5281 6.52812L10.5312 6.53125Z"
        fill="#328E02"
      />
    </svg>
  )
}

export default ApproveCheckboxIcon
