import { useState } from 'react'
import { useEffect } from 'react'

export default function () {
  const [theme, setTheme] = useState(localStorage.theme)
  const [checked, setChecked] = useState(localStorage.checked)
  const colorTheme = theme === 'dark' ? 'light' : 'dark'
  useEffect(() => {
    const root = window.document.documentElement
    if (colorTheme === 'dark') {
      setChecked(false)
    } else {
      setChecked(true)
    }

    root.classList.remove(colorTheme)
    root.classList.add(theme)
    localStorage.setItem('theme', theme)
    localStorage.setItem('checked', checked)
  }, [theme, colorTheme])
  return [colorTheme, setTheme, checked, setChecked]
}
