import { format } from 'date-fns'
import { Formik } from 'formik'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { toast } from 'react-toastify'

import { setSelectedPartner } from '../../api/features/fileSlice'
import { setStampTemplate } from '../../api/features/stampSlice'
import { useGetConfiguratorQuery } from '../../api/services/configuratorApi'
import { useLastStampKeyQuery } from '../../api/services/protocolApi'
import { useGetCustomStampsQuery } from '../../api/services/stampsApi'
import { values } from '../../data/Values/values'
import usePartners from '../../hooks/createstamp/usePartners'
import Selects from '../approve/Selects'
import { DropdownFileInput } from '../inputs/DropdownFileInput'
import MjestoTroska from '../other/MjestoTroska'
import { Button } from '../root/Button'
import DocumentTypeForm from './DocumentTypeForm'
import FileFormSlider from './FileFormSlider'
import StampForm from './StampForm'

const StampForFileForm = () => {
  const [pageState, setPageState] = useState(0)
  const { user } = useSelector((state) => state.user)
  const { company } = useSelector((state) => state.company)
  const { fileData, fileURL } = useSelector((state) => state.file)
  const { stampTemplate } = useSelector((state) => state.stamp)
  const { data: stampKey } = useLastStampKeyQuery(company?.id)

  const { data: config } = useGetConfiguratorQuery(company?.id)

  const [formikValues, setFormikValues] = useState({})

  const componentRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const partners = usePartners()

  // Stamp fields of the current company
  const { data: companyStampFields } = useGetCustomStampsQuery({ companyId: company?.id })

  /**
   * If the stamp was already submited to the redux state
   * Display the saved stamp on the form
   */
  const fields = stampTemplate.length > 0 ? stampTemplate : companyStampFields ? companyStampFields : []

  const initialValues = fields
    ? Object?.fromEntries(
        fields?.map((field) => {
          if (field?.attributeKey === 'Stamp ID') {
            return [field?.attributeKey, stampKey?.stampKey]
          } else if (field.attributeKey === 'Datum protokola') {
            const date = field.attributeValue
              ? field.attributeValue.replace(/-/g, '.')
              : format(new Date(), 'dd.MM.yyyy')

            const [day, month, year] = date?.split('.')
            const dateObject = !date || !month || !year ? '' : new Date(year, month - 1, day)

            return [field.attributeKey, dateObject]
          } else if (field.attributeKey === 'Datum dokumenta' || field.attributeKey === 'Datum predmeta') {
            const date = field.attributeValue ? field.attributeValue.replace(/-/g, '.') : ''

            const [day, month, year] = date?.split('.')
            const dateObject = !date || !month || !year ? '' : new Date(year, month - 1, day)

            return [field.attributeKey, dateObject]
          } else {
            return [field?.attributeKey, field?.attributeValue]
          }
        }),
      )
    : {}

  const handleSubmit = (data, forPrint) => {
    // Validate if all stamp fields have been filled
    const isCompleated = Object.values(data).every((key) => key)

    if (!isCompleated && !forPrint) {
      return toast.error(t('create_stamp'))
    }

    let clientName = ''
    // Find the partner and set it in the redux state
    if (company?.tax === true) {
      const selectedParnterId = Object.entries(data).find((entrie) => {
        return entrie[0] === 'Klijent'
      })

      const foundPartner = partners.find((partner) => partner.name === selectedParnterId[1])

      clientName = foundPartner?.name

      dispatch(
        setSelectedPartner({
          label: foundPartner?.name,
          value: foundPartner?.partner_id,
        }),
      )
    }

    // Modify the data to fit the for futher use
    const modifiedStamp = Object.entries(data)
      .map((entrie) =>
        entrie[0] === 'Datum protokola' || entrie[0] === 'Datum dokumenta' || entrie[0] === 'Datum predmeta'
          ? {
              attributeKey: entrie[0],
              attributeValue: new Date(entrie[1]).toLocaleDateString('en-UK').replace(/\//g, '-'),
            }
          : { attributeKey: entrie[0], attributeValue: entrie[1] },
      )
      .concat([
        {
          attributeKey: 'Protokolisao',
          attributeValue: `${user?.firstName} ${user?.lastName}`,
        },
      ])
      .concat([
        {
          attributeKey: 'Tip dokumenta',
          attributeValue: `${fileData?.issued ? 'IZLAZNI' : ''} ${fileData?.inSlide ? 'UVOZ' : ''} ${
            fileData?.outSlide ? 'IZVOZ' : ''
          } ${fileData?.reExport ? 'RE-EXPORT' : ''} ${fileData?.koDoc ? 'KO' : ''} ${fileData?.reciedved ? 'ULAZNI' : ''} ${fileData?.advanced ? 'AVANSNI DOKUMENT' : ''}`.trim(),
        },
      ])

    // "Klijent" field was an ID of partner
    // Needs to be replaces with the partner name
    if (company?.tax === true) {
      // find the index of the filed
      // This is used so the new field is put at the same index
      const clientIndex = modifiedStamp.findIndex((obj) => obj.attributeKey === 'Klijent')

      // remove the "Klient filed"
      const newStamp = modifiedStamp.filter((obj) => obj.attributeKey !== 'Klijent')

      // set the client name in the stamp
      newStamp.splice(clientIndex, 0, { attributeKey: 'Klijent', attributeValue: clientName })

      dispatch(setStampTemplate(newStamp))

      if (forPrint === false) {
        toast.success('Stamp saved')
      }
    } else {
      dispatch(setStampTemplate(modifiedStamp))
      if (forPrint === false) toast.success('Stamp saved')
    }
    if (forPrint === false) {
      setPageState(1)
    }
  }

  const uniqueStampTemplate = stampTemplate?.filter((inputField, index, self) => {
    return (
      index ===
      self.findIndex(
        (t) => t.attributeKey === inputField.attributeKey && t.attributeValue === inputField.attributeValue,
      )
    )
  })

  const stampForPrint = uniqueStampTemplate.map((item) => {
    if (item.attributeKey === 'Protokolisao' || item.attributeKey === 'Tip dokumenta') {
      return { ...item, displayOnStamp: true }
    } else {
      const myField = companyStampFields?.find((field) => field.attributeKey === item.attributeKey)

      return { ...item, displayOnStamp: myField?.displayOnStamp }
    }
  })

  return (
    <div className="h-[75vh] hidden lg:block">
      <div className="py-2 my-2">
        <FileFormSlider pageState={pageState} setPageState={setPageState} totalPages={3} />
      </div>
      <div className="flex w-full h-full">
        {pageState === 0 ? (
          <div className="flex flex-col gap-4 w-full">
            <div className="flex gap-4 justify-between w-full h-full">
              <div className="w-full flex flex-col gap-2 h-full">
                <DropdownFileInput />
              </div>
              <div className="flex flex-col gap-2 h-full w-full">
                <DocumentTypeForm setPageState={setPageState} />
                <div className="flex flex-col justify-between">
                  <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
                    {({ values, setFieldValue }) => {
                      return (
                        <StampForm
                          companyStampFields={companyStampFields}
                          setFormikValues={setFormikValues}
                          handleSubmit={handleSubmit}
                        />
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className="w-full flex gap-4">
              <div className="w-full">
                {Object.values(values).every((key) => key) ? (
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <Button className="w-full text-lg" white>
                          {t('print_stamp')}
                        </Button>
                      )
                    }}
                    content={() => componentRef.current}
                    pageStyle={' @page { size:  auto 62mm; margin: 12px !important }'}
                    // ' @page { size: auto 62mm; margin: 24px !important }'
                  />
                ) : (
                  <Button asDiv className="w-full text-lg" disabled>
                    {t('print_stamp')}
                  </Button>
                )}
              </div>

              <Button type="submit" disabled={!fileURL} onClick={() => handleSubmit(formikValues, false)}>
                {t('nextStep')}
              </Button>
            </div>
          </div>
        ) : pageState === 1 ? (
          <MjestoTroska list setPageState={setPageState} isOnScanDoc />
        ) : (
          <Selects setPageState={setPageState} />
        )}
      </div>

      {/* stamp table */}
      <div className="stamp-table">
        <table className={config?.printFormat === 'A4' ? 'CS-table' : 'CS-62mm'} ref={componentRef} id="table">
          {stampForPrint?.map((inputField, index) => {
            if (!inputField.displayOnStamp) return

            return (
              <tbody key={index}>
                <tr className="CS-th_th">
                  <th className="CS-th_th">
                    {inputField.attributeKey === 'Stamp ID' ? 'Šifra pečata' : inputField.attributeKey}
                  </th>
                  <td className="CS-td">{inputField.attributeValue}</td>
                </tr>
              </tbody>
            )
          })}
        </table>
      </div>
    </div>
  )
}

export default StampForFileForm
