import { useEffect, useState } from 'react'
// Import useEffect
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../../api/services/configuratorApi'
import NewModal from '../../../root/modals/NewModal'

const SetProtocolStartModal = ({ open, onClose }) => {
  const { t } = useTranslation()
  const company = useSelector((state) => state.company.company)
  const { data: companyConfig } = useGetConfiguratorQuery(company?.id) // Destructure refetch
  const [updateCompanyConfig] = useEditConfiguratorMutation()
  const [start, setStart] = useState(companyConfig?.num_key)

  useEffect(() => {
    // Set the start value initially with the retrieved companyConfig value
    if (companyConfig && companyConfig.key) {
      setStart(companyConfig.num_key)
    }
  }, [companyConfig])

  const setProtocolStart = async (val) => {
    try {
      if (val === '') return toast.error(t('input_all_fields'))

      const newConfig = {
        ...companyConfig,
      }

      newConfig['num_key'] = val

      delete newConfig['createdAt']
      delete newConfig['updatedAt']

      const response = await updateCompanyConfig({
        companyId: company?.id,
        updatedConfig: newConfig,
      }).unwrap()

      // Update the start value
      toast.success(response.message)
      setStart(val)

      onClose()
    } catch (error) {
      toast.error(error.data.message)
      setStart(companyConfig?.num_key)
      onClose()
    }
  }

  const handleSubmit = () => {
    setProtocolStart(start)
  }

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        hideClose
        title={t('set_st_pr_num')}
        btns
        titleClose={t('close')}
        titleSubmit={t('save')}
        onClickClose={onClose}
        onClickSubmit={handleSubmit}
      >
        <div className="flex flex-col items-center gap-8">
          <span>
            <input
              type="text"
              className="theme-input mt-10"
              placeholder=""
              onChange={(e) => {
                const value = e.target.value
                if (/^\d*$/.test(value)) {
                  setStart(value)
                }
              }}
              value={start}
            />
          </span>
        </div>
      </NewModal>
    </>
  )
}

export default SetProtocolStartModal
