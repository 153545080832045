import { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useCreateAttachmentMutation, useEditAttachmentMutation } from '../../../api/services/attachments'
import { PDFDocumentIcon } from '../../../assets/icons'
import NewModal from '../../root/modals/NewModal'

const AttachFileToFileModal = ({ open, onClose, primaryDocument, isEditAttachment }) => {
  const { t } = useTranslation()

  const [createAttachment] = useCreateAttachmentMutation()
  const [editAttachments] = useEditAttachmentMutation()

  const [selectedFile, setSelectedFile] = useState('')
  const [fileURL, setFileURL] = useState('')

  const attachDocument = async (file, attachmetDoc) => {
    try {
      const formData = new FormData()
      formData.append('file', attachmetDoc)
      formData.append('fileId', file?.id)

      await createAttachment({
        formData,
      })
        .unwrap()
        .then((result) => {
          toast.success(result.message)
        })
    } catch (error) {
      toast.error('Došlo je do greške!')
    }
  }

  const editAttachment = async (attachment, attachmetDoc) => {
    try {
      //Upload file to S3
      const formData = new FormData()
      formData.append('file', attachmetDoc)
      formData.append('id', attachment?.id)
      formData.append('fileId', attachment.fileId)

      await editAttachments({ formData })
        .unwrap()
        .then((result) => {
          toast.success(result.message)
        })
    } catch (error) {
      toast.error('Došlo je do greške!')
    }
  }

  //Drag and drop
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    onDrop: (e) => {
      let selectFile = e[0]

      const fileType = ['application/pdf']

      setSelectedFile(selectFile)
      toast.success(t('file_selected'))

      if (selectFile) {
        // setName(selectFile.name)

        if (selectFile && fileType.includes(selectFile.type)) {
          let reader = new FileReader()
          reader.readAsDataURL(selectFile)
          reader.onloadend = (e) => {
            setFileURL(e.target.result)
          }
        } else {
          setFileURL(null)
          toast.error('Dokument mora biti .PDF')
        }
      } else {
        toast.error('Izaberite dokument')
      }
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!isEditAttachment) attachDocument(primaryDocument, selectedFile)
    if (isEditAttachment) editAttachment(primaryDocument, selectedFile)
    onClose()
  }

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        setShowModal={onClose}
        onClickClose={onClose}
        onClickSubmit={handleSubmit}
        titleSubmit={t('save')}
        titleClose={t('close')}
        title={!selectedFile ? t('attach') : `${t('selected')}: ${selectedFile.name}`}
        btns
        page="report"
      >
        <div className="w-full h-[56vh] relative flex flex-col border bg-neutral rounded-xl p-1" {...getRootProps()}>
          <input {...getInputProps()} className="hidden absolute" id="file-input" required />
          <div className="h-full z-10">
            <iframe
              src={fileURL}
              frameBorder="0"
              width="100%"
              height="100%"
              title="test"
              type="application/pdf"
            ></iframe>
          </div>
          <div className="absolute inset-0 flex justify-center items-center">
            <PDFDocumentIcon />
          </div>
        </div>

        <div className="h-[2px] w-full bg-primary my-4 rounded-sm"></div>

        <div className="flex w-full justify-between items-center">
          <label {...getRootProps()} className="text-secondary font-semibold">
            {t('import_pdf')}
          </label>
          <label
            {...getRootProps()}
            className="bg-primary border py-1 text-white text-sm rounded-md w-22 flex justify-center cursor-pointer p-2"
          >
            {t('import_pdf')}
          </label>
        </div>
      </NewModal>
    </>
  )
}

export default AttachFileToFileModal
