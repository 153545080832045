import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  file: undefined,
  fileURL: '',
  fileTypeID: 1,
  fileCategoryID: 1,
  sendFile: undefined,
  selectedPartner: {},
  fileData: {
    issued: false,
    inSlide: false,
    outSlide: false,
    koDoc: false,
    advanced: false,
    reciedved: true,
    reExport: false,
  },
  placeOfExpence: [],
}

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setFile: (state, action) => {
      state.file = action.payload
    },
    setFileURL: (state, action) => {
      state.fileURL = action.payload
    },
    setFileType: (state, action) => {
      // NUMBER
      state.fileTypeID = action.payload
    },
    setFileCategory: (state, action) => {
      state.fileCategoryID = action.payload
    },
    setSendFile: (state, action) => {
      state.sendFile = action.payload
    },
    setFileData: (state, action) => {
      state.fileData = action.payload
    },
    setSelectedPartner: (state, action) => {
      state.selectedPartner = action.payload
    },
    setPlaceOfExpence: (state, action) => {
      state.placeOfExpence = state.placeOfExpence.concat(action.payload)
    },
    deleteOnePlaceOfExpence: (state, action) => {
      state.placeOfExpence = state.placeOfExpence.filter((place) => place.id !== action.payload)
    },
    resetFile: () => initialState,
    resetPlaceofExpence: (state) => {
      state.placeOfExpence = []
    },
  },
})

export const {
  setFile,
  setFileURL,
  setFileType,
  setFileCategory,
  setSendFile,
  setFileData,
  resetFile,
  setSelectedPartner,
  setPlaceOfExpence,
  deleteOnePlaceOfExpence,
  resetPlaceofExpence,
} = fileSlice.actions
export default fileSlice.reducer
