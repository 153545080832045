import React from 'react'

const ModalDataMobile = ({ row, setOpenDataTableModal, openDataTableModal, columns }) => {
  const data = row?.original

  const col = columns?.filter((item) => item?.accessor !== 'id')

  const handleSetOpen = () => {
    setOpenDataTableModal(!openDataTableModal)
  }
  return (
    <div className="lg:hidden relative">
      {openDataTableModal && (
        <div className="fixed inset-0 bg-black z-10">
          <div className="w-full h-full flex flex-col border border-[#E6E6E5] overflow-y-auto">
            <div className="bg-[#006EAB] h-[86px] flex items-center p-6 justify-between">
              <span onClick={handleSetOpen}>
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.16504 6.29365C0.774414 6.68428 0.774414 7.31865 1.16504 7.70928L7.16504 13.7093C7.55566 14.0999 8.19004 14.0999 8.58066 13.7093C8.97129 13.3187 8.97129 12.6843 8.58066 12.2937L3.28691 6.9999L8.57754 1.70615C8.96816 1.31553 8.96816 0.681152 8.57754 0.290527C8.18691 -0.100098 7.55254 -0.100098 7.16191 0.290527L1.16191 6.29053L1.16504 6.29365Z"
                    fill="#FEFEFE"
                  />
                </svg>
              </span>
            </div>
            <div className="w-full h-full flex border border-[#E6E6E5]">
              <div className="bg-[#EDECE7] w-[40%] flex flex-col justify-between items-center">
                {col?.map((item) => {
                  return (
                    <div className="flex justify-center items-center  w-full h-full">
                      <p className="font-popins font-semibold text-sm text-[#073C59]">{item?.Header}</p>
                    </div>
                  )
                })}
              </div>
              <div className="bg-[#F7F7F7] w-[60%] flex flex-col justify-between items-center">
                {col?.map((colItem) => {
                  return (
                    <div key={colItem.accessor} className="flex justify-center items-center w-full h-full">
                      <p className="font-popins font-normal text-sm leading-5 text-[#073C59]">
                        {data[colItem.accessor]}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ModalDataMobile
