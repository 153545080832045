import bs from 'date-fns/locale/bs'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import { AiFillPrinter } from 'react-icons/ai'
import { BsGearFill } from 'react-icons/bs'
import { useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import ReactToPrint from 'react-to-print'

import useTableHooks from '../../hooks/useTableHooks'
import Spinner from '../other/Spinner'
import { Button } from '../root/Button'
import ColumnFilterPagination from './ColumnFilterPagination'
import MobilePagination from './components/MobilePagination'
import PrefetchPagination from './components/PrefetchPagination'
import PrefetchPaginationUrl from './components/PrefetchPaginationUrl'
import { filterByFirstCharacter } from './filterByFirstCharacter'

registerLocale('bs', bs)

const NewTable = ({
  columns,
  data,
  isLoading,
  isFetching,
  actions,
  hooksArray,
  navbar = true,
  pagination = true,
  paginationUrl,
  filtering,
  filterRows = false,
  setFilteredRows,
  caption = '',
  hiddenColumns,
  print,
  componentRef,

  // Pagination
  controlledPageCount,
  setPage,
  setPageUrl,
  pageManual,
  pageManualUrl,
  setPageAmount,
  setPageAmountUrl,
  pageSizeUrl,
  count,
  filterInputs,
  setFilterInputs,
  searchParams,
  setSearchParams,

  // dynamic columns
  api = '',
  apiType = '',
  dynamicColumns,
}) => {
  const [openColumnSelection, setOpenColumnSelection] = useState(false)

  //FILTERS
  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    if (setPage) {
      setPage(1)
    } else if (setPageUrl) {
      setPageUrl(
        (prev) => {
          prev.set('page', '1')
          return prev
        },
        { replace: true },
      )
    }

    // IF it is a search query server pagination
    if (setSearchParams) {
      setSearchParams(
        (prev) => {
          prev.set(columnId, value)
          return prev
        },
        { replace: true },
      )
    }
    // IF it is a search params server pagination
    else {
      setFilterInputs((prev) => {
        const newValue = value.trim() || ' '
        return { ...prev, [columnId]: newValue }
      })
    }
  }, 600)

  const defaultColumn = useMemo(
    () => ({
      Filter: ({ column }) => (
        <ColumnFilterPagination
          column={column}
          handleFilterChange={handleFilterChange}
          count={count}
          searchParams={searchParams}
        />
      ),
    }),
    [filterInputs, handleFilterChange, count, searchParams],
  )

  //   const { user } = useAppSelector((state) => state.auth)
  //   const { data: columnData } = useGetFilterTableQuery(
  //     { user_id: user?.users_id, api, type: apiType },
  //     {
  //       skip: !user?.users_id || !api,
  //     },
  //   )

  //   const hiddenColumns = useMemo(() => (columnData?.columns ? JSON.parse(columnData?.columns) : []), [columnData])

  const cols = useMemo(() => (Array.isArray(columns) ? [...columns] : []), [columns, hiddenColumns])

  const tableHooks = useTableHooks(hooksArray)

  const tableInstance = useTable(
    {
      columns: cols,
      data,
      manualPagination: true,
      manualFilters: true,
      pageCount: controlledPageCount,
      defaultColumn,
      filterTypes: {
        filterByFirstCharacter: filterByFirstCharacter,
      },
      initialState: { pageSize: 25, hiddenColumns: hiddenColumns || '' },
      filterable: true,
    },
    useFilters,
    useGlobalFilter,
    actions && tableHooks,
    useSortBy,
    usePagination,
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageSize },
    setPageSize,
    pageCount,
    filteredRows,
  } = tableInstance

  useEffect(() => {
    if (filterRows) {
      setFilteredRows(filteredRows)
    }
  }, [filterRows, filteredRows])

  return (
    <div className="drop-shadow-[0px_0px_3px_rgba(0,0,0,0.25)] pt-1">
      {dynamicColumns ? (
        <div className="max-w-md">
          <button className="btn btn-bloc" onClick={() => setOpenColumnSelection(true)}>
            <BsGearFill className="h-6 w-6" />
          </button>
        </div>
      ) : null}
      {navbar && (
        <div className="flex items-end justify-between">
          <div className="w-full hidden lg:block">
            {pagination && (
              <PrefetchPagination
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
                pageCount={pageCount}
                setPage={setPage}
                setPageAmount={setPageAmount}
                pageManual={pageManual}
              />
            )}
            {paginationUrl && (
              <PrefetchPaginationUrl
                pageSize={pageSizeUrl}
                setPageSize={setPageSize}
                pageOptions={pageOptions}
                pageCount={pageCount}
                setPage={setPageUrl}
                setPageAmount={setPageAmountUrl}
                pageManual={pageManualUrl}
              />
            )}
          </div>
          <div className="lg:hidden w-full">
            <MobilePagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageOptions={pageOptions}
              pageCount={pageCount}
              setPage={setPage}
              setPageAmount={setPageAmount}
              pageManual={pageManual}
            />
          </div>
        </div>
      )}
      <div className={`w-full ${print ? 'max-h-[75vh]' : 'max-h-[80vh]'} overflow-auto max-w-full`}>
        <table {...getTableProps()} className={`text-xs border border-main-400 w-full`}>
          <caption className="text-center font-bold uppercase">{caption}</caption>
          <thead className="sticky top-[-1px] bg-main-regular">
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()} className="sticky z-0">
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                        textAlign: 'center',
                        borderBottom: column.borderBottom,
                        fontSize: '13px',
                        padding: '14px',
                        borderWidth: 0,
                      },
                    })}
                    className="p-2 border border-t-main-400  border-b-main-400  border-l-main-300  text-sm font-semibold first-of-type:border-l-main-400 last-of-type:border-r-main-400 text-secondary tracking-wide"
                  >
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                      </span>
                    </div>

                    {filtering && (
                      <div className="text-sky-900">{column.canFilter ? column.render('Filter') : null}</div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                // eslint-disable-next-line react/jsx-key
                <tr
                  {...row.getRowProps()}
                  className={`bg-main-gray-ligth text-secondary tracking-wide text-center border-t-[2px] border-t-white ${
                    isFetching ? 'text-gray-300' : ''
                  } `}
                >
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                            maxWidth: 100,
                            paddingTop: 10,
                            paddingBottom: 10,
                          },
                        })}
                        className="p-1"
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {print && (
        <ReactToPrint
          trigger={() => {
            return (
              <div className="mt-2 w-full flex flex-row-reverse">
                <div className="w-full max-w-sm">
                  <Button>
                    <div className="flex justify-center items-center gap-4">
                      <AiFillPrinter className="w-6 h-6" />
                      <p className="mb-0 mr-2 font-semibold">{t('print')}</p>
                    </div>
                  </Button>
                </div>
              </div>
            )
          }}
          content={() => componentRef.current}
          pageStyle=" @page { margin: 17.1mm 5mm  12.7mm 5mm !important} @media print { body { -webkit-print-color-adjust: exact; }"
        />
      )}

      {isLoading || isFetching ? (
        <div className="flex justify-center items-center mt-5">
          <Spinner />
        </div>
      ) : null}
    </div>
  )
}

export default NewTable
