import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { FaBuilding, FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useAsyncDebounce } from 'react-table'
import 'react-toastify/dist/ReactToastify.css'

import { useGetAllCompaniesNoPaginationQuery } from '../../../api/services/companiesApi'
import { useGetAllGroupsQuery, useGetMultipleCompanyGroupsMutation } from '../../../api/services/groupsApi'
import { useGetAllUsersQuery } from '../../../api/services/usersApi'
import { DeleteUserModal, EditUserModal, EditUsersCompanies } from '../../modals/modals'
import { Button } from '../../root/Button'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import SelectColumnFilterPaginated from '../components/SelectColumnFilterPaginated'
import NewTable from '../NewTable'

const TableUsers = ({ setOpen }) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const columns = useMemo(
    () => [
      {
        Header: t('first_name'),
        accessor: 'firstName',
      },
      {
        Header: t('last_name'),
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: t('username'),
        accessor: 'username',
      },
      {
        Header: t('arch_access'),
        accessor: 'archive',
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={[
                { value: 'All', label: 'All' },
                { value: 'Mine', label: 'Mine' },
              ]}
            />
          )
        },
      },
      {
        Header: t('company_name'),
        accessor: 'companyName',
        Cell: ({ row }) => {
          const userCompanies = row.original.companies
          return (
            <td className="w-full flex justify-center items-center flex-col">
              {userCompanies?.map((company, index) => (
                <p className="m-0" key={index}>
                  {company?.name}
                </p>
              ))}
            </td>
          )
        },
      },
      {
        Header: t('created2'),
        accessor: 'createdAt',
        Cell: ({ row }) => {
          const createdAt = row.original.createdAt
          if (!createdAt) return ''
          return createdAt.substr(0, 10)
        },
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
    ],
    [],
  )

  const [openDelete, setOpenDelete] = useState(false) //ostaje
  const [openEdit, setOpenEdit] = useState(false) //ostaje
  const [openEditCompanies, setOpenEditCompanies] = useState(false) //ostaje
  const [user, setUser] = useState({}) //ostaje
  const [newGroups, setNewGroups] = useState([]) // ostaje
  // const [archive, setArchive] = useState();
  const [recipient, setRecipient] = useState()
  const [warning, setWarning] = useState()
  const [emailNotification, setEmailNotification] = useState()

  const [newCompanies, setNewCompanies] = useState([])
  const [newOptions, setNewOptions] = useState([])

  const { data: allCompanies } = useGetAllCompaniesNoPaginationQuery()
  const { data: allUsers } = useGetAllUsersQuery({ page, pageSize, query: query ? query : '' })
  const { data: allGroups } = useGetAllGroupsQuery()
  const [getGoupsForCompanies] = useGetMultipleCompanyGroupsMutation()

  useEffect(() => {
    const idArr = newCompanies?.map((comp) => comp.id)

    getGoupsForCompanies({ companies: idArr || [] })
  }, [newCompanies])

  const data = useMemo(() => (Array.isArray(allUsers?.users) ? allUsers?.users : []), [allUsers])
  const allGroupOptions = allGroups
    ? allGroups?.map((option) => ({
        value: option.id,
        label: option.name,
      }))
    : []
  // Actions buttons
  const tableHooksArray = [
    {
      label: t('edit'),
      Icon: FaEdit,
      onClick: (row) => {
        setOpenEdit(true)
        setUser(row.original)
        setNewCompanies(row.original.companies)
        setRecipient({
          label: row.original.recipient === true ? 'True' : 'False',
          value: row.original.recipient,
        })
        setWarning({
          label: row.original.warning === true ? 'True' : 'False',
          value: row.original.warning,
        })
        setEmailNotification({
          label: row.original.emailNotification === true ? 'True' : 'False',
          value: row.original.emailNotification,
        })
        setNewGroups(row.original.groups)
      },
      disabled: false,
    },
    {
      label: t('companies'),
      Icon: FaBuilding,
      onClick: (row) => {
        setOpenEditCompanies(true)
        setUser(row.original)
        setNewCompanies(row.original.companies)
        setRecipient({
          label: row.original.recipient === true ? 'True' : 'False',
          value: row.original.recipient,
        })
        setWarning({
          label: row.original.warning === true ? 'True' : 'False',
          value: row.original.warning,
        })
        setNewGroups(row.original.groups)
      },
      disabled: false,
    },
    {
      label: t('delete'),
      Icon: FaTrashAlt,
      onClick: (row) => {
        setOpenDelete(true)
        setUser(row.original)
      },
      disabled: false,
    },
  ]

  const companyOptions = allCompanies?.companies?.map((company) => ({
    value: company?.id,
    label: company?.name,
  }))

  return (
    <>
      <div className="w-full flex justify-evenly">
        <div className="w-full">
          <div className="flex gap-4 py-4 max-w-sm">
            <Button onClick={setOpen}>{t('reg_user')}</Button>
          </div>
        </div>
      </div>

      <NewTable
        data={data}
        columns={columns}
        actions
        filtering
        hooksArray={tableHooksArray}
        hiddenColumns={['address']}
        setPage={setPage}
        pageManual={page}
        setPageAmount={setPageSize}
        controlledPageCount={allUsers?.total_pages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={allUsers?.total_count}
      />

      <DeleteUserModal user={user} open={openDelete} onClose={() => setOpenDelete(false)} />
      <EditUserModal
        onClose={() => {
          setOpenEdit(false)
        }}
        setNewCompanies={setNewCompanies} //ne diraj
        setNewOptions={setNewOptions} //pitaj
        newCompanies={newCompanies} //ne diraj ?
        setRecipient={setRecipient} //ne diraj
        newOptions={newOptions} // pitaj
        allOptions={allGroupOptions} //pitaj
        recipient={recipient} // ne diraj
        setGroup={setNewGroups} //ne diraj
        newGroup={newGroups} //ne diraj
        open={openEdit} // ne diraj
        user={user} //ne diraj
        warning={warning}
        setWarning={setWarning}
        emailNotification={emailNotification}
        setEmailNotification={setEmailNotification}
        isCompany
      />
      <EditUsersCompanies
        onClose={() => {
          setOpenEditCompanies(false)
        }}
        setNewCompanies={setNewCompanies}
        companyOptions={companyOptions}
        newCompanies={newCompanies}
        open={openEditCompanies}
        setGroup={setNewGroups}
        allOptions={allGroupOptions}
        newOptions={newOptions}
        newGroup={newGroups}
        user={user}
      />
    </>
  )
}
export default TableUsers
