import { format } from 'date-fns'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useAsyncDebounce } from 'react-table'

import { useGetAllCompaniesQuery } from '../../../api/services/companiesApi'
import { DeleteCompanyModal, EditCompanyModal } from '../../modals/modals'
import { Button } from '../../root/Button'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import NewTable from '../NewTable'

const TableCompanies = ({ setOpenCompany, setOpenGroup }) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const [openDelete, setOpenDelete] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [company, setCopmany] = useState({})

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (key === 'Date') {
        if (value.trim() !== '') {
          const formattedDate = format(new Date(value), 'dd-MM-yyyy')
          queryString += `Date=${encodeURIComponent(formattedDate)}&`
        }
      } else {
        if (value.trim() !== '') {
          queryString += `${key}=${encodeURIComponent(value)}&`
        }
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const { data: allCompanies } = useGetAllCompaniesQuery({ page, pageSize, query: query ? query : '' })

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('address'),
        accessor: 'address',
      },
      {
        Header: t('phone'),
        accessor: 'phone',
      },
      {
        Header: 'Tax',
        Cell: ({ row }) => {
          const tax = row?.original?.tax === true
          return <td>{tax ? 'True' : 'False'}</td>
        },
        accessor: 'tax',
      },
      {
        Header: t('created'),
        accessor: 'createdAt',
        Cell: ({ row }) => {
          const createdAt = row?.original?.createdAt
          if (!createdAt) return ''
          return createdAt.substr(0, 10)
        },
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
    ],
    [],
  )

  const data = useMemo(() => (Array.isArray(allCompanies?.companies) ? allCompanies?.companies : []), [allCompanies])

  const tableHooksArray = [
    {
      label: t('edit'),
      Icon: FaEdit,
      onClick: (row) => {
        setOpenEdit(true)
        setCopmany(row.original)
      },
      disabled: false,
    },
    {
      label: t('delete'),
      Icon: FaTrashAlt,
      onClick: (row) => {
        setOpenDelete(true)
        setCopmany(row.original)
      },
      disabled: false,
    },
  ]

  return (
    <>
      <div className="w-full flex gap-4">
        <Button onClick={setOpenCompany}>{t('create_company')}</Button>

        <Button onClick={setOpenGroup}>{t('create_group')}</Button>
      </div>

      <NewTable
        columns={columns}
        data={data}
        actions
        filtering
        hooksArray={tableHooksArray}
        setPage={setPage}
        pageManual={page}
        setPageAmount={setPageSize}
        controlledPageCount={allCompanies?.total_pages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={allCompanies?.count}
        hiddenColumns={['address', 'id']}
      />

      <DeleteCompanyModal onClose={() => setOpenDelete(false)} open={openDelete} company={company} />
      <EditCompanyModal
        onClose={() => {
          setOpenEdit(false)
        }}
        company={company}
        open={openEdit}
      />
    </>
  )
}
export default TableCompanies
