import React from 'react'

const UserIcon = () => {
  return (
    <svg width="56" height="64" viewBox="0 0 56 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_777_4974)">
        <path
          d="M28 32C32.2435 32 36.3131 30.3143 39.3137 27.3137C42.3143 24.3131 44 20.2435 44 16C44 11.7565 42.3143 7.68687 39.3137 4.68629C36.3131 1.68571 32.2435 0 28 0C23.7565 0 19.6869 1.68571 16.6863 4.68629C13.6857 7.68687 12 11.7565 12 16C12 20.2435 13.6857 24.3131 16.6863 27.3137C19.6869 30.3143 23.7565 32 28 32ZM22.2875 38C9.975 38 0 47.975 0 60.2875C0 62.3375 1.6625 64 3.7125 64H52.2875C54.3375 64 56 62.3375 56 60.2875C56 47.975 46.025 38 33.7125 38H22.2875Z"
          fill="#073C59"
        />
      </g>
      <defs>
        <clipPath id="clip0_777_4974">
          <rect width="56" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UserIcon
