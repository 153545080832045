import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsPaperclip } from 'react-icons/bs'
import { MdOutlineAdminPanelSettings } from 'react-icons/md'
import { RiFilePaper2Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import 'tippy.js/dist/tippy.css'

import { setOpenSidebar } from '../api/features/sidebarSlice'
import { useGetUserFunctionOnSelectCompanyQuery } from '../api/services/usersApi'
import {
  AdminIcon,
  ArchiveIcon,
  ConfigIcon,
  DocIcon,
  LogoutIcon,
  PrintIcon,
  ProtocolBookIcon,
  StampIcon,
} from '../assets/icons'
import Logo from '../assets/images/protocol.ico'
import { LogoutModal } from '../components/modals/modals'
import OpenSidebarBtn from './components/OpenSidebarBtn'
import SidebarAwaitingDocsLink from './components/SidebarAwaitingDocsLink'
import SidebarListItem from './components/SidebarListItem'
import SidebarLogoutLink from './components/SidebarLogoutLink'

const Sidebar = () => {
  const [openLogut, setOpenLogut] = useState(false)

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { openSidebar } = useSelector((state) => state.sidebar)
  const user = useSelector((state) => state.user.user)
  const company = useSelector((state) => state.company.company)

  const { data, isFetching, isSuccess } = useGetUserFunctionOnSelectCompanyQuery(
    { userId: user?.id, companyId: company.id },
    { skip: !user?.id || !company.id },
  )

  const functions = !isFetching && isSuccess ? data[company.name][0] : []

  const handleSetOpen = () => {
    dispatch(setOpenSidebar(!openSidebar))
  }

  const SidebarLinks = [
    {
      title: t('scan_doc'),
      icon: <PrintIcon className="h-10 w-10" />,
      link: '/scandocument',
      auth_key: 'scandocument',
    },
    {
      title: t('attached_docs'),
      icon: <BsPaperclip className="h-8 w-8 rotate-90" />,
      link: '/attached-documents',
      auth_key: 'attacheddocuments',
    },
    {
      title: t('protocol_book'),
      icon: <ProtocolBookIcon className="h-10 w-10" />,
      link: '/protocolbook',
      auth_key: 'protocolbook',
    },
    {
      title: t('arch'),
      icon: <ArchiveIcon className="h-10 w-10" />,
      link: '/archive',
      auth_key: 'archive',
    },
    {
      title: t('awaiting_docs'),
      icon: <DocIcon className="h-10 w-10" />,
      link: '/awaitingDocs',
      auth_key: 'awaitingdocs',
    },
    // {
    //   title: t('issued_docs'),
    //   icon: <RiFilePaper2Line className="h-8 w-8" />,
    //   link: '/izdatnice',
    //   auth_key: 'issueddocuments',
    // },
    {
      title: t('stamp_collection'),
      icon: <StampIcon className="h-10 w-10" />,
      link: '/stampCollection',
      auth_key: 'stampcollection',
    },
    {
      title: t('config_menu'),
      icon: <ConfigIcon className="h-10 w-10" />,
      link: '/config',
      auth_key: 'config',
    },
    {
      title: 'Admin Dashboard',
      icon: <MdOutlineAdminPanelSettings className="h-9 w-9" />,
      link: '/admin-dashboard',
      auth_key: 'admin-dashboard',
    },
    {
      title: 'Admin Panel',
      icon: <AdminIcon className="h-10 w-10" />,
      link: '/admin-panel',
      auth_key: 'admin-panel',
    },
    {
      title: t('logout'),
      icon: <LogoutIcon className="h-10 w-10" />,
      link: '',
    },
  ]

  const mapedFunctions = functions.map((fun) => fun.name)
  const filteredMenues = SidebarLinks.filter((link) =>
    link.auth_key ? mapedFunctions.find((fun) => fun === link.auth_key) : link,
  )

  return (
    <>
      <div
        className={`tablet:hidden phone:hidden relative ${
          openSidebar ? 'w-[20em] ease-in' : 'w-[120px] ease-in'
        } bg-primary min-h-screen transition-all duration-300`}
      >
        <div
          className={`absolute ${openSidebar ? '-right-6' : '-right-5'} top-[50px] cursor-pointer transition-all duration-200 ${!openSidebar ? 'rotate-180' : ''}`}
          onClick={handleSetOpen}
        >
          <OpenSidebarBtn />
        </div>

        <div className={`flex w-full justify-center items-center pb-6 pt-10`}>
          <img
            src={Logo}
            className={`cursor-pointer duration-500 h-16 w-16 ${openSidebar && 'rotate-[360deg]'}`}
            alt=""
          />
          <h1
            className={`text-white origin-left ml-3 font-sans text-xl duration-200 uppercase font-semibold tracking-tight ${!openSidebar && 'hidden'}`}
          >
            Protocol
          </h1>
        </div>

        {/* Links */}
        <ul className="flex flex-col justify-center items-center mt-4 w-full gap-2">
          {filteredMenues.map((link) => {
            if (link.title === 'Dokumenti na čekanju' || link.title === 'Awaiting documents')
              return <SidebarAwaitingDocsLink link={link} openSidebar={openSidebar} />
            if (link.title === t('logout'))
              return <SidebarLogoutLink link={link} openSidebar={openSidebar} setOpenLogut={setOpenLogut} />
            return <SidebarListItem link={link} openSidebar={openSidebar} />
          })}
        </ul>
      </div>
      <LogoutModal open={openLogut} onClose={() => setOpenLogut(false)} />
    </>
  )
}
export default Sidebar
