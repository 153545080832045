import { api } from '../api'

export const attachmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createAttachment: builder.mutation({
      query: ({ formData }) => ({
        url: `/attachments/create-attachment`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Attachments'],
    }),
    getAttachments: builder.query({
      query: ({ fileId, companyId }) => ({
        url: `/attachments/get-attachments/${fileId}/${companyId}`,
      }),
      providesTags: ['Attachments'],
    }),
    getAttachmentsPaginated: builder.query({
      query: ({ fileId, page, pageSize, query }) => ({
        url: `/attachments/get-attachments/${fileId}/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Attachments'],
    }),
    editAttachment: builder.mutation({
      query: ({ formData }) => ({
        url: `/attachments/edit-attachment`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: ['Attachments'],
    }),
    deleteAttachment: builder.mutation({
      query: ({ attachmentId }) => ({
        url: `/attachments/delete-attachment/${attachmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Attachments'],
    }),
  }),
})

export const {
  useCreateAttachmentMutation,
  useGetAttachmentsQuery,
  useEditAttachmentMutation,
  useDeleteAttachmentMutation,
  useGetAttachmentsPaginatedQuery,
} = attachmentsApi
