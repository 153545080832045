import { api } from '../api'

export const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadS3: builder.mutation({
      query: ({ data }) => ({
        url: `/files/upload-S3`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Files'],
    }),
    generateUploadUrl: builder.mutation({
      query: ({ fileName }) => ({
        url: `/files/generate-upload-url`,
        method: 'PUT',
        body: {
          fileName,
          contentType: 'application/pdf',
          kind: 'protocolstorage',
        },
      }),
      invalidatesTags: ['Files'],
    }),
    createFile: builder.mutation({
      query: ({ companyId, userId, data }) => ({
        url: `/files/create-file/${companyId}/${userId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Files'],
    }),
    usersFiles: builder.mutation({
      query: ({ id, index, userId }) => ({
        url: `/files/users-files/${id}/?number=${index}`,
        method: 'POST',
        body: userId,
      }),
      invalidatesTags: ['Files'],
    }),
    allFiles: builder.query({
      query: () => ({
        url: `/files`,
      }),
      providesTags: ['Files'],
    }),
    allAproved: builder.query({
      query: () => ({
        url: '/files/get-all-approved',
      }),
      providesTags: ['Files'],
    }),
    allRejected: builder.query({
      query: () => ({
        url: '/files/get-all-rejected',
      }),
      providesTags: ['Files'],
    }),
    archive: builder.query({
      query: ({ companyId, page, pageSize, userId, query }) => ({
        url: `/files/archive/${companyId}/${userId}/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Files'],
    }),
    associatedFilesByCompanyAndUser: builder.query({
      query: ({ companyId, userId, page, pageSize, query }) => ({
        url: `/files/associated-files/${companyId}/${userId}/${page}/${pageSize}${query ? `/${query}` : ''}`, //dodat pag
      }),
      providesTags: ['Files', 'AwaitingDocs'],
    }),
    associatedFilesForOneCompany: builder.query({
      query: ({ companyId, userId }) => ({
        url: `/files/get-associated-files-for-one-company/${companyId}/${userId}`,
      }),
      providesTags: ['Files', 'AwaitingDocs'],
    }),
    deleteFile: builder.mutation({
      query: (id) => ({
        url: `/files/delete-file/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Files'],
    }),
    associatedFilesByUserAndNumber: builder.query({
      query: ({ number, userId }) => ({
        url: `/files/associated-files/${userId}/${number}`,
      }),
      providesTags: ['Files'],
    }),
    fileAprove: builder.mutation({
      query: ({ fileId, userId, comment }) => ({
        url: `/files/${fileId}/approve`,
        method: 'PUT',
        body: {
          userId,
          comment,
        },
      }),
      invalidatesTags: ['Files'],
    }),
    fileReject: builder.mutation({
      query: ({ fileId, userId, comment }) => ({
        url: `/files/${fileId}/reject`,
        method: 'PUT',
        body: {
          userId,
          comment,
        },
      }),
      invalidatesTags: ['Files'],
    }),
    filesProtocol: builder.mutation({
      query: ({ fileId, comment }) => ({
        url: `/files/${fileId}/protocol`,
        method: 'PUT',
        body: { comment },
      }),
      invalidatesTags: ['Files'],
    }),
    changeFile: builder.mutation({
      query: ({ fileId, prevStampKey, companyId, body }) => ({
        url: `/files/change-file/${fileId}/${prevStampKey}/${companyId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Files', 'Stamps'],
    }),
    changeFileType: builder.mutation({
      query: ({ fileId, typeId }) => ({
        url: `/files/change-file-type/${fileId}/${typeId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Files'],
    }),
    filesDownload: builder.query({
      query: (companyId) => ({
        url: `/files/download/${companyId}`,
      }),
      providesTags: ['Files'],
    }),
    deleteLocalFiles: builder.mutation({
      query: (companyId) => ({
        url: `/files/delete-local-files/${companyId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Files'],
    }),
    changeFileCategory: builder.mutation({
      query: ({ fileId, categoryId }) => ({
        url: `/files/change-file-category/${fileId}/${categoryId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Files'],
    }),
    statusApproveCheck: builder.query({
      query: (fileId) => ({
        url: `/files/status-approve-check/?fileId=${fileId}`,
      }),
      providesTags: ['Files'],
    }),
    checkForUserRejection: builder.query({
      query: (fileId) => ({
        url: `/files/check-for-user-rejection/${fileId}`,
      }),
      providesTags: ['Files'],
    }),
    checkPreviousFile: builder.query({
      query: (ID) => ({
        url: `/files/check-previous-file/?id=${ID}`,
      }),
      providesTags: ['Files'],
    }),
    companyDeleteFiles: builder.query({
      query: ({ companyId, page, pageSize, query }) => ({
        url: `/files/company-deleted-files/${companyId}/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Files'],
    }),
    restoreFiles: builder.mutation({
      query: (id) => ({
        url: `/files/restore/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Files'],
    }),
    sendToApprove: builder.mutation({
      query: ({ formData }) => ({
        url: `files/all-in-one`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Files'],
    }),
    duplicateSubjectCheck: builder.mutation({
      query: ({ companyId, subject, partnerName }) => ({
        url: `files/duplicate-subject-check`,
        method: 'POST',
        body: { companyId, subject, partnerName },
      }),
      invalidatesTags: ['Files'],
    }),
    fileAddressWithoutStamp: builder.mutation({
      query: ({ formData }) => ({
        url: `/files/file-address-without-stamp`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Files'],
    }),
    getFileBlob: builder.mutation({
      query: ({ url }) => ({
        url: `/files/get-file-data`,
        method: 'POST',
        body: { url },
      }),
    }),
    farwardFile: builder.mutation({
      query: ({ userId, fileId, newUserId }) => ({
        url: `/users/update-approver-user`,
        method: 'PUT',
        body: { userId, fileId, newUserId },
      }),
      invalidatesTags: ['AwaitingDocs'],
    }),
  }),
})

export const {
  useUploadS3Mutation,
  useGenerateUploadUrlMutation,
  useCreateFileMutation,
  useUsersFilesMutation,
  useAllFilesQuery,
  useAllAprovedQuery,
  useAllRejectedQuery,
  useArchiveQuery,
  useAssociatedFilesByCompanyAndUserQuery,
  useAssociatedFilesForOneCompanyQuery,
  useDeleteFileMutation,
  useAssociatedFilesByUserAndNumberQuery,
  useFileAproveMutation,
  useFileRejectMutation,
  useFilesProtocolMutation,
  useChangeFileMutation,
  useChangeFileTypeMutation,
  useFilesDownloadQuery,
  useDeleteLocalFilesMutation,
  useChangeFileCategoryMutation,
  useStatusApproveCheckQuery,
  useCheckForUserRejectionQuery,
  useLazyCheckForUserRejectionQuery,
  useCheckPreviousFileQuery,
  useCompanyDeleteFilesQuery,
  useRestoreFilesMutation,
  useLazyStatusApproveCheckQuery,
  useLazyFilesDownloadQuery,
  useSendToApproveMutation,
  useDuplicateSubjectCheckMutation,
  useFileAddressWithoutStampMutation,
  useGetFileBlobMutation,
  useFarwardFileMutation,
} = filesApi
