export const selectFields = [
    'Predmet',
    'Broj fakture',
    'Klijent',
    'Partner',
    'Dobavljač',
    'Kupac',
    'Mjesto',
    'Sjedište',
    'Datum dokumenta',
    'Datum predmeta',
    'Datum protokola',
  ]
  