import React from 'react'

const FolderIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 30H28C30.2062 30 32 28.2062 32 26V10C32 7.79375 30.2062 6 28 6H18C17.3687 6 16.775 5.70625 16.4 5.2L15.2 3.6C14.4437 2.59375 13.2563 2 12 2H4C1.79375 2 0 3.79375 0 6V26C0 28.2062 1.79375 30 4 30Z"
        fill="#073C59"
      />
    </svg>
  )
}

export default FolderIcon
