import TableDeletedUsers from '../../components/table/users/TableDeletedUsers'
import useDarkMode from '../../hooks/useDarkMode'

const ModifyDeletedUsers = () => {
  useDarkMode()

  return <TableDeletedUsers />
}

export default ModifyDeletedUsers
