import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CompanyIcon from '../../assets/icons/CompanyIcon'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import FolderIcon from '../../assets/icons/FolderIcon'
import UserIcon from '../../assets/icons/UserIcon'
import useDarkMode from '../../hooks/useDarkMode'

const AdminDashboard = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  useDarkMode()

  return (
    <div className="w-full flex gap-5 items-center justify-center h-full">
      <div className="bg-[#F7F7F7]  text-secondary font-semibold tracking-tight p-5 w-full border border-[#D5D9EC] rounded-md">
        <div className="flex items-center justify-start gap-6 w-full">
          <CompanyIcon />
          <p className="font-semibold text-2xl leading-5 text-center">{t('companies')}</p>
        </div>
        <ul className="flex flex-col gap-4 w-full justify-start items-start mt-4">
          <button
            className="flex items-center justify-start hover:bg-[#e9e7e7] gap-4 border-b-2 border-[#D5D9EC] w-full py-[24px]"
            onClick={() => navigate('/create-company')}
          >
            <FolderIcon /> {t('existing_comp')}
          </button>
          <button
            className="flex items-center justify-start hover:bg-[#e9e7e7] gap-4 border-b-2 border-[#D5D9EC] w-full py-[24px]"
            onClick={() => navigate('/restore-company')}
          >
            <DeleteIcon /> {t('deleted_comp')}
          </button>
        </ul>
      </div>
      <div className="bg-[#F7F7F7]  text-secondary font-semibold tracking-tight p-5 w-full border border-[#D5D9EC] rounded-md">
        <div className="flex items-center justify-start gap-6 w-full">
          <UserIcon />
          <p className="font-semibold text-2xl leading-5 text-center">{t('users')}</p>
        </div>
        <ul className="flex flex-col gap-4 w-full justify-start items-start mt-4">
          <button
            className="flex items-center justify-start hover:bg-[#e9e7e7] gap-4 border-b-2 border-[#D5D9EC] w-full py-[24px]"
            onClick={() => navigate('/create-user')}
          >
            <FolderIcon /> {t('existing_comp')}
          </button>
          <button
            className="flex items-center justify-start hover:bg-[#e9e7e7] gap-4 border-b-2 border-[#D5D9EC] w-full py-[24px]"
            onClick={() => navigate('/restore-user')}
          >
            <DeleteIcon /> {t('deleted')}
          </button>
        </ul>
      </div>
    </div>
  )
}

export default AdminDashboard
