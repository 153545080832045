import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Fc from 'react-icons/fc'

import { DoubleChevronIcon, SingleChevronIcon } from '../../assets/icons'

const Pagination = ({
  gotoPage,
  previousPage,
  setPageSize,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageSize,
  pageIndex,
  pageOptions,
}) => {
  const [index, setIndex] = useState(pageIndex)
  const { t } = useTranslation()
  const arrayInSelect = useMemo(() => [...Array(pageOptions.length).keys()].map((i) => i + 1), [pageOptions])
  return (
    <div className="flex items-center bg-white justify-between p-2 flex-wrap w-full rounded-t-2xl -z-10">
      <button
        className="bg-white px-14 py-2 rounded disabled:bg-white/30 disabled:cursor-not-allowed"
        onClick={() => {
          gotoPage(0)
          setIndex(0)
        }}
        disabled={!canPreviousPage}
      >
        <DoubleChevronIcon />
      </button>
      <button
        className="flex gap-4 items-center text-secondary capitalize"
        onClick={() => {
          previousPage()
          setIndex((prev) => prev - 1)
        }}
        disabled={!canPreviousPage}
      >
        <SingleChevronIcon /> <p>{t('previous')}</p>
      </button>
      <div className="rounded-md border-2 border-neutral">
        <select
          value={index + 1}
          onChange={(e) => {
            gotoPage(+e.target.value - 1)
            setIndex(+e.target.value - 1)
          }}
          disabled={pageCount === 1}
          className="bg-white px-6 py-2 rounded "
        >
          {arrayInSelect.map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </select>
      </div>
      <div className="text-secondary font-semibold text-sm">
        {index + 1}/{pageOptions.length}
      </div>
      <div className="rounded-md border-2 border-neutral">
        <select
          className="bg-white px-6 py-2 rounded "
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 25, 50, 100, 250, 500, 1000].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Prikaži {pageSize}
            </option>
          ))}
        </select>
      </div>
      <button
        className="flex gap-4 items-center text-secondary capitalize"
        onClick={() => {
          nextPage()
          setIndex((prev) => prev + 1)
        }}
        disabled={!canNextPage}
      >
        <p>{t('next')}</p>
        <div className="rotate-180">
          <SingleChevronIcon />
        </div>
      </button>
      <button
        className="bg-white disabled:cursor-not-allowed"
        onClick={() => {
          gotoPage(pageCount - 1)
          setIndex(pageCount - 1)
        }}
        disabled={!canNextPage}
      >
        <div className="rotate-180">
          <DoubleChevronIcon />
        </div>
      </button>
    </div>
  )
}

export default Pagination
