import { combineReducers } from 'redux'

import attachedDocumentsReducer from '../api/features/attachedDocuments'
import placeOfExpenceReducer from '../api/features/placeOfExpence'
import { api } from './api'
import companyReducer from './features/companySlice'
import fileReducer from './features/fileSlice'
import sidebarReducer from './features/sidebarSlice'
import stampReducer from './features/stampSlice'
import userReducer from './features/userSlice'

export const rootReducer = combineReducers({
  file: fileReducer,
  stamp: stampReducer,
  placeOfExpence: placeOfExpenceReducer,
  attachedDocuments: attachedDocumentsReducer,
  company: companyReducer,
  user: userReducer,
  sidebar: sidebarReducer,
  [api.reducerPath]: api.reducer,
})
