const FileDisplay = ({ address, displayComment }) => {
  return (
    <div className={`w-full ${displayComment ? 'h-[60vh]' : 'h-[70vh]'} md:monitor:h-[620px]`}>
      <div id="pdf" className="w-full h-full flex justify-center items-center">
        <iframe src={address} type="application/pdf" className="w-full h-full rounded-xl" frameBorder="0">
          <a href={address}>test.pdf</a>
        </iframe>
      </div>
    </div>
  )
}
export default FileDisplay
