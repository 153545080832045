import { useTranslation } from 'react-i18next'
import 'react-toastify/dist/ReactToastify.css'

import { useDeleteAttachmentMutation } from '../../../api/services/attachments'
import NewModal from '../../root/modals/NewModal'

const DeleteAttachmentModal = ({ open, onClose, attachmentId }) => {
  const { t } = useTranslation()

  const [deleteAttachment] = useDeleteAttachmentMutation()

  const handleDelete = () => {
    deleteAttachment({ attachmentId: attachmentId })
    onClose()
  }

  return (
    <NewModal
      showModal={open}
      onClickClose={onClose}
      titleClose={t('close')}
      onClickSubmit={handleDelete}
      titleSubmit={t('delete')}
      setShowModal={onClose}
      title={t('you_sure_attachment_delete')}
      btns
      page="configStamp"
    ></NewModal>
  )
}

export default DeleteAttachmentModal
