import { parseISO } from 'date-fns'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify'

const isValidDate = function (date) {
  const [day, month, year] = date.trim().split('-')
  const formattedDate = `${year}-${month}-${day}`

  return new Date(date) !== 'Invalid Date' && !isNaN(new Date(formattedDate))
}

const parseStringDateOrder = (dateString) => {
  const validDate = isValidDate(dateString?.trim())

  if (!validDate) return null

  const day = dateString?.slice(0, 2)
  const month = dateString?.slice(3, 5)
  const year = dateString?.slice(6, 10)
  const ISODate = new Date(+year, +month - 1, +day).toISOString()

  return parseISO(ISODate)
}

const DateInputRegular = ({ noLabel, label, onChange, onChangeRaw, ...props }) => {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    if (props.initialValue) {
      const correctDate = parseStringDateOrder(props.initialValue)

      setDate(correctDate)
    }
  }, [props.initialValue])

  const handleDateChange = (date) => {
    onChange && onChange(date)
    setDate(date)
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value
    onChangeRaw && onChangeRaw(event)
    let formattedValue = inputValue ? inputValue.replace(/[^\d]/g, '') : ''

    if (formattedValue.length > 2) {
      const day = parseInt(formattedValue.slice(0, 2), 10)
      if (day >= 1 && day <= 31) {
        formattedValue = `${formattedValue.slice(0, 2)}.${formattedValue.slice(2)}` // Insert dot after two digits
      } else {
        formattedValue = `${formattedValue.slice(0, 2)}.`
        toast.error(t('wrong_day'))
      }
    }

    if (formattedValue.length > 5) {
      const month = parseInt(formattedValue.slice(3, 5), 10)
      if (month >= 1 && month <= 12) {
        formattedValue = `${formattedValue.slice(0, 5)}${formattedValue.slice(5)}` // Insert dot after five digits
      } else {
        // Dodaj tačku i onemogući dalji unos
        formattedValue = `${formattedValue.slice(0, 5)}`
        toast.error(t('wrong_month'))
      }
    }

    if (formattedValue.length > 5) {
      formattedValue = `${formattedValue.slice(0, 5)}.${formattedValue.slice(5)}` // Insert dot after five digits
    }

    if (formattedValue.length > 10) {
      formattedValue = formattedValue.slice(0, 10)
    }

    if (formattedValue !== inputValue || (inputValue && !isNaN(Number(inputValue)))) {
      event.target.value = formattedValue
      const dateParts = formattedValue.split('.').filter(Boolean)
      const day = parseInt(dateParts[0], 10)
      const month = parseInt(dateParts[1], 10) - 1
      const year = parseInt(dateParts[2], 10) || 0

      const date = new Date(year, month, day)

      if (!isNaN(date.getTime())) {
        setDate(date)
      } else {
        setDate(null)
      }
    }
  }

  const maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() + 100)

  return (
    <fieldset className="flex flex-col w-full">
      {!noLabel && (
        <div className="w-full flex justify-start text-secondary">
          <label>{label}</label>
        </div>
      )}
      <DatePicker
        autoComplete="off"
        {...props}
        selected={date || null}
        onChange={handleDateChange}
        locale="bs"
        dateFormat="dd.MM.yyyy"
        strictParsing
        // placeholderText={'dan.mjesec.godina'}
        portalId="root-portal"
        onChangeRaw={handleInputChange}
        className="border-2 border-neutral font-thin tracking-tight focus:border-neutral-200 capitalize placeholder:text-gray-300 w-full p-2 rounded-xl"
        maxDate={maxDate}
      />
    </fieldset>
  )
}

export default DateInputRegular
