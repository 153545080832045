import { changeLanguage } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaEye, FaEyeSlash, FaLock, FaUser } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { setCompany } from '../../api/features/companySlice'
import { setUser } from '../../api/features/userSlice'
import { useLazyUserCompaniesQuery } from '../../api/services/companiesApi'
import { useLazyUserFunctionQuery, useSignInMutation } from '../../api/services/usersApi'
import BaIcon from '../../assets/icons/BaIcon'
import EngIcon from '../../assets/icons/EngIcon'
import backgroundImage from '../../assets/images/Background.png'
import img from '../../assets/images/protocol-logo.png'
import Spinner from '../../components/other/Spinner'

const Login = () => {
  const [username, setUserName] = useState()
  const [password, setPassword] = useState()
  const [remember, setrememberMe] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [baActive, setBaActive] = useState(false)
  const [enActive, setEnActive] = useState(true)

  const { t } = useTranslation()
  const [signInUser, { isLoading }] = useSignInMutation()
  const [getUserCompanies] = useLazyUserCompaniesQuery()
  const [getUserFunctions] = useLazyUserFunctionQuery()

  const handleLogin = (e, userName, pass) => {
    e.preventDefault()
    signIn(userName, pass, remember)
  }
  const rememberChange = () => {
    setrememberMe((prev) => !prev)
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const signIn = async (username, password, rememberMe) => {
    try {
      const user = await signInUser({ username, password }).unwrap()

      dispatch(setUser(user.user))

      const userId = user?.user.id

      const companies = await getUserCompanies(userId).unwrap()
      const token = user?.user.token

      //If user has one company
      //fetch this functions & set config options
      if (token && companies?.length === 1) {
        const company = companies[0]
        const userFunctions = await getUserFunctions({
          userId,
          companyId: company.id,
        }).unwrap()

        const userFunctionNames = userFunctions[company.name][0].map((item) => item.name)

        const userObj = { ...user.user, functions: userFunctions[company.name][0] }

        // Remember user if checked
        if (remember) {
          localStorage.setItem('user', JSON.stringify(userObj))
          localStorage.setItem('company', JSON.stringify(company))
        }

        dispatch(setUser(userObj))
        dispatch(setCompany(company))

        navigate(`/${userFunctionNames[0]}`)
      } else {
        if (remember) {
          navigate('/selectcompany?remember=true')
        } else {
          navigate('/selectcompany')
        }
      }
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <>
      <form>
        <div
          className="flex items-center justify-center w-full h-screen"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="relative flex flex-col w-[310px] lg:w-[397px]  h-[487px] border-[1px] border-[#D5D9EC] rounded-t-[20px] rounded-b-[20px]">
            <div className="absolute flex justify-center items-center gap-[6px] right-2 top-2 w-[80px]">
              <span
                className={`w-[30px] h-[30px] cursor-pointer mt-2`}
                onClick={() => {
                  setBaActive(true)
                  setEnActive(false)
                  changeLanguage('bos')
                }}
              >
                <BaIcon active={baActive} />
              </span>
              <span
                className={`w-[30px] h-[30px] cursor-pointer`}
                onClick={() => {
                  setBaActive(false)
                  setEnActive(true)
                  changeLanguage('en')
                }}
              >
                <EngIcon active={enActive} />
              </span>
            </div>
            <div className="flex flex-col w-[310px] lg:w-[397px] h-[361px] gap-[40px] pt-[60px] pr-[40px] pb-[40px] pl-[40px] bg-[#006EAB] rounded-t-[20px]">
              <div className="flex lg:w-[266px] h-[80px] p-[8px] gap-[12px] justify-center items-center">
                <img src={img} className="w-[64px] h-[64px]" alt="logo" />
                <p className="lg:w-[174px] h-[45px] font-bold text-4xl text-[#FFFFFF] text-center leading-[45.25px] ">
                  PROTOCOL
                </p>
              </div>

              <div className="lg:w-[317px] h-[141px] flex flex-col gap-[12px]">
                <div className=" flex items-center justify-center lg:w-[317px] h-[48px] rounded-lg border-[1px] gap-[8px] py-[8px] px-[16px] bg-[#FFFFFF] border-[#E6E6E5]">
                  <FaUser className="w-[14px] h-[16px] text-[#006EAB]" />
                  <input
                    type="text"
                    placeholder={t('username')}
                    autoComplete="off"
                    className="w-[155px] lg:w-[279px] h-[24px] font-normal text-base leading-6 outline-none font-popins"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className=" flex items-center justify-center lg:w-[317px] h-[48px] rounded-lg border-[1px] gap-[8px] py-[8px] px-[16px] bg-[#FFFFFF] border-[#E6E6E5]">
                  <FaLock className="w-[14px] h-[16px] text-[#006EAB]" />
                  <input
                    type={showPassword === false ? 'password' : 'text'}
                    placeholder={t('password')}
                    autoComplete="off"
                    className="w-[130px] lg:w-[279px] h-[24px] font-normal text-base leading-6 outline-none font-popins"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className="text-[#006EAB] top-[-0px] left-[-0px]"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {!showPassword ? <FaEyeSlash className="w-5 h-4" /> : <FaEye className="w-5 h-4" />}
                  </span>
                </div>
                <div className="">
                  <Link
                    to="/forgot-password"
                    className="w-[134px] h-[21px] font-normal text-sm leading-[21px] text-center text-white font-popins"
                  >
                    {t('forgot_your_password')}
                  </Link>
                </div>
              </div>
              {/* FORMA */}
            </div>
            <div className="flex flex-col w-[310px] lg:w-[397px] h-[126px] py-5 px-10 bg-[#E7EAF8] rounded-b-[20px] items-center justify-center gap-[12px]">
              <div className="flex gap-[8px] w-[150px] h-[26px]">
                <label className="relative cursor-pointer">
                  <input
                    className="z-40  w-[28px] h-[26px] rounded-lg border-[1px] border-[#A6A9AD] cursor-pointer"
                    onClick={rememberChange}
                  />
                  {remember && (
                    <svg
                      className="w-[20px] h-[20px] absolute top-[4px] right-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </label>
                <span className="flex w-[100px] h-[21px] font-normal text-sm leading-[21px] items-center justify-center pt-1 text-[#073C59]">
                  {t('remember_me')}
                </span>
              </div>
              <button
                className="flex justify-center items-center lg:w-[317px] h-[48px] rounded-[4px] py-[12px] px-[20px]  gap-[20px] bg-[#006EAB] font-semibold font-popins text-base leading-6 text-[#FCFCFC]"
                onClick={(e) => handleLogin(e, username, password)}
              >
                {isLoading ? <Spinner /> : t('login')}
              </button>
            </div>
          </div>
          {/* LOGIN FORMA END  */}
        </div>
      </form>
    </>
  )
}

export default Login
