import { useParams } from 'react-router-dom'

import TableAttachments from '../components/table/files/TableAttachments'

const ProtocolBookAttachments = () => {
  const { id } = useParams()

  return <TableAttachments primaryDocumentId={id} isOnProtocolBook />
}

export default ProtocolBookAttachments
