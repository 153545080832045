import SidebarItemWrapper from './SidebarItemWrapper'

const SidebarListItem = ({ link, openSidebar }) => {
  return (
    <SidebarItemWrapper
      link={link}
      className="w-full flex justify-center tooltip tooltip-right hover:bg-white/10"
      data-tip={link.title}
    >
      <li
        key={link.title}
        className={`flex rounded-md cursor-pointer gap-4 text-white text-sm items-center ${link.title === 0 && 'bg-light-white'} ${!openSidebar ? 'w-fit' : 'w-4/5'}`}
      >
        <p className="mb-0">{link.icon}</p>
        <span className={`${!openSidebar && 'hidden'}`}>{link.title}</span>
      </li>
    </SidebarItemWrapper>
  )
}

export default SidebarListItem
