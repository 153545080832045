import { PDFDocument } from 'pdf-lib'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { v4 as uuidv4 } from 'uuid'

import { resetPlaceofExpence, setFile, setFileURL } from '../../api/features/fileSlice'
import { setStampTemplate } from '../../api/features/stampSlice'
import { useGetConfiguratorQuery } from '../../api/services/configuratorApi'
import { useSendEmailMutation } from '../../api/services/emailApi'
import {
  useDuplicateSubjectCheckMutation,
  useFileAddressWithoutStampMutation,
  useSendToApproveMutation,
} from '../../api/services/filesApi'
import { useCreatePlaceOfExpanseMutation } from '../../api/services/placeOfExpanseApi'
import Loader from '../other/Loader'
import { Button } from '../root/Button'

const ApproveBtns = ({
  addData,
  setDocType,
  setDocCategory,
  setValue,
  setStampPositionState,
  setPage,
  docCategory,
  page,
  setPageState,
  setUrlPdf,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { sendFile, fileTypeID, fileData, fileCategoryID, selectedPartner, placeOfExpence } = useSelector(
    (state) => state.file,
  )
  const [createPlaceOfExpanse] = useCreatePlaceOfExpanseMutation()

  const [load, setLoad] = useState(false)
  const [percentLoaded, setPercentLoaded] = useState(0)

  const stampTemplate = useSelector((state) => state.stamp.stampTemplate)

  const company = useSelector((state) => state.company.company)
  const { data: config } = useGetConfiguratorQuery(company.id)

  const [sendEmail] = useSendEmailMutation()

  const [checkDuplicateSubject] = useDuplicateSubjectCheckMutation()
  const [sendToApproveData, { isLoading }] = useSendToApproveMutation()
  const [fileAddressWithoutStamp] = useFileAddressWithoutStampMutation()

  const file = useSelector((state) => state.file.file)

  const user = useSelector((state) => state.user.user)

  const sendToApprove = async (userArray, category) => {
    try {
      const formData = new FormData()
      formData.append('file', sendFile)

      // Combine both in one filter
      const uniqueData = stampTemplate.filter(
        (item, index, self) =>
          item.attributeKey !== 'Stamp ID' && index === self.findIndex((t) => t.attributeKey === item.attributeKey),
      )

      // Currenct subject
      const currentSubject = uniqueData?.find((key) => key?.attributeKey === 'Predmet')

      const validateDup = await checkDuplicateSubject({
        companyId: company?.id,
        subject: currentSubject?.attributeValue,
        partnerName: selectedPartner.label,
      }).unwrap()

      // Find the matching subject
      if (validateDup?.foundDuplicate === true) {
        toast.info(t('dup_sub'))
      }

      setLoad(true)
      setPercentLoaded(1)
      const data = {
        uploaderId: user?.id,
        companyId: company?.id,
        data: JSON.stringify(uniqueData),
        approverUsers: userArray ? JSON.stringify(userArray) : '',
        fileName: `${sendFile.name}`,
        note: 'Test Note',
        issued: fileData.issued,
        importOption: fileData.inSlide,
        exportOption: fileData.outSlide,
        reExport: fileData.reExport,
        advance: fileData.advanced,
        KO: fileData.koDoc,
        booked: false,
        categoryId: fileCategoryID, // Categories unique to each company
        typeId: fileTypeID, // File types
        received: fileData.reciedved,
        isDeleted: false,
        statusId: userArray.length ? 3 : 1, // Approve / Reject / Awaiting
        ...(selectedPartner?.value
          ? {
              clientId: selectedPartner.value,
              clientName: selectedPartner.label,
            }
          : {}),
      }
      setPercentLoaded(15)

      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          formData.append(key, data[key])
        }
      }
      setPercentLoaded(25)

      const res = await sendToApproveData({
        formData,
      }).unwrap()
      setPercentLoaded(40)

      const noStampData = new FormData()

      const existingPdfBytes = await file?.arrayBuffer()
      setPercentLoaded(50)

      // Load a PDFDocument from the existing PDF bytes
      let pdfDoc
      if (existingPdfBytes) {
        const docPDF = await PDFDocument.load(existingPdfBytes)
        pdfDoc = docPDF
      }
      setPercentLoaded(60)
      const pdfBytes = await pdfDoc?.save()
      const newFile = new File([pdfBytes], file.name, {
        type: 'application/pdf',
      })
      setPercentLoaded(70)

      const uniqueID = uuidv4()
      const bytes = new Uint8Array(pdfBytes)
      const blob = new Blob([bytes], { type: 'application/pdf' })
      const noStampFile = new File([blob], `${newFile.name.split('.')[0]}${uniqueID}.pdf`)

      noStampData.append('id', res?.id)
      noStampData.append('file', noStampFile)

      // Upload the file without the samp

      setPercentLoaded(80)
      await fileAddressWithoutStamp({
        formData: noStampData,
      }).unwrap()
      setPercentLoaded(90)

      //Senging place of expence if provided
      if (placeOfExpence?.length > 0) {
        await createPlaceOfExpanse({ arr: placeOfExpence, type: 'FILE', id: res?.id, companyId: company?.id }).unwrap()
      }

      setStampPositionState(null)
      setDocCategory(null)
      setDocType(null)
      setValue(null)
      setPage(null)
      setUrlPdf(null)
      dispatch(setFile(undefined))
      dispatch(setStampTemplate([]))
      dispatch(setFileURL(''))
      dispatch(resetPlaceofExpence())
      setPercentLoaded(100)
      setLoad(false)

      toast.success(res.message)
    } catch (error) {
      toast.error(error.data.message)
      setLoad(false)
    }
  }

  const handleSend = (e) => {
    e.preventDefault()
    if (!file) {
      toast.error(t('choose_doc'))
      return
    }
    if (
      !stampTemplate
        .map((object) => {
          return object.attributeValue
        })
        .every((key) => key)
    ) {
      toast.error(t('create_stamp_error'))
      return
    }
    if (!docCategory) return toast.error(t('create_stamp_category'))
    const userArr = !docCategory.requiredApproval ? [] : [...addData.key1]
    if (docCategory.requiredApproval && !userArr.length) {
      toast.error(t('add_recievers'))
      return
    }
    if (!page) return toast.error(t('input_all_fields'))
    const isTrue = Object.values(addData).every((key) => key)
    if (!isTrue) {
      toast.error(t('input_all_fields'))
    } else {
      sendToApprove(userArr, company?.id, user?.id, docCategory.requiredApproval, placeOfExpence)

      if (config?.usersNeeded === 'one') {
        sendEmail({
          users: userArr.map((user) => user.id),
          stampId: stampTemplate[0].attributeValue,
        })
      } else {
        if (userArr.length > 0) {
          sendEmail({
            users: [userArr[0].id],
            stampId: stampTemplate[0].attributeValue,
          })
        }
      }
    }
  }

  return (
    <>
      <Button
        onClick={(e) => {
          handleSend(e)
        }}
        disabled={isLoading}
        isLoading={isLoading}
      >
        {t('send_to_approve')}
      </Button>
      <Loader percentLoaded={percentLoaded} setPercentLoaded={setPercentLoaded} load={load} />
    </>
  )
}

export default ApproveBtns
