const useFilteredOptions = (stampOptions, spredValues) => {
  //Can only have one option from each class
  //If stampField contains an option
  //filter other options from the same class
  //After stamp select options are all used up...
  //display regular input to manualy input stampField
  let filteredOptions = stampOptions?.reduce((prev, curr, index, array) => {
    //Fill accumulator on first iteration
    if (prev.length === 0) {
      //Map to [{value:string,label:string}...]
      prev = stampOptions.map((option) => ({
        attributeKey: option.attributeKey,
        value: option.option,
        label: option.option,
      }))
    }

    //For each stamp field, check  if it contains current option
    let containedOption
    spredValues.forEach((field) => {
      if (field.attributeKey === curr.option) {
        //Set *containedOption* to option's parent class name
        containedOption =
          curr.option === 'Predmet' || curr.option === 'Broj fakture'
            ? 'Subject'
            : curr.option === 'Klijent' ||
                curr.option === 'Partner' ||
                curr.option === 'Dobavljač' ||
                curr.option === 'Kupac'
              ? 'Client'
              : curr.option === 'Mjesto' || curr.option === 'Sjedište'
                ? 'Location'
                : curr.option === 'Datum dokumenta' || curr.option === 'Datum predmeta'
                  ? 'Date of document'
                  : 'Date'
      }
    })

    //Filter class of contained option
    if (containedOption) {
      let filtered = prev.filter((option) => option.attributeKey !== containedOption)
      return filtered
    } else {
      return prev
    }
  }, [])
  return filteredOptions
}

export default useFilteredOptions
