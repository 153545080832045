import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { logout } from '../../api/features/userSlice'

const Homepage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logOut = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <div className="homepage">
      <h1>{t('welcome')}</h1>
      <p>{t('homepage_message')}</p>
      <button onClick={logOut} className="font-semibold w-[250px] px-2 p-1 bg-slate-600 hover:bg-slate-500 rounded-sm">
        {t('logout')}
      </button>
    </div>
  )
}

export default Homepage
