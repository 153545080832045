import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useCheckUserQuery } from './api/services/usersApi'
import NotificationModal from './components/modals/files/NotificationModal'
import { FreezeAppModal } from './components/modals/modals'
import NewModal from './components/root/modals/NewModal'
import ProtectAdmin from './components/routes/ProtectAdmin'
import ProtectAuth from './components/routes/ProtectAuth'
import ProtectProtocol from './components/routes/ProtectProtocol'
import ProtectSuperAdmin from './components/routes/ProtectSuperAdmin'
import Layout from './layout/Layout'
import {
  AdminDashboard,
  AdminPanel,
  ArchiveDocs,
  AttachedDocuments,
  AwaitingDocs,
  CompanySelect,
  ConfiguratorMenu,
  EditFileRelationsPage,
  Homepage,
  IssuedDocs,
  Login,
  ModifyCompanies,
  ModifyDeletedCompanies,
  ModifyDeletedUsers,
  ModifyUsers,
  NewRelationsPage,
  NotFound,
  ProtocolBook,
  StampCollection,
} from './pages/index'
import AssociateFilesPage from './pages/protocol/configurator/associateFiles/AssociateFilesPage'
import AttachmentsPage from './pages/protocol/configurator/associateFiles/AttachmentsPage'
import RelationsPage from './pages/protocol/configurator/associateFiles/RelationsPage'
import CompanyCategoriesPage from './pages/protocol/configurator/CompanyCategoriesPage'
import CompanyGroupsPage from './pages/protocol/configurator/CompanyGroupsPage'
import DeleteDocument from './pages/protocol/configurator/DeleteDocument'
import EditDocument from './pages/protocol/configurator/EditDocument'
import LogsPage from './pages/protocol/configurator/LogsPage'
import RestoreDocumentPage from './pages/protocol/configurator/RestoreDocumentPage'
import FileFormPage from './pages/protocol/FileFormPage'
import ProtocolBookAttachments from './pages/ProtocolBookAttachments'
import ProtocolBookRelations from './pages/ProtocolBookRelations'

function App() {
  const [open, setOpen] = useState(false)
  const [openNotificationModal, setOpenNotificationModal] = useState(false)
  const user = useSelector((state) => state.user.user)
  const company = useSelector((state) => state.company.company)

  const { data } = useCheckUserQuery(
    { userId: user?.id, companyId: company?.id },
    { skip: !user.id, refetchOnMountOrArgChange: true },
  )

  const counter = data?.length ? data?.length : 0

  const onClose = () => {
    setOpen(!open)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (user && data?.length > 0) setOpen(true)
    }, 2000)

    return () => clearTimeout(timer)
  }, [data])

  return (
    <div>
      <FreezeAppModal open={false} />

      {/* {alert && alert !== '0' ? <Timer /> : null} */}
      {/* <Updating open={alert} /> */}
      <Routes>
        {/* Login pages */}
        <Route>
          <Route element={<ProtectAuth />}>
            <Route path="/" element={<Login />} />
            <Route path="selectcompany" element={<CompanySelect />} />
            <Route path="homepage" element={<Homepage />} />
          </Route>
        </Route>

        {/* Admin dashboard routes */}
        <Route path="/" element={<Layout />}>
          <Route element={<ProtectSuperAdmin allowed={['admin-dashboard']} />}>
            <Route path="admin-dashboard" element={<AdminDashboard />} />
            <Route path="create-company" element={<ModifyCompanies />} />
            <Route path="create-user" element={<ModifyUsers />} />
            <Route path="restore-user" element={<ModifyDeletedUsers />} />
            <Route path="restore-company" element={<ModifyDeletedCompanies />} />
          </Route>
        </Route>

        {/* Amin panel routes  */}
        <Route path="/" element={<Layout />}>
          <Route element={<ProtectAdmin allowed={['admin-dashboard', 'admin-panel']} />}>
            <Route path="admin-panel" element={<AdminPanel />} />
          </Route>
        </Route>

        {/* Protocol routes */}
        <Route path="/" element={<Layout />}>
          {/* Protocol routes */}
          <Route element={<ProtectProtocol allowed={['scandocument']} />}>
            <Route path="scandocument" element={<FileFormPage />} />
          </Route>
          <Route element={<ProtectProtocol allowed={['stampcreating']} />}></Route>
          <Route element={<ProtectProtocol allowed={['attacheddocuments']} />}>
            <Route path="attached-documents" element={<AttachedDocuments />} />
          </Route>
          <Route element={<ProtectProtocol allowed={['protocolbook']} />}>
            <Route path="protocolbook">
              <Route index element={<ProtocolBook />} />
              <Route path="relations/:id" element={<ProtocolBookRelations />} />
              <Route path="attachments/:id" element={<ProtocolBookAttachments />} />
            </Route>
          </Route>
          <Route element={<ProtectProtocol allowed={['archive']} />}>
            <Route path="archive" element={<ArchiveDocs />} />
          </Route>
          <Route element={<ProtectProtocol allowed={['awaitingdocs']} />}>
            <Route path="awaitingDocs" element={<AwaitingDocs />} />
          </Route>
          <Route element={<ProtectProtocol allowed={['stampcollection']} />}>
            <Route path="stampCollection" element={<StampCollection />} />
          </Route>
          <Route element={<ProtectProtocol allowed={['config']} />}>
            <Route path="config">
              <Route index element={<ConfiguratorMenu />} />
              <Route path="company-categories" element={<CompanyCategoriesPage />} />
              <Route path="logs" element={<LogsPage />} />
              <Route path="company-groups" element={<CompanyGroupsPage />} />
              <Route path="restore-document" element={<RestoreDocumentPage />} />
              <Route path="edit-document" element={<EditDocument />} />
              <Route path="delete-document" element={<DeleteDocument />} />

              <Route path="associate-files">
                <Route index element={<AssociateFilesPage />} />
                <Route path="relations/:id" element={<RelationsPage />} />
                <Route path="new-relations/:id" element={<NewRelationsPage />} />
                <Route path="edit-relations/:id" element={<EditFileRelationsPage />} />
                <Route path="attachments/:id" element={<AttachmentsPage />} />
              </Route>
            </Route>
          </Route>

          {/* <Route element={<ProtectProtocol allowed={['outbounddocuments', 'issueddocuments']} />}>
            <Route path="izdatnice" element={<IssuedDocs />} />
          </Route> */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      {user?.token ? (
        <NewModal
          showModal={open}
          setShowModal={onClose}
          btns
          title={t('new_notification')}
          titleSubmit={t('review')}
          onClickSubmit={() => {
            setOpenNotificationModal(true)
            setOpen(false)
          }}
        >
          <div className="w-full h-full mt-6">
            <p className="text-sm font-bold py-2 flex justify-center">
              {t('have')} {counter} {t('documents_pending')}
            </p>
          </div>
        </NewModal>
      ) : null}
      <NotificationModal open={openNotificationModal} setOpenNotificationModal={setOpenNotificationModal} data={data} />

      <ToastContainer className="z-[9999]" />
    </div>
  )
}

export default App
