import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useRestoreDeletedUsersMutation } from '../../../api/services/usersApi'
import NewModal from '../../root/modals/NewModal'

const RestoreUserModal = ({ open, onClose, user }) => {
  const { t } = useTranslation()
  const [restoreDeletedUser] = useRestoreDeletedUsersMutation()

  const handleRestoreUser = async () => {
    try {
      const response = await restoreDeletedUser(user?.id).unwrap()
      toast.success(response.message)
      onClose()
    } catch (error) {
      toast.error(error.data.message)
    }
  }
  return (
    <NewModal
      showModal={open}
      closeModal={onClose}
      title={`${t('you_sure_user_resore')} ${user?.username} ?`}
      hideClose
      btns
      titleClose={t('close')}
      titleSubmit={t('restore')}
      noChildren
      onClickClose={onClose}
      onClickSubmit={() => handleRestoreUser(user.id)}
    />
  )
}

export default RestoreUserModal
