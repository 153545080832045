import axios from 'axios'
import { format } from 'date-fns'
import { t } from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { FaRegFileExcel } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { toast } from 'react-toastify'

import { usePaginatedProtocolQuery } from '../../api/services/protocolApi'
import { Button } from '../root/Button'
import NewModal from '../root/modals/NewModal'
// import MainPaginatedTable from './MainPaginatedTable'
import NewTable from './NewTable'
import TableRow from './print/TableRow'
import TableWrapper from './TableWrapper'

const ProtocolBookUniqueTable = ({
  columns,
  tableHooks,
  page,
  setPage,
  pageSize,
  setPageSize,
  filterInputs,
  setFilterInputs,
  setViewArray,
}) => {
  // page & pageSize for pagination

  const companyId = useSelector((state) => state.company.company)
  const user = useSelector((state) => state.user.user)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (key === 'Datum predmeta') {
        if (value.trim() !== '') {
          const formattedDate = format(new Date(value), 'dd-MM-yyyy')
          queryString += `Datum predmeta=${encodeURIComponent(formattedDate)}&`
        }
      } else if (key === 'comments') {
        if (value.trim() !== '') {
          queryString += `comment=${value.replace(';', '')}&`
        }
      } else {
        if (value.trim() !== '') {
          queryString += `${key}=${encodeURIComponent(value)}&`
        }
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const {
    data: paginatedProtocolFiles,
    isFetching,
    isLoading,
  } = usePaginatedProtocolQuery({
    companyId: companyId?.id,
    page,
    pageSize,
    query: query ? query : '',
  })

  const componentRef = useRef()
  const tableClass =
    'text-xs font-normal break-words p-1 overflow-hidden  text-center align-middle border border-l-0 border-r-0 border-solid bg-blueGray-50 text-blueGray-500 border-blueGray-600 '

  // PAGNATION

  // paginated data
  const data = useMemo(
    () => (Array.isArray(paginatedProtocolFiles?.rows) ? paginatedProtocolFiles?.rows : []),
    [paginatedProtocolFiles],
  )

  useEffect(() => {
    // This will only run when `data` changes
    if (data) {
      const addressData = data?.map((item) => ({ address: item?.address, id: item?.id }))
      setViewArray(addressData)
    }
  }, [data])

  const [openModal, setOpenModal] = useState(false)

  const onClose = () => {
    setOpenModal(!openModal)
  }

  const downloadExcel = async () => {
    try {
      const baseURL = process.env.REACT_APP_API_IP

      const headers = {
        Authorization: `Bearer ${user?.token}`,
      }

      const response = await axios.get(`${baseURL}/protocol/protocol-book-to-excel/${companyId?.id}`, {
        headers: headers,
        responseType: 'blob',
      })

      const fileName = `Protocol Book excel.xlsx`
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)

      window.URL.revokeObjectURL(url)
    } catch (error) {
      toast.error('Error')
    }
  }

  return (
    <>
      <TableWrapper>
        <NewTable
          columns={columns}
          data={data}
          setPage={setPage}
          pageManual={page}
          setPageAmount={setPageSize}
          controlledPageCount={paginatedProtocolFiles?.totalPages || 0}
          hooksArray={tableHooks}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          count={paginatedProtocolFiles?.count}
          actions
          filtering
          isFetching={isFetching}
          isLoading={isLoading}
        />
        <div className="mt-2 w-full flex flex-row-reverse">
          <div className="w-full max-w-sm">
            <Button onClick={() => setOpenModal(true)}>
              <div className="flex justify-center items-center gap-4">
                <AiFillPrinter className="w-6 h-6" />
                <p className="mb-0 mr-2 font-semibold">{t('print')}</p>
              </div>
            </Button>
          </div>
        </div>
      </TableWrapper>
      {/* Component to print */}
      <NewModal showModal={openModal} setShowModal={onClose} btns title={t('print')}>
        <div className="w-full flex flex-col gap-3 mt-6">
          <Button onClick={downloadExcel}>
            <div className="flex justify-center items-center gap-4">
              <FaRegFileExcel className="w-6 h-6" />
              <p className="mb-0 mr-2 font-semibold">Print Excel</p>
            </div>
          </Button>
          <ReactToPrint
            trigger={() => {
              return (
                <Button>
                  <div className="flex justify-center items-center gap-4">
                    <AiFillPrinter className="w-6 h-6" />
                    <p className="mb-0 mr-2 font-semibold">{t('print')} PDF</p>
                  </div>
                </Button>
              )
            }}
            content={() => componentRef.current}
            pageStyle=" @page { margin: 17.1mm 5mm  12.7mm 5mm !important} @media print { body { -webkit-print-color-adjust: exact; }"
          />
        </div>
      </NewModal>
      <div className="hidden">
        <div className="" ref={componentRef}>
          <table className="items-center w-full bg-transparent border border-collapse border-black table-fixed">
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '16%' }}></col>
            <col style={{ width: '5%' }}></col>
            <col style={{ width: '5%' }}></col>
            <col style={{ width: '16%' }}></col>
            <col style={{ width: '5%' }}></col>
            <col style={{ width: '22%' }}></col>
            <col style={{ width: '5%' }}></col>
            <col style={{ width: '16%' }}></col>
            <thead className="">
              <tr>
                <th className={tableClass}>Osnovni broj</th>
                <th rowSpan={2} className={tableClass + 'uppercase'}>
                  Predmet
                </th>
                <th rowSpan={2} className={tableClass + 'text-[8px]'}>
                  Podbroj
                </th>
                <th rowSpan={2} className={tableClass + 'text-[8px]'}>
                  Datum Prijema
                </th>
                <th className={tableClass + 'uppercase'}>Pošiljalac</th>

                <th rowSpan={2} className={tableClass + 'text-[8px]'}>
                  Datum broj
                </th>
                <th rowSpan={2} className={tableClass + 'uppercase'}>
                  Organizaciona jedinica
                </th>
                <th className={tableClass + 'uppercase'} colSpan={2}>
                  Razvod
                </th>
              </tr>
              <tr>
                <th className={tableClass}>Prenos</th>
                <th className={tableClass}>Prezime i ime-naziv i mjesto</th>
                <th className={tableClass + 'text-[8px]'}>Datum</th>
                <th className={tableClass + 'uppercase'}>Oznaka</th>
              </tr>
              <tr>
                <th className={tableClass}>1</th>
                <th className={tableClass}>2</th>
                <th className={tableClass}>3</th>
                <th className={tableClass}>4</th>
                <th className={tableClass}>5</th>
                <th className={tableClass}>6</th>
                <th className={tableClass}>7</th>
                <th className={tableClass}>8</th>
                <th className={tableClass}>9</th>
              </tr>
            </thead>
          </table>
          {data.map((item, i) => (
            <TableRow key={i} item={item} />
          ))}
        </div>
      </div>
    </>
  )
}

export default ProtocolBookUniqueTable
