import React from 'react'

const BaIcon = ({ active }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_241_7703)">
        <mask
          id="mask0_241_7703"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path d="M2 2H22V22H2V2Z" fill="white" />
        </mask>
        <g mask="url(#mask0_241_7703)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 2H22V22H2V2Z" fill="#000099" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00781 2L22.0781 19.0703V2H5.00781Z" fill="#FFCC00" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0234 20.375L19.0039 19.6289L17.9531 20.3711L18.3516 19.1523L17.3203 18.4023H18.5977L18.9961 17.1914L19.3867 18.4062L20.668 18.4102L19.6445 19.168L20.0234 20.375ZM23.0195 20.7812H21.7422L21.3516 19.5625L20.9531 20.7773H19.6719L20.7031 21.5273L20.3086 22.7461L21.3555 22.0039L22.3789 22.7461L21.9961 21.5391L23.0195 20.7812ZM17.3047 16.8281L18.3281 16.0703H17.0508L16.6562 14.8516L16.2578 16.0625H14.9805L16.0117 16.8164L15.6172 18.0312L16.6641 17.2891L17.6836 18.0352L17.3047 16.8242V16.8281ZM14.9492 14.4688L15.9727 13.7109L14.6914 13.707L14.3008 12.4883L13.9023 13.707L12.625 13.7031L13.6562 14.4531L13.2578 15.6719L14.3086 14.9297L15.3281 15.6758L14.9492 14.4648V14.4688ZM12.6172 12.1367L13.6406 11.3789H12.3633L11.9688 10.1602L11.5703 11.375H10.293L11.3242 12.125L10.9297 13.3437L11.9766 12.6016L12.9961 13.3437L12.6172 12.1367ZM10.2578 9.78125L11.2812 9.02734L10 9.02344L9.60938 7.80469L9.21094 9.02344L7.93359 9.01953L8.96484 9.76953L8.56641 10.9883L9.61719 10.2461L10.6367 10.9883L10.2578 9.78516V9.78125ZM7.92578 7.44141L8.95312 6.6875L7.67578 6.68359L7.28516 5.46484L6.88281 6.67969H5.60156L6.63281 7.42969L6.23828 8.64844L7.28516 7.90625L8.30469 8.64844L7.92578 7.44141ZM5.58203 5.09766L6.60938 4.34375L5.33203 4.33984L4.94141 3.12109L4.53906 4.33594H3.25781L4.28906 5.08594L3.89453 6.30469L4.94141 5.5625L5.96484 6.30469L5.58203 5.09766ZM3.23828 2.75391L4.26562 2L2.98438 1.99609L2.59375 0.777344L2.1875 1.99219H0.910156L1.94531 2.74219L1.55078 3.96094L2.59766 3.21875L3.61719 3.96094L3.23828 2.75391Z"
            fill="white"
          />
        </g>
      </g>
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
        stroke={`${active ? 'white' : '#006EAB'}`}
        stroke-width="2"
        shape-rendering="crispEdges"
      />
      <defs>
        <clipPath id="clip0_241_7703">
          <rect x="2" y="2" width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BaIcon
