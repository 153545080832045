import { useMemo } from 'react'

const PrefetchPaginationUrl = ({
  pageSize,
  setPageSize,
  pageOptions,
  pageCount,
  setPage,
  setPageAmount,
  pageManual,
}) => {
  const arrayInSelect = useMemo(() => [...Array(pageOptions.length).keys()].map((i) => i + 1), [pageOptions])

  return (
    <div className="flex items-center justify-between bg-[#349dd8] p-2 mb-2 mt-2 flex-wrap w-full">
      <button
        className="bg-white px-14 py-2 rounded disabled:bg-white/30 disabled:cursor-not-allowed"
        onClick={() => {
          setPage(
            (prev) => {
              prev.set('page', '1')
              return prev
            },
            { replace: true },
          )
        }}
        disabled={pageManual === 1}
      >
        {'<<'}
      </button>
      <button
        className="text-white bg-main-700 rounded px-4 py-2 disabled:bg-main-700/30 disabled:cursor-not-allowed hover:bg-main-700/95"
        onClick={() => {
          setPage(
            (prev) => {
              prev.set('page', +prev.get('page') - 1)
              return prev
            },
            { replace: true },
          )
        }}
        disabled={pageManual === 1}
      >
        Prethodno
      </button>
      <select
        value={pageManual}
        onChange={(e) => {
          setPage(
            (prev) => {
              prev.set('page', +e.target.value)
              return prev
            },
            { replace: true },
          )
        }}
        className="bg-white px-6 py-2 rounded "
      >
        {arrayInSelect.map((el) => (
          <option key={el} value={el}>
            {el}
          </option>
        ))}
      </select>
      <div className="text-white font-semibold">
        {pageManual} / {pageOptions.length}
      </div>
      <select
        className="bg-white px-6 py-2 rounded "
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value))
          setPageAmount(
            (prev) => {
              prev.set('pageSize', Number(e.target.value))
              prev.set('page_size', Number(e.target.value))
              return prev
            },
            { replace: true },
          )
          setPage(
            (prev) => {
              prev.set('page', '1')
              return prev
            },
            { replace: true },
          )
        }}
      >
        {[10, 25, 50, 100, 250, 500, 1000].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Prikaži {pageSize}
          </option>
        ))}
      </select>
      <button
        className="text-white bg-main-700 rounded px-4 py-2 disabled:bg-main-700/30 disabled:cursor-not-allowed hover:bg-main-700/95"
        onClick={() =>
          setPage(
            (prev) => {
              prev.set('page', +prev.get('page') + 1)
              return prev
            },
            { replace: true },
          )
        }
        disabled={pageManual === pageOptions.length || pageOptions.length === 0}
      >
        Sljedeće
      </button>
      <button
        className="bg-white px-14 py-2 rounded disabled:bg-white/30 disabled:cursor-not-allowed"
        onClick={() => {
          setPage(
            (prev) => {
              prev.set('page', pageCount)
              return prev
            },
            { replace: true },
          )
        }}
        disabled={pageManual === pageOptions.length || pageOptions.length === 0}
      >
        {'>>'}
      </button>
    </div>
  )
}

export default PrefetchPaginationUrl
