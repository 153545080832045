import { format } from 'date-fns'
import { t } from 'i18next'
import { useMemo, useRef, useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useAsyncDebounce } from 'react-table'

import { useGetNewCompanyStampsPaginatedQuery } from '../../../api/services/stampsApi'
import PrintStampModal from '../../../components/modals/stamp/PrintStampModal'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import NewTable from '../NewTable'

const TableStampCollection = () => {
  const [open2, setOpen2] = useState(false)
  const componentRef = useRef()
  const [useStampData, setUseStampData] = useState({})
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (key === 'Date') {
        if (value.trim() !== '') {
          const formattedDate = format(new Date(value), 'dd-MM-yyyy')
          queryString += `Date=${encodeURIComponent(formattedDate)}&`
        }
      } else {
        if (value.trim() !== '') {
          queryString += `${key}=${encodeURIComponent(value)}&`
        }
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const columns = useMemo(
    () => [
      {
        Header: t('stamp_key'),
        accessor: 'StampID',
        Cell: ({ row }) => {
          return (
            <button
              className="cursor-pointer text-sky-700 hover:underline hover:text-sky-700/70"
              onClick={() => {
                setUseStampData(row?.original)
                setOpen2(true)
              }}
            >
              {row.original[`Stamp ID`]}
            </button>
          )
        },
      },
      {
        Header: t('client'),
        accessor: 'Client',
      },
      {
        Header: t('subject'),
        accessor: 'Subject',
      },
      {
        Header: t('location'),
        accessor: 'Location',
      },
      {
        Header: t('date'),
        accessor: 'Date',
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
    ],
    [],
  )

  const company = useSelector((state) => state.company.company)

  const { data: companyStamps } = useGetNewCompanyStampsPaginatedQuery({
    companyId: company?.id,
    page,
    pageSize,
    query: query ? query : '',
  })

  const tableHooksArray = [
    {
      label: t('print'),
      Icon: AiFillPrinter,
      onClick: (row) => {
        setUseStampData(row?.original)
        setOpen2(true)
      },
      disabled: false,
    },
  ]

  const data = useMemo(() => (Array.isArray(companyStamps?.files) ? companyStamps?.files : []), [companyStamps])

  return (
    <>
      <NewTable
        columns={columns}
        data={data}
        hooksArray={tableHooksArray}
        hiddenColumns={['Oznaka', 'Organizaciona jedinica', 'Datum dokumenta']}
        actions
        filtering
        setPage={setPage}
        pageManual={page}
        setPageAmount={setPageSize}
        controlledPageCount={companyStamps?.totalPages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={companyStamps?.totalCount}
      />
      <PrintStampModal open={open2} onClose={() => setOpen2(false)} data={useStampData} componentRef={componentRef} />
    </>
  )
}
export default TableStampCollection
