import React from 'react'

const LogoutIcon = () => {
  return (
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33359 4.79141L0.617968 11.507C0.224218 11.9008 0 12.4422 0 13C0 13.5578 0.224218 14.0992 0.617968 14.493L7.33359 21.2086C7.68359 21.5586 8.15391 21.75 8.64609 21.75C9.66875 21.75 10.5 20.9188 10.5 19.8961V16.5H17.5C18.468 16.5 19.25 15.718 19.25 14.75V11.25C19.25 10.282 18.468 9.5 17.5 9.5H10.5V6.10391C10.5 5.08125 9.66875 4.25 8.64609 4.25C8.15391 4.25 7.68359 4.44688 7.33359 4.79141ZM19.25 4.25H22.75C23.718 4.25 24.5 5.03203 24.5 6V20C24.5 20.968 23.718 21.75 22.75 21.75H19.25C18.282 21.75 17.5 22.532 17.5 23.5C17.5 24.468 18.282 25.25 19.25 25.25H22.75C25.6484 25.25 28 22.8984 28 20V6C28 3.10156 25.6484 0.75 22.75 0.75H19.25C18.282 0.75 17.5 1.53203 17.5 2.5C17.5 3.46797 18.282 4.25 19.25 4.25Z"
        fill="white"
      />
    </svg>
  )
}

export default LogoutIcon
