import React from 'react'

const ProcessIcon = () => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.898071 2.42676C0.898071 1.59863 1.56995 0.926758 2.39807 0.926758H5.39807C6.2262 0.926758 6.89807 1.59863 6.89807 2.42676V2.92676H12.8981V2.42676C12.8981 1.59863 13.5699 0.926758 14.3981 0.926758H17.3981C18.2262 0.926758 18.8981 1.59863 18.8981 2.42676V5.42676C18.8981 6.25488 18.2262 6.92676 17.3981 6.92676H14.3981C13.5699 6.92676 12.8981 6.25488 12.8981 5.42676V4.92676H6.89807V5.42676C6.89807 5.47988 6.89495 5.53301 6.8887 5.58301L9.39807 8.92676H12.3981C13.2262 8.92676 13.8981 9.59863 13.8981 10.4268V13.4268C13.8981 14.2549 13.2262 14.9268 12.3981 14.9268H9.39807C8.56995 14.9268 7.89807 14.2549 7.89807 13.4268V10.4268C7.89807 10.3736 7.9012 10.3205 7.90745 10.2705L5.39807 6.92676H2.39807C1.56995 6.92676 0.898071 6.25488 0.898071 5.42676V2.42676Z"
        fill="#073C59"
      />
    </svg>
  )
}

export default ProcessIcon
