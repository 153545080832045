import React from 'react'

import Spinner from '../other/Spinner'

export const Button = ({ children, white, isLoading, ...props }) => {
  return (
    <button
      {...props}
      className={`${white ? 'bg-neutral-100 text-secondary disabled:bg-neutral-300 border-2 border-primary' : 'bg-primary text-white disabled:bg-primary/50 '} w-full disabled:cursor-not-allowed font-bold rounded-default cursor-pointer py-2`}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  )
}
