import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAsyncDebounce } from 'react-table'

import { useGetCategoryQuery } from '../../../api/services/categoriesApi'
import { usePaginatedProtocolQuery } from '../../../api/services/protocolApi'
import { useGetDatabaseStampsQuery } from '../../../api/services/stampsApi'
import createAttachmentImg from '../../../assets/images/create-attachment.png'
import createRelationImg from '../../../assets/images/create-relation.png'
import viewAttachment from '../../../assets/images/view-attachment.png'
import viewRelation from '../../../assets/images/view-relation.png'
import { AttachFileToFileModal } from '../../modals/modals'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import SelectColumnFilterPaginated from '../components/SelectColumnFilterPaginated'
import NewTable from '../NewTable'

const TableAssociateFiles = () => {
  const company = useSelector((state) => state.company.company)

  const { data: category } = useGetCategoryQuery(company?.id)

  const { data: stampFields } = useGetDatabaseStampsQuery({
    companyId: company?.id,
  })
  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const navigate = useNavigate()

  const [primaryDocument, setPrimaryDocument] = useState({})
  const [openAttachFile, setOpenAttachFile] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  const [filterInputs, setFilterInputs] = useState({})

  let subjectHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Predmet' || el.attributeKey === 'Broj fakture') {
      return el
    }
  })

  let clientHeader = stampFields?.find((el) => {
    if (
      el.attributeKey === 'Klijent' ||
      el.attributeKey === 'Partner' ||
      el.attributeKey === 'Dobavljač' ||
      el.attributeKey === 'Kupac'
    ) {
      return el
    }
  })
  let locationHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Mjesto' || el.attributeKey === 'Sjedište') {
      return el
    }
  })
  let dateHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Datum dokumenta' || el.attributeKey === 'Datum predmeta') {
      return el
    }
  })

  const columns = useMemo(
    () => [
      {
        Header: t('stamp_key'),
        accessor: 'Sifra',
      },
      {
        Header: subjectHeader?.attributeKey || t('subject'),
        accessor: 'Predmet',
      },
      {
        Header: clientHeader?.attributeKey || t('client'),
        accessor: 'Klijent',
      },
      {
        Header: locationHeader?.attributeKey || t('location'),
        accessor: 'Lokacija',
      },
      {
        Header: dateHeader?.attributeKey || t('date_of_prijem'),
        accessor: 'Datum prijema',
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
      {
        Header: 'Status',
        accessor: 'Status',
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={[
                { label: t('approved'), value: 1 },
                { label: t('rejected'), value: 2 },
                { label: t('awaiting'), value: 3 },
              ]}
            />
          )
        },

        Cell: ({ row }) => {
          let status = row.original.Status

          return status === 'Approved' ? t('approved') : status === 'Rejected' ? t('rejected') : t('awaiting')
        },
      },
      {
        Header: t('type'),
        accessor: 'Tip',
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={[
                { label: 'Računovodstveni', value: 1 },
                { label: 'Pravni', value: 2 },
              ]}
            />
          )
        },
      },
      {
        Header: t('category'),
        accessor: 'Kategorije',
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={category?.map((item) => {
                return {
                  label: item?.categoryName,
                  value: item?.id,
                }
              })}
            />
          )
        },

        Cell: ({ row }) => {
          let category = row.original.Kategorije
          if (!category) return 'Nema kategorije'
          return category
        },
      },
      {
        Header: t('kind'),
        accessor: 'advance',

        Cell: ({ row }) => {
          let advance = row.original.advance === 1 ? 'advance' : ''
          let importt = row.original?.importOption === 1 ? 'import' : ''
          let exportt = row.original?.exportOption === 1 ? 'export' : ''
          let clasified = row.original.clasified === 1 ? 'clasified' : ''
          let reExport = row.original.reExport === 1 ? 're-export' : ''
          return (
            <div className="flex flex-col w-full justify-center items-center">
              <p className="mb-0">{advance}</p>
              <p className="mb-0">{importt}</p>
              <p className="mb-0">{exportt}</p>
              <p className="mb-0">{clasified}</p>
              <p className="mb-0">{reExport}</p>
            </div>
          )
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              advancedOptions
              handleFilterChange={handleFilterChange}
              providedOptions={[
                { label: 'Advance', value: 1 },
                { label: 'Import', value: 2 },
                { label: 'Export', value: 3 },
                { label: 'Re-export', value: 4 },
              ]}
            />
          )
        },
      },
    ],
    [category],
  )

  const tableHooksArray = [
    {
      label: t('relate'),
      Image: createRelationImg,
      onClick: (row) => {
        navigate(`new-relations/${row?.original?.id}`)
      },
      disabled: false,
    },
    {
      label: t('attach'),
      Image: createAttachmentImg,
      onClick: (row) => {
        setPrimaryDocument(row.original)
        setOpenAttachFile(true)
      },
      disabled: false,
    },
    {
      label: t('relations'),
      Image: viewRelation,
      onClick: (row) => {
        navigate(`relations/${row?.original?.id}`)
      },
      disabled: false,
    },
    {
      label: t('attachments'),
      Image: viewAttachment,
      onClick: (row) => {
        navigate(`attachments/${row?.original?.id}`)
      },
      disabled: false,
    },
  ]

  // page & pageSize for pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    return queryString
  }, [filterInputs])

  const { data: paginatedProtocolFiles, isFetching } = usePaginatedProtocolQuery({
    page,
    pageSize,
    companyId: company?.id,
    query: query,
  })

  const data = useMemo(
    () => (Array.isArray(paginatedProtocolFiles?.rows) ? paginatedProtocolFiles?.rows : []),
    [paginatedProtocolFiles],
  )

  return (
    <div className="phone:hidden">
      <NewTable
        columns={columns}
        setPage={setPage}
        pageManual={page}
        data={data}
        setPageAmount={setPageSize}
        controlledPageCount={paginatedProtocolFiles?.totalPages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={paginatedProtocolFiles?.count}
        actions
        filtering
        hooksArray={tableHooksArray}
        isFetching={isFetching}
      />

      <AttachFileToFileModal
        onClose={() => {
          setOpenAttachFile(false)
          setPrimaryDocument({})
          setSelectedFile()
        }}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        open={openAttachFile}
        primaryDocument={primaryDocument}
      />
    </div>
  )
}
export default TableAssociateFiles
