import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { RiArrowGoBackFill } from 'react-icons/ri'
import { useAsyncDebounce } from 'react-table'
import 'react-toastify/dist/ReactToastify.css'

import { useGetDeletedUsersQuery } from '../../../api/services/usersApi'
import { RestoreDeletedUser } from '../../modals/modals'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import SelectColumnFilterPaginated from '../components/SelectColumnFilterPaginated'
import NewTable from '../NewTable'

const TableDeletedUsers = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])
  const columns = useMemo(
    () => [
      {
        Header: t('first_name'),
        accessor: 'firstName',
      },
      {
        Header: t('last_name'),
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: t('username'),
        accessor: 'username',
      },
      {
        Header: t('arch_access'),
        accessor: 'archive',
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              handleFilterChange={handleFilterChange}
              advancedOptions
              providedOptions={[
                { value: 'All', label: 'All' },
                { value: 'Mine', label: 'Mine' },
              ]}
            />
          )
        },
      },
      {
        Header: t('company_name'),
        accessor: 'companies',
      },
      {
        Header: t('created2'),
        accessor: 'createdAt',
        Cell: ({ row }) => {
          const createdAt = row.original.createdAt
          if (!createdAt) return ''
          return createdAt.substr(0, 10)
        },
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
    ],
    [],
  )

  const { data: deletedUsers } = useGetDeletedUsersQuery({ page, pageSize, query: query ? query : '' })

  const data = useMemo(() => (Array.isArray(deletedUsers?.users) ? deletedUsers?.users : []), [deletedUsers])

  const [open, setOpen] = useState(false)
  const [user, setUser] = useState()

  const tableHooksArray = [
    {
      label: t('restore'),
      Icon: RiArrowGoBackFill,
      onClick: (row) => {
        setOpen(true)
        setUser(row.original)
      },
      disabled: false,
    },
  ]

  return (
    <>
      <NewTable
        columns={columns}
        data={data}
        actions
        filtering
        hooksArray={tableHooksArray}
        setPage={setPage}
        pageManual={page}
        setPageAmount={setPageSize}
        controlledPageCount={deletedUsers?.total_pages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={deletedUsers?.count}
        hiddenColumns={['address', 'companies']}
      />
      <RestoreDeletedUser open={open} onClose={() => setOpen(false)} user={user} />
    </>
  )
}
export default TableDeletedUsers
