import fontkit from '@pdf-lib/fontkit'
import { degrees, PDFDocument, rgb } from 'pdf-lib'
import { useEffect, useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Gr from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid'

import { setFileCategory, setFileType, setSendFile } from '../../api/features/fileSlice'
import { useGetCategoryQuery } from '../../api/services/categoriesApi'
import { useGetConfiguratorQuery } from '../../api/services/configuratorApi'
import { useLastStampKeyQuery } from '../../api/services/protocolApi'
import { useGetCustomStampsQuery } from '../../api/services/stampsApi'
import { useGetRecipientsQuery } from '../../api/services/usersApi'
import { PDFDocumentIcon } from '../../assets/icons'
import { clientStyles } from '../../data/createStampSelectStyles'
import { buttonStamp } from '../../data/stamp/btnStamp'
import ApproveBtns from './ApproveBtns'

const Selects = ({ setPageState }) => {
  const [value, setValue] = useState([])
  const [docType, setDocType] = useState(null)
  const [docCategory, setDocCategory] = useState(null)
  const [rotate, setRotate] = useState(0)
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0)

  const company = useSelector((state) => state.company.company)
  const file = useSelector((state) => state.file.file)
  const { data: companyConfig } = useGetConfiguratorQuery(company.id)

  const { data: companyStampFields } = useGetCustomStampsQuery({ companyId: company?.id })
  const { data: lastStampKey } = useLastStampKeyQuery(company?.id)
  const { data: companyCategories } = useGetCategoryQuery(company?.id)
  const { data: companyUsers } = useGetRecipientsQuery({
    companyId: company?.id,
  })

  const dispatch = useDispatch()

  const { t } = useTranslation()

  //Increment StampID
  const stampMod = (arr) => {
    const date = new Date()
    const parsedDate = date && date.toLocaleDateString('en-UK').replace(/\//g, '-')

    const newStampID = companyConfig.key
      ? `${companyConfig.key}`
      : lastStampKey
        ? `${parseInt(lastStampKey.split('-')[0]) + 1}-${lastStampKey.split('-')[1]}`
        : `${1}-${parseInt(parsedDate.slice(8, 10))}`

    const newObj = {
      attributeKey: 'Šifra pečata',
      attributeValue: newStampID,
    }
    arr[0] = newObj

    // setStampTemplate(arr)
    // setStampData(arr)
  }

  useEffect(() => {
    const date = new Date()
    const parsedDate = date && date.toLocaleDateString('en-UK').replace(/\//g, '-')

    //Not correcting stamp if new year has been started
    if (
      companyConfig?.toString()?.split('-')[1] !== parsedDate?.slice(8, 10) ||
      lastStampKey?.split('-')[1] !== parsedDate.slice(8, 10)
    ) {
      return
    } else {
      stampMod(stampTemplate)
    }
  }, [lastStampKey])

  const stampTemplate = useSelector((state) => state.stamp.stampTemplate)

  // const stampTemplate = JSON.parse(localStorage.getItem('stampTemplate'))
  const data = stampTemplate?.reduce((prev, curr) => {
    const myField = companyStampFields?.find((field) => field.attributeKey === curr.attributeKey)

    if (!myField?.displayOnStamp && curr?.attributeKey !== 'Protokolisao' && curr?.attributeKey !== 'Tip dokumenta') {
      return prev
    } else {
      //translation
      return (
        (prev[
          curr.attributeKey === 'Stamp ID'
            ? 'Šifra pečata'
            : curr.attributeKey === 'Subject'
              ? 'Predmet'
              : curr.attributeKey === 'Client'
                ? 'Klijent'
                : curr.attributeKey === 'Location'
                  ? 'Lokacija'
                  : curr.attributeKey === 'Date'
                    ? 'Datum'
                    : curr.attributeKey === 'Date of document'
                      ? 'Datum dokumenta'
                      : curr.attributeKey === 'Organisational Unit'
                        ? 'Organizaciona jedinica'
                        : curr.attributeKey === 'Label'
                          ? 'Oznaka'
                          : curr.attributeKey
        ] = curr.attributeValue || curr.attributeKey),
        prev
      )
    }
  }, {})

  //State for printing stamp on PDF
  const [coords, setCoords] = useState({ x: 11, y: 630 })
  const [coordsRect, setCoordsRect] = useState({ x: coords.x - 1, y: 630 })
  const [coords1, setCoords1] = useState({ x: 111, y: 630 })
  const [coordsRect1, setCoordsRect1] = useState({ x: coords1.x - 1, y: 630 })
  const [totalHeght, setTotalHeight] = useState(0)
  const [urlPdf, setUrlPdf] = useState()
  const [page, setPage] = useState(undefined)
  const [stampPositionState, setStampPositionState] = useState(null)
  const [totalPages, setTotalPages] = useState(1)
  const stampPosition = localStorage.getItem('stampPosition')
  const defaultStampPosition = JSON.parse(localStorage.getItem('DefaultStampPosition'))

  const listPages = useMemo(() => [...Array(totalPages).keys()], [totalPages])
  //List of value and label
  const selectListPages = listPages.map((p) => {
    return {
      value: p,
      label: p + 1,
    }
  })

  // Function that draws stamp on to PDF
  async function modifyPdf() {
    const existingPdfBytes = await file?.arrayBuffer()

    // Load a PDFDocument from the existing PDF bytes
    let pdfDoc
    if (existingPdfBytes) {
      const docPDF = await PDFDocument.load(existingPdfBytes)
      pdfDoc = docPDF
    }

    // Embed the Helvetica font
    const url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf'
    const fontBytes = await fetch(url).then((res) => res.arrayBuffer())
    pdfDoc?.registerFontkit(fontkit)
    const helveticaFont = await pdfDoc?.embedFont(fontBytes)

    // Get the first page of the document
    const pages = pdfDoc?.getPages()
    const firstPage = pages ? pages[page?.value] : null
    setTotalPages(pages?.length)
    let inc = 0
    let prevInc = 0
    let prevHeightFactor = 0
    let prevTextHeight = 0

    //If stamp is rotated sort array backward for display purposes
    const tt = rotate === 90 || rotate === 180 ? Object.entries(data).reverse() : Object.entries(data)

    if (company?.logo) {
      //Drawing image on PDF
      // const jpgUrl = 'https://pdf-lib.js.org/assets/cat_riding_unicorn.jpg'
      // // const jpgUrl = company?.logo
      // const jpgImageBytes = await fetch(jpgUrl).then((res) => {
      //   return res.arrayBuffer()
      // })
      // const jpgImage = await pdfDoc?.embedJpg(jpgImageBytes)
      // tt.splice(tt.length, 0, ['logo', jpgImage])
    }

    for (let i = tt.length - 1; i >= 0; i--) {
      inc = inc + 15

      if (tt[i][0] === 'Tip dokumenta') {
        // firstPage?.drawImage(tt[i][1], {
        //   x:
        //     rotate === 90
        //       ? coordsRect1.x - 100 + inc
        //       : rotate === 180
        //       ? coordsRect1.x + 100
        //       : rotate === 270
        //       ? coordsRect1.x + inc - 200 + inc
        //       : coordsRect1.x - 100,
        //   y:
        //     rotate === 90
        //       ? coordsRect1.y + 100 - 100
        //       : rotate === 270
        //       ? coordsRect1.y + 100
        //       : rotate === 180
        //       ? coordsRect1.y + inc
        //       : coordsRect1.y - 100 + inc + inc,
        //   width: 200,
        //   height: 100,
        //   rotate: degrees(rotate),
        //   opacity: 0.55,
        // })

        const textSize = 10
        const currentTextLength = tt[i][1].toString().length
        const maxTextLength = 20
        const heightFactor = Math.ceil(currentTextLength / maxTextLength)
        let textHeight = helveticaFont?.heightAtSize(textSize + 3) * heightFactor

        firstPage?.drawRectangle({
          x:
            rotate === 90
              ? coordsRect1.x - prevTextHeight + 80
              : rotate === 180
                ? coordsRect.x + 200
                : rotate === 270
                  ? coordsRect1.x + prevTextHeight - (heightFactor !== 1 ? 130 : 115)
                  : coordsRect.x,
          y:
            rotate === 90
              ? coordsRect.y
              : rotate === 270
                ? coordsRect.y + 100
                : rotate === 0
                  ? coordsRect.y + prevTextHeight - (heightFactor !== 1 ? 24.5 * heightFactor : 35)
                  : rotate === 180
                    ? coordsRect.y - prevTextHeight + 100 + 60
                    : coordsRect.y + inc,
          rotate: degrees(rotate),
          width: 200,
          height: textHeight,
          borderColor: rgb(0, 0.1, 0.9),
          borderWidth: 1,
          rotate: degrees(rotate),
        })

        firstPage?.drawText(tt[i][1].toString(), {
          x:
            rotate === 90
              ? coordsRect1.x - prevTextHeight + 80 - 3
              : rotate === 180
                ? coordsRect.x + 200 - 3
                : rotate === 270
                  ? coordsRect1.x + prevTextHeight - 100 - 9
                  : rotate === 0
                    ? coordsRect.x + 3 + (tt[i][1].length <= 6 ? 80 : tt[i][1].length <= 13 ? 70 : 30)
                    : coordsRect.x + 3,
          y:
            rotate === 90
              ? coordsRect.y + 3
              : rotate === 270
                ? coordsRect.y + 3 + 94
                : rotate === 0
                  ? coordsRect.y + prevTextHeight - 32
                  : rotate === 180
                    ? coordsRect.y - prevTextHeight + 100 + 60 - 3
                    : coordsRect.y + inc,
          size: textSize,
          maxWidth: 200,
          // wordBreaks: 'yes',
          lineHeight: textSize,
          font: helveticaFont,
          color: rgb(0, 0.1, 0.9),
          rotate: degrees(rotate),
        })
      } else {
        const textSize = 10
        const keyLenght = tt[i][0]?.toString()?.trim()?.length
        const valueLenght = tt[i][1]?.toString()?.trim()?.length
        const currentTextLength = keyLenght > valueLenght ? keyLenght : valueLenght
        const maxTextLength = 20
        const heightFactor = Math.ceil(currentTextLength / maxTextLength)
        let textHeight = helveticaFont?.heightAtSize(textSize + 3) * heightFactor
        inc = inc * heightFactor

        if (heightFactor !== 1 && rotate === 0) {
          inc = inc - 15 * heightFactor
        }
        if (heightFactor !== 1 && rotate === 90) {
          inc = inc - prevInc
        }
        if (rotate === 90 && i === tt.length - 1) {
          inc = inc - prevInc - 15
        }

        //Key texxt
        firstPage?.drawText(tt[i][0].toString(), {
          x:
            rotate === 90
              ? coords1.x - prevTextHeight - (heightFactor === 1 ? 5 : 10) * heightFactor + 80
              : rotate === 180
                ? coords.x + 200
                : rotate === 270
                  ? coords1.x + prevTextHeight - 100 + (heightFactor === 1 ? 5 : 10) * heightFactor
                  : coords.x,
          y:
            rotate === 90
              ? coords.y
              : rotate === 180
                ? coords1.y - prevTextHeight + 110 - textHeight + 60
                : rotate === 270
                  ? coords.y - 3 + 100
                  : rotate === 0 && heightFactor === 1
                    ? coords1.y + prevTextHeight + 3 - 20
                    : rotate === 0 && heightFactor !== 1
                      ? coords1.y + prevTextHeight + textHeight - 10 - 20
                      : coords1.y + 3 + inc,
          size: textSize,
          lineHeight: textSize,
          maxWidth: 100,
          font: helveticaFont,
          color: rgb(0, 0.1, 0.9),
          rotate: degrees(rotate),
        })
        // //StampKey columns
        firstPage?.drawRectangle({
          x:
            rotate === 90
              ? coordsRect1.x - prevTextHeight + 80
              : rotate === 180
                ? coordsRect.x + 100
                : rotate === 270
                  ? coordsRect1.x + prevTextHeight - 100
                  : coordsRect.x,
          y:
            rotate === 90
              ? coordsRect.y
              : rotate === 270
                ? coordsRect.y
                : rotate === 0
                  ? coordsRect.y + prevTextHeight - 20
                  : rotate === 180
                    ? coordsRect.y - prevTextHeight + 100 + 60
                    : coordsRect.y + inc,
          width: 100,
          height: textHeight,
          borderColor: rgb(0, 0.1, 0.9),
          borderWidth: 1,
          rotate: degrees(rotate),
        })
        //Value text
        firstPage?.drawText(tt[i][1].toString(), {
          x:
            rotate === 90
              ? coords1.x - prevTextHeight - (heightFactor === 1 ? 5 : 12) * heightFactor + 80
              : rotate === 180
                ? coords1.x - 3
                : rotate === 270
                  ? coords1.x + prevTextHeight - 100 + (heightFactor === 1 ? 5 : 11) * heightFactor
                  : coords1.x,
          y:
            rotate === 90
              ? coords1.y + 101
              : rotate === 180
                ? coords1.y - prevTextHeight + 110 - textHeight + 60
                : rotate == 270
                  ? coords1.y - 1
                  : rotate === 0 && heightFactor === 1
                    ? coords1.y + prevTextHeight + 3 - 20
                    : rotate === 0 && heightFactor !== 1
                      ? coords1.y + prevTextHeight + textHeight - 10 - 20
                      : coords1.y + 3 + inc,
          size: textSize,
          maxWidth: 100,
          // wordBreaks: 'yes',
          lineHeight: textSize,
          font: helveticaFont,
          color: rgb(0, 0.1, 0.9),
          rotate: degrees(rotate),
        })
        // //Value columns
        firstPage?.drawRectangle({
          x:
            rotate === 90
              ? coordsRect1.x - prevTextHeight + 80
              : rotate === 180
                ? coordsRect1.x + 100
                : rotate === 270
                  ? coordsRect1.x + prevTextHeight - 100
                  : coordsRect1.x,
          y:
            rotate === 90
              ? coordsRect1.y + 100
              : rotate === 270
                ? coordsRect1.y + 100
                : rotate === 0
                  ? coordsRect.y + prevTextHeight - 20
                  : rotate === 180
                    ? coordsRect.y - prevTextHeight + 100 + 60
                    : coordsRect1.y + inc,
          width: 100,
          height: textHeight,
          borderColor: rgb(0, 0.1, 0.9),
          borderWidth: 1,
          rotate: degrees(rotate),
        })

        prevTextHeight = prevTextHeight + textHeight
        setTotalHeight(prevTextHeight)
        if (i === tt.length) {
          prevInc = 0
          prevHeightFactor = 0
        } else {
          prevInc = inc
          prevHeightFactor = heightFactor
        }
      }
    }

    const pdfBytes = await pdfDoc?.save()
    const newFile = new File([pdfBytes], file?.name, {
      type: 'application/pdf',
    })

    const uniqueID = uuidv4()
    const bytes = new Uint8Array(pdfBytes)
    const blob = new Blob([bytes], { type: 'application/pdf' })
    const docUrl = URL.createObjectURL(blob)
    const sendFile = new File([blob], `${newFile?.name.split('.')[0]}${uniqueID}.pdf`)
    setUrlPdf(docUrl)
    dispatch(setSendFile(sendFile))
  }

  const userobj = JSON.parse(localStorage.getItem('userobj')) || JSON.parse(sessionStorage.getItem('userobj'))

  //Move stamp on pdf file
  const changeStampPosition = (label, cordX, cordAllY, cordRectX) => {
    setStampPositionState(label || '')

    //Conditionaly ajusting coordinates to
    //fit the stamp if it is rotated
    setCoords((prev) => {
      return {
        x:
          (label.label.toLowerCase().includes('desno') && rotate === 90) ||
          (rotate === 270 && label.label.toLowerCase().includes('desno'))
            ? cordX - totalHeght
            : (label.label.toLowerCase().includes('lijevo') && rotate === 90) ||
                (rotate === 270 && label.label.toLowerCase().includes('lijevo'))
              ? cordX + totalHeght
              : rotate === 270 && label.label.toLowerCase().includes('lijevo')
                ? cordX - totalHeght / 2
                : cordX,
        y:
          label.label?.toLowerCase().includes('dole') && rotate === 90
            ? cordAllY + 40
            : label.label?.toLowerCase().includes('gore') && rotate === 90
              ? cordAllY - 60
              : label.label?.toLowerCase().includes('dole') && rotate === 180
                ? cordAllY + (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                : label.label?.toLowerCase().includes('gore') && rotate === 180
                  ? cordAllY - 60
                  : label.label?.toLowerCase().includes('gore') && rotate === 0
                    ? cordAllY - (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                    : label.label?.toLowerCase().includes('dole') && rotate === 0
                      ? cordAllY + totalHeght / 2
                      : label.label?.toLowerCase().includes('dole') && rotate === 270
                        ? cordAllY + 100
                        : cordAllY,
      }
    })
    setCoordsRect((prev) => {
      return {
        x:
          (label.label.toLowerCase().includes('desno') && rotate === 90) ||
          (rotate === 270 && label.label.toLowerCase().includes('desno'))
            ? cordX - totalHeght
            : (label.label.toLowerCase().includes('lijevo') && rotate === 90) ||
                (rotate === 270 && label.label.toLowerCase().includes('lijevo'))
              ? cordX + totalHeght
              : rotate === 270 && label.label.toLowerCase().includes('lijevo')
                ? cordX - totalHeght / 2
                : cordX - 1,
        y:
          label.label?.toLowerCase().includes('dole') && rotate === 90
            ? cordAllY + 40
            : label.label?.toLowerCase().includes('gore') && rotate === 90
              ? cordAllY - 60
              : label.label?.toLowerCase().includes('dole') && rotate === 180
                ? cordAllY + (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                : label.label?.toLowerCase().includes('gore') && rotate === 180
                  ? cordAllY - 60
                  : label.label?.toLowerCase().includes('gore') && rotate === 0
                    ? cordAllY - (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                    : label.label?.toLowerCase().includes('dole') && rotate === 0
                      ? cordAllY + totalHeght / 2
                      : label.label?.toLowerCase().includes('dole') && rotate === 270
                        ? cordAllY + 100
                        : cordAllY,
      }
    })
    setCoords1((prev) => {
      return {
        x:
          (label.label.toLowerCase().includes('desno') && rotate === 90) ||
          (rotate === 270 && label.label.toLowerCase().includes('desno'))
            ? cordRectX - (totalHeght >= 200 ? totalHeght / 4 : totalHeght / 5)
            : (label.label.toLowerCase().includes('lijevo') && rotate === 90) ||
                (rotate === 270 && label.label.toLowerCase().includes('lijevo'))
              ? cordX + totalHeght
              : rotate === 270 && label.label.toLowerCase().includes('lijevo')
                ? cordX - totalHeght / 2
                : cordRectX,
        y:
          label.label?.toLowerCase().includes('dole') && rotate === 90
            ? cordAllY + 40
            : label.label?.toLowerCase().includes('gore') && rotate === 90
              ? cordAllY - 60
              : label.label?.toLowerCase().includes('dole') && rotate === 180
                ? cordAllY + (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                : label.label?.toLowerCase().includes('gore') && rotate === 180
                  ? cordAllY - 60
                  : label.label?.toLowerCase().includes('gore') && rotate === 0
                    ? cordAllY - (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                    : label.label?.toLowerCase().includes('dole') && rotate === 0
                      ? cordAllY + totalHeght / 2
                      : label.label?.toLowerCase().includes('dole') && rotate === 270
                        ? cordAllY + 100
                        : cordAllY,
      }
    })
    setCoordsRect1((prev) => {
      return {
        x:
          (label.label.toLowerCase().includes('desno') && rotate === 90) ||
          (rotate === 270 && label.label.toLowerCase().includes('desno'))
            ? cordRectX - (totalHeght >= 200 ? totalHeght / 4 : totalHeght / 5)
            : (label.label.toLowerCase().includes('lijevo') && rotate === 90) ||
                (rotate === 270 && label.label.toLowerCase().includes('lijevo'))
              ? cordX + totalHeght
              : rotate === 270 && label.label.toLowerCase().includes('lijevo')
                ? cordX - totalHeght / 2
                : cordRectX - 1,
        y:
          label.label?.toLowerCase().includes('dole') && rotate === 90
            ? cordAllY + 40
            : label.label?.toLowerCase().includes('gore') && rotate === 90
              ? cordAllY - 60
              : label.label?.toLowerCase().includes('dole') && rotate === 180
                ? cordAllY + (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                : label.label?.toLowerCase().includes('gore') && rotate === 180
                  ? cordAllY - 60
                  : label.label?.toLowerCase().includes('gore') && rotate === 0
                    ? cordAllY - (totalHeght >= 200 ? totalHeght / 2 : totalHeght / 5)
                    : label.label?.toLowerCase().includes('dole') && rotate === 0
                      ? cordAllY + totalHeght / 2
                      : label.label?.toLowerCase().includes('dole') && rotate === 270
                        ? cordAllY + 100
                        : cordAllY,
      }
    })
    forceUpdate()
  }

  useEffect(() => {
    //Ajust stamp position if stamp was rotated
    if (stampPosition === 'Default' && defaultStampPosition) {
      //In case of default stamp position
      //* Stamp position (default / custom) is set in setDefaultPosition.jsx*/
      const values = defaultStampPosition?.value
      changeStampPosition(defaultStampPosition, values[0], values[1], values[2])
    } else if (stampPositionState !== null) {
      //In case of custom stamp position
      const values = stampPositionState?.value
      changeStampPosition(stampPositionState, values[0], values[1], values[2])
    }
  }, [page, rotate])

  useEffect(() => {
    modifyPdf()
  }, [coords, coordsRect, reducerValue, page, rotate, lastStampKey])

  const newData = {
    key1: value ? value : [],
    key2: docType ? docType.value : null,
    key3: docCategory ? docCategory.value : null,
  }
  const options = companyUsers
    ?.map((user) => {
      if (user?.firstName !== userobj?.username) {
        return { value: user.id, label: user.firstName, id: user.id }
      } else {
        return null
      }
    })
    .filter((item) => item)

  //react-select optins
  const typeOptions = [
    { value: 1, label: t('accounting') },
    { value: 2, label: t('legal') },
    { value: 3, label: t('other') },
  ]

  const categoryOptions = companyCategories?.map((category) => ({
    label: category.categoryName,
    value: category.id,
    requiredApproval: category.requiredApproval,
  }))

  const stampPositionOptons = buttonStamp.map((btn) => {
    const valueObj = {
      value: [btn.cordX, btn.cordAllY, btn.cordRectX],
      label:
        btn.label === '1'
          ? t('top_left')
          : btn.label === '2'
            ? t('top_center')
            : btn.label === '3'
              ? t('top_right')
              : btn.label === '4'
                ? t('center_left')
                : btn.label === '5'
                  ? t('center')
                  : btn.label === '6'
                    ? t('center_right')
                    : btn.label === '7'
                      ? t('bottom_left')
                      : btn.label === '8'
                        ? t('bottom_center')
                        : t('bottom_right'),
    }
    return valueObj
  })

  //Change page wich stamp is printed on
  const handleSetPage = (value) => {
    setPage(value)
    modifyPdf()
  }

  const handleTypeChange = (e) => {
    setDocType(e)
    dispatch(setFileType(e.value))
  }

  const handleCategoryChande = (e) => {
    setDocCategory(e)

    if (!e.requiredApproval) setValue([])

    dispatch(setFileCategory(e.value))
  }

  return (
    <div className="w-full flex justify-between gap-4 h-full">
      <div className="w-2/6 flex flex-col gap-4">
        <div className="">
          <Select
            styles={clientStyles}
            options={typeOptions}
            placeholder={t('doc_type')}
            value={docType}
            onChange={(e) => handleTypeChange(e)}
          />
        </div>

        <div className="">
          <Select
            styles={clientStyles}
            options={categoryOptions}
            value={docCategory}
            placeholder={t('doc_cat')}
            onChange={(e) => handleCategoryChande(e)}
          />
        </div>
        {docCategory && docCategory.requiredApproval ? (
          <Select
            styles={clientStyles}
            options={options}
            value={value}
            placeholder={t('receivers')}
            isMulti
            onChange={(e) => setValue(e)}
            isDisabled={!(docCategory && docCategory.requiredApproval)}
          />
        ) : (
          <></>
        )}
        <div className="">
          <Select
            styles={clientStyles}
            options={selectListPages}
            value={page}
            placeholder={t('stamp_on_page')}
            onChange={(value) => handleSetPage(value)}
          />
        </div>

        <div className="">
          <Select
            styles={clientStyles}
            options={stampPositionOptons}
            value={stampPositionState}
            placeholder={t('stamp_position')}
            onChange={(e) => changeStampPosition(e, e.value[0], e.value[1], e.value[2])}
            isDisabled={!(stampPosition !== 'Default')}
          />
        </div>

        <div className="">
          <Select
            styles={clientStyles}
            options={[
              { label: t('rotate_90'), value: 90 },
              { label: t('rotate_180'), value: 180 },
              { label: t('rotate_270'), value: 270 },
              { label: t('rotate_360'), value: 0 },
            ]}
            // value={stampPositionState}
            placeholder={t('stamp_rotation')}
            onChange={(option) => setRotate(option.value)}
            isDisabled={!(stampPosition !== 'Default')}
          />
        </div>
      </div>

      <div className="w-4/6">
        {/* PDF DOC DISPLAY */}
        <div className="bg-neutral-100 py-6 px-9 rounded-lg border-[1px] border-neutral w-full h-full">
          <div className="w-full h-[85%] relative flex flex-col border bg-neutral rounded-xl p-1">
            <div className="h-full z-10">
              <iframe
                src={urlPdf}
                frameBorder="0"
                width="100%"
                height="100%"
                title="test"
                type="application/pdf"
              ></iframe>
            </div>
            <div className="absolute inset-0 flex justify-center items-center">
              <PDFDocumentIcon />
            </div>
          </div>

          <div className="h-[2px] w-full bg-primary my-4 rounded-sm"></div>

          <div className="w-full">
            <ApproveBtns
              addData={newData}
              setValue={setValue}
              setDocCategory={setDocCategory}
              setDocType={setDocType}
              setStampPositionState={setStampPositionState}
              setPage={setPage}
              setUrlPdf={setUrlPdf}
              page={page}
              docCategory={docCategory}
              setPageState={setPageState}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Selects

const ssss = ({ urlPdf }) => {
  return (
    <>
      <div className="h-screen w-full flex flex-col justify-center items-center">
        {/* PDF file viewer */}
        {urlPdf && urlPdf.length > 0 ? (
          <div className="w-3/4 h-screen p-2">
            <iframe
              src={urlPdf}
              // src={' '}
              frameBorder="0"
              width="100%"
              height="100%"
              title="test"
              type="application/pdf"
            ></iframe>
          </div>
        ) : null}
      </div>
    </>
  )
}
