import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stampID: undefined,
  stampData: null,
  stampTemplate: [],
  stampFields: [],
};

const stampSlice = createSlice({
  name: "stamp",
  initialState,
  reducers: {
    setStampID: (state, action) => {
      state.stampID = action.payload;
    },
    setStampData: (state, action) => {
      state.stampData = action.payload;
    },
    setStampTemplate: (state, action) => {
      state.stampTemplate = action.payload;
    },
    setStamp: (state, action) => {
      state.stampFields = action.payload;
    },
    setDefaultStampPosition: (state, action) => {
      state.defaultStampPosition = action.payload;
    },
    resetStamp: () => initialState,
  },
});

export const {
  setStampID,
  setStampData,
  setStampTemplate,
  setStamp,
  resetStamp,
  setDefaultStampPosition,
} = stampSlice.actions;
export default stampSlice.reducer;
