import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../../api/services/configuratorApi'
import NewModal from '../../../root/modals/NewModal'

const SetIssuedDocStart = ({ open, onClose }) => {
  const [start, setStart] = useState('')
  const { t } = useTranslation()

  const company = useSelector((state) => state.company.company)

  const [updateCompanyConfig] = useEditConfiguratorMutation(company?.id)

  const { data: companyConfig } = useGetConfiguratorQuery(company?.id)

  const setIssuedDocStart = async (val) => {
    try {
      if (val === '') return toast.error(t('input_all_fields'))

      const newConfig = {
        ...companyConfig,
      }

      newConfig['issuedDocKey'] = val

      delete newConfig['createdAt']
      delete newConfig['updatedAt']

      await updateCompanyConfig({
        companyId: company?.id,
        updatedConfig: newConfig,
      }).unwrap()

      setStart(val)
      onClose()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  useEffect(() => {
    if (companyConfig && companyConfig.issuedDocKey) {
      setStart(companyConfig.issuedDocKey)
    }
  }, [companyConfig])

  const handleSubmit = () => {
    setIssuedDocStart(start)
  }

  return (
    <NewModal
      showModal={open}
      closeModal={onClose}
      hideClose
      title={t('set_st_iss_num')}
      btns
      titleClose={t('close')}
      titleSubmit={t('save')}
      onClickClose={onClose}
      onClickSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-8">
        <span className="flex items-center justify-center">
          <input
            className="theme-input"
            type="text"
            placeholder=""
            onChange={(e) => setStart(e.target.value)}
            value={start}
          />
        </span>
      </div>
    </NewModal>
  )
}

export default SetIssuedDocStart
